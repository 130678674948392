import BaseSse from "common/BaseSse"
import { AnyParsed } from "common/schemaParser"
import { ActivitiesSchemaWithId } from "components/planscan/activities/activities.schema"
import { ConstraintsSchemaWithId } from "components/planscan/constraints/constraints.schema"
import { MilestonesSchemaWithId } from "components/planscan/milestones/milestones.schema"
import { getModule } from "vuex-module-decorators"

import { PlanscanSchemaWithId } from "./planscan.schema"
import PlanscanStore from "./planscan.store"
export default class PlanscanSse extends BaseSse {
    private planscanStore = getModule(PlanscanStore)
    public sse(data: AnyParsed): void {
        if (data && data.name === "planscan") {
            if (this.planscanStore.currentSchedule === null) return
            if (data.object.id === this.planscanStore.currentSchedule.id) this.planscanStore.replaceSchedule(data.object as PlanscanSchemaWithId)
        }

        if (data && data.name === "activity") this.planscanStore.updateActivityIfExists(data.object as ActivitiesSchemaWithId)

        if (data && data.name === "constraint") this.planscanStore.updateConstraintIfExists(data.object as ConstraintsSchemaWithId)

        if (data && data.name === "milestone") this.planscanStore.updateMilestoneIfExists(data.object as MilestonesSchemaWithId)
    }
}

import BaseVueComponent from "common/BaseVueComponent"
import { Component, Prop } from "vue-property-decorator"

@Component
export default class AttachmentsUploaderBase extends BaseVueComponent {
    @Prop(String) public acceptedFiles!: string
    @Prop(Boolean) public disabled!: boolean
    @Prop(Boolean) public uploadNow!: boolean
    @Prop(Boolean) public uploadOnSave!: boolean
    @Prop(String) public uploadUrl!: string
    @Prop(Number) public maxFileSize!: number
    public showDialog = false
}

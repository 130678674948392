import BaseVueComponent from "common/BaseVueComponent"
import { instanceOfSSE } from "common/client.utils"
import http, { isHttpError } from "common/http"
import parse from "common/schemaParser"
import hubConfig from "components/hub/hub.config.json"
import PickUpdateDate from "components/planscan/pickUpdateDate/pickUpdateDate.component.vue"
import projectConfig from "components/project/project.config.json"
import config from "components/protest.config.json"
import sseModules from "components/protest.sse"
import { UsersSchemaWithId } from "components/users/users.schema"
import { UserUtilsMixin } from "components/users/users.utils"
import ActionAlert from "components/widgets/actionAlert/actionAlert.component.vue"
import DateRangePicker from "components/widgets/dateRangePicker/dateRangePicker.component.vue"
import FAddress from "components/widgets/fAddress/fAddress.component.vue"
import FAlert from "components/widgets/fAlert/fAlert.component.vue"
import FAppBar from "components/widgets/fAppBar/fAppBar.component.vue"
import FBadge from "components/widgets/fBadge/fBadge.component.vue"
import FBanner from "components/widgets/fBanner/fBanner.component.vue"
import FButton from "components/widgets/fButton/fButton.component.vue"
import FButtonToggle from "components/widgets/fButtonToggle/fButtonToggle.component.vue"
import FDialog from "components/widgets/fDialog/fDialog.component.vue"
import FDropdown from "components/widgets/fDropdown/fDropdown.component.vue"
import FIcon from "components/widgets/fIcon/fIcon.component.vue"
import FloatingActionButton from "components/widgets/floatingActionButton/floatingActionButton.component.vue"
import FMenu from "components/widgets/fMenu/fMenu.component.vue"
import FSwitch from "components/widgets/fSwitch/fSwitch.component.vue"
import FText from "components/widgets/fText/fText.component.vue"
import FToolbar from "components/widgets/fToolbar/fToolbar.component.vue"
import FuzzySearch from "components/widgets/fuzzySearch/fuzzySearch.component.vue"
import GroupBy from "components/widgets/groupBy/groupBy.component.vue"
import GroupBySortBy from "components/widgets/groupBySortBy/groupBySortBy.component.vue"
import Search from "components/widgets/search/search.component.vue"
import SortBy from "components/widgets/sortBy/sortBy.component.vue"
import Vue from "vue"
import { Component, Mixins, Watch } from "vue-property-decorator"

import { getAllAdminUsers } from "./project/project.utils"
import { FBannerUtilsMixin } from "./widgets/fBanner/fBanner.utils"
/**
 * Protest Base Component
 *
 * @description The base Protest component.
 * @global
 */
Vue.component("ActionAlert", ActionAlert)
Vue.component("FMenu", FMenu)
Vue.component("FloatingActionButton", FloatingActionButton)
Vue.component("FIcon", FIcon)
Vue.component("FDropdown", FDropdown)
Vue.component("FBadge", FBadge)
Vue.component("FAppBar", FAppBar)
Vue.component("FAddress", FAddress)
Vue.component("FAlert", FAlert)
Vue.component("FToolbar", FToolbar)
Vue.component("FDialog", FDialog)
Vue.component("FSwitch", FSwitch)
Vue.component("FText", FText)
Vue.component("FButton", FButton)
Vue.component("FButtonToggle", FButtonToggle)
Vue.component("GroupBySortBy", GroupBySortBy)
Vue.component("GroupBy", GroupBy)
Vue.component("SortBy", SortBy)
Vue.component("Search", Search)
Vue.component("FuzzySearch", FuzzySearch)
Vue.component("PickUpdateDate", PickUpdateDate)
Vue.component("DateRangePicker", DateRangePicker)

// @ts-ignore needed for the abstract class
@Component({
    components: {
        Onboarding: () => import("components/widgets/onboarding/onboarding.component.vue"),
        PickHub: () => import("components/hub/pickHub/pickHub.component.vue"),
        FBanner,
        SendEmail: () => import("components/widgets/sendEmail/sendEmail.component.vue"),
        UserInterfaceModes: () => import("components/widgets/userInterfaceModes/userInterfaceModes.component.vue")
    }
})
export default abstract class ProtestBase extends Mixins(BaseVueComponent, UserUtilsMixin, FBannerUtilsMixin) {
    public showProfile = false
    public isUserAdmin = false
    public showSubmitFeedback = false
    public showCreateReport = false
    public showUserProfileDialog = false
    public showPickHub = false
    private navigation = config.objects.navigation as Navigation
    public protestConfig = config
    public valid = true
    public projectConfig = projectConfig
    public addedRefreshNeededBanner = false
    public hubConfig = hubConfig
    public showSendMailDialog = false
    public needsProjectAddressChange = false
    public isAddressDeprecated = false
    /**
     * Navigation Option Filtering
     *
     * @returns The navigation items that are viewable to the requesting user.
     */
    public get filteredNavigation(): Record<string, NavigationPart[]> {
        const filteredNav: Record<string, NavigationPart[]> = {}
        for (const [key, value] of Object.entries(this.navigation)) {
            filteredNav[key] = value.filter((x) => {
                if (x.name === "Daily" && !this.project?.data.daily.settings.active) return false
                if (x.name === "PlanScan" && this.project?.data.planscan && !this.project.data.planscan.settings.active) return false
                if (x.allowedHubs) if (!this.hub || !x.allowedHubs.includes(this.hub.id)) return false
                if (x.superAdmin) if (!this.isUserSuperAdmin) return false
                if (x.hubAdmin) if (!this.isUserHubAdmin) return false
                if (x.projectAdmin) if (!this.isUserProjectAdmin) return false
                if (!x.modes) return true

                const beta = x.modes.includes("beta")
                return x.modes.includes(this.MODE) || (this.user?.data.flags.beta_tester && beta)
            })
        }

        return filteredNav
    }

    get usersNotInProject(): UsersSchemaWithId[] {
        return this.allUsers.filter((user) => {
            return user.data.email !== ""
        })
    }

    protected async mounted(): Promise<void> {
        await this.$store.dispatch("hydrate")

        if (this.isUserSuperAdmin) {
            this.fBannerStore.addBanner({
                icon: "mdi-account-supervisor",
                text: "You are a Super Admin. With great power comes great responsibility.",
                showButton: true,
                buttonText: "Switch",
                bannerColor: "info",
                buttonIcon: "mdi-account-switch",
                showBanner: true,
                buttonCallback: this.signout
            })
        }

        if (this.isUserImpersonator) {
            this.fBannerStore.addBanner({
                icon: "mdi-account-convert",
                text: "You are impersonating " + this.user?.data.name,
                showButton: true,
                buttonText: "Switch",
                bannerColor: "info",
                buttonIcon: "mdi-account-switch",
                showBanner: true,
                buttonCallback: this.signout
            })
        }

        setInterval(async () => {
            const versionMatched = await http.get(`${process.env.FLYPAPER_API_URL}/protest/version/matches/` + process.env.FLYPAPER_VERSION)
            if (isHttpError(versionMatched)) return
            if (!versionMatched && !this.addedRefreshNeededBanner) {
                this.fBannerStore.addBanner({
                    icon: "mdi-update",
                    text: "The version of FlyPaper you are using is out of date. We highly recommend you refresh your browser window now to avoid any issues with the app.",
                    showButton: true,
                    buttonText: "Refresh",
                    bannerColor: "warning",
                    buttonIcon: "mdi-refresh",
                    showBanner: true,
                    hideCancel: true,
                    buttonCallback: this.refreshApp
                })

                this.addedRefreshNeededBanner = true
            }
        }, 60000)
    }

    private get addressReminderBanner(): FBannerOptions {
        return {
            icon: "mdi-account-supervisor",
            text: this.projectConfig.change_default_address,
            showButton: true,
            buttonText: this.isUserAdmin ? "Change it" : "Email admin",
            bannerColor: "warning",
            buttonIcon: "mdi-content-save",
            showBanner: true,
            buttonCallback: () => {
                this.intiateChangeAddress()
            }
        }
    }

    @Watch("isUserProjectAdmin", { immediate: true })
    @Watch("isUserHubAdmin", { immediate: true })
    @Watch("isUserTeamMember", { immediate: true })
    @Watch("isUserImpersonator", { immediate: true })
    @Watch("isUserSuperAdmin", { immediate: true })
    public showUnauthorizedHubAdminBanner() {
        console.log("isUserProjectAdmin ", this.isUserProjectAdmin)
        console.log("isUserHubAdmin", this.isUserHubAdmin)
        console.log("isUserTeamMember", this.isUserTeamMember)
        setTimeout(() => {
            if (this.isUserHubAdmin && !(this.isUserTeamMember || this.isUserProjectAdmin || this.isUserImpersonator) && !this.isUserSuperAdmin) {
                this.fBannerStore.addBanner({
                    icon: "mdi-account-supervisor",
                    text: "hub admin browsing projects you are not actually a part of",
                    bannerColor: "info",
                    showBanner: true,
                    showButton: false
                })
            }
        }, 100)
    }

    public intiateChangeAddress(): void {
        if (this.isUserAdmin) {
            this.$router.push({
                path: "/project"
            })
        } else {
            this.needsProjectAddressChange = true
            this.showSendMailDialog = true
        }
    }

    @Watch("project", { immediate: false })
    public userOrProjectIdChanged(newVal: number | undefined): void {
        if (!this.user || !this.project) return

        const addressDeprecated = projectConfig.defaults.depreciated_locations.some((x) => {
            return this.project && this.project.data.location.lat === x.lat && this.project.data.location.long === x.long
        })

        if (addressDeprecated) {
            this.removeAddressReminderBannerIfExists()
            this.fBannerStore.addNewBanners([this.addressReminderBanner])
            this.isAddressDeprecated = true
        } else {
            this.removeAddressReminderBannerIfExists()
        }
    }

    @Watch("isUserProjectAdmin", { immediate: true })
    @Watch("isUserHubAdmin", { immediate: true })
    public onAdminChanged(): void {
        if (this.isUserHubAdmin || this.isUserProjectAdmin) this.isUserAdmin = true
        else this.isUserAdmin = false
        this.removeAddressReminderBannerIfExists()

        if (this.isAddressDeprecated) this.fBannerStore.addNewBanners([this.addressReminderBanner])
    }

    public removeAddressReminderBannerIfExists(): void {
        this.fBannerStore.removeBannerByText(this.projectConfig.change_default_address)
    }

    public closeEmailDialog(successfullySendEmail: boolean): void {
        if (successfullySendEmail) this.removeAddressReminderBannerIfExists()
    }

    @Watch("user", { immediate: true })
    public onUser(): void {
        if (this.user && this.user.data.flags.onboarded && !this.isHubAndProjectSet) this.showPickHub = true
    }

    public get isHubAndProjectSet(): boolean {
        if (!this.user) return false
        return this.user.data.current_hub !== 0 && this.user.data.current_project !== 0
    }

    public async refreshApp(): Promise<void> {
        window.location.reload()
    }

    public async signout(): Promise<void> {
        this.$store.commit("clearState")
        window.location.href = "/auth/signout"
    }

    @Watch("user.id", { immediate: true })
    public userIdChanged(newVal: number | undefined): void {
        if (newVal === undefined || newVal === 0) return

        if (!this.isPrinting) this.registerSSE()
    }

    public get allAdminUsers(): string[] | undefined {
        if (this.project) return getAllAdminUsers(this.project, this.allUsers)
    }

    public get mailSubjectLine(): string {
        return this.user?.data.name + " " + "is requesting to re-active project" + " " + this.project?.data.name
    }

    public get changeAddressMailSubject(): string {
        return "FlyPaper Incorrect Project Address"
    }

    public get mailSubjectBody(): string {
        return "Hello, I'm still working on " + this.project?.data.name + ", can you please re-activate the project?"
    }

    public get changeAddressMailBody(): string {
        return (
            "Hello,\nIt looks like the project address has not been set for our project: " +
            this.project?.data.name +
            ".\nCould you please change it in the Project admin?"
        )
    }

    @Watch("$store.state.userPreferences.darkMode", { immediate: true })
    public onDarkModeChange(): void {
        this.$vuetify.theme.dark = this.$store.state.userPreferences.darkMode
    }

    public async reactToSSE(stringData: string): Promise<void> {
        try {
            const json = JSON.parse(stringData)
            if (!json) return

            if (!instanceOfSSE(json)) return

            const parsedData = await parse(json.data)
            if (!parsedData) return

            sseModules.forEach((sseModule) => {
                sseModule.sse(parsedData)
            })

            // TODO react differently based on new action field "json.action"
            if (parsedData.name === "user") {
                const updatedUser = parsedData.object
                if (this.user && this.user.id) {
                    if (updatedUser.id === this.user.id) {
                        this.$store.dispatch("replaceCurrentUser", updatedUser)
                        return
                    }
                }

                this.$store.dispatch("updateOrAddToAllUsers", updatedUser)
                return
            }

            if (parsedData.name === "hub") {
                this.$store.dispatch("queryForAllHubs")
                if (this.hub && this.hub.id === parsedData.object.id) this.$store.dispatch("queryAndReplaceHub", this.hub.id)
            }

            if (parsedData.name === "worklog") {
                // this.$store.dispatch("queryForAllProjectWorklogTags")
                return
            }

            if (parsedData.name === "project") {
                this.$store.dispatch("queryForAllProjects")
                if (this.project && this.project.id === parsedData.object.id) this.$store.dispatch("queryAndReplaceProject", this.project.id)

                return
            }

            if (parsedData.name === "company") {
                if (this.allCompaniesInHub.some((x) => x.id === parsedData.object.id)) this.$store.dispatch("queryForAllCompaniesInHub")

                if (this.company && this.company.id === parsedData.object.id) this.$store.dispatch("queryAndReplaceCompany", this.company.id)

                return
            }

            if (parsedData.name === "area") {
                this.$store.dispatch("updateProjectAreaIfNeeded", parsedData.object)

                return
            }
        } catch {
            return
        }
    }

    protected abstract registerSSE(): void
}

import BaseVueComponent from "common/BaseVueComponent"
import ViewerJs from "viewerjs"
import { Component, Prop } from "vue-property-decorator"
@Component
export default class AttachmentsViewerImageBase extends BaseVueComponent {
    @Prop({ default: true }) public src!: string

    public viewer: ViewerJs | null = null
    mounted(): void {
        const imageViewer = document.getElementById("viewer")

        if (imageViewer) {
            this.viewer = new ViewerJs(imageViewer, {
                toolbar: {
                    zoomIn: 4,
                    zoomOut: 4,
                    oneToOne: 4,
                    reset: 4,
                    rotateLeft: 4,
                    rotateRight: 4,
                    flipHorizontal: 4,
                    flipVertical: 4
                }
            })
        }
    }

    public viewFullScreen(): void {
        if (!this.viewer) return
        this.viewer.show()
    }
    public closeFullScreen(): void {
        if (this.viewer) this.viewer.destroy()
    }
}

import Vue from "vue"
import { Component, Watch } from "vue-property-decorator"

@Component
export default class Snacks extends Vue {
    public snackbar = {
        show: false,
        current: {} as Snack,
        queue: [] as Snack[]
    }

    public created(): void {
        this.$root.$on("newSnack", this.addSnackToQueue)
    }
    public addSnackToQueue(snack: Snack): void {
        if (snack.timeout === undefined) snack.timeout = 4000

        this.snackbar.queue.push(snack)
        this.snackbar.show = true
    }

    @Watch("snackbar.show")
    public onWarningHide(newValue: boolean, oldValue: boolean): void {
        if (newValue) {
            const newSnack = this.snackbar.queue.shift()
            if (newSnack) this.snackbar.current = newSnack

            return
        }

        if (this.snackbar.queue.length > 0) {
            setTimeout(() => {
                this.snackbar.show = true
            }, 750)
        }
    }
}

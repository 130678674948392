import { render, staticRenderFns } from "./image.template?vue&type=template&id=3bab65a6&scoped=true&lang=pug&"
import script from "./image.client?vue&type=script&lang=ts&"
export * from "./image.client?vue&type=script&lang=ts&"
import style0 from "./image.style?vue&type=style&index=0&id=3bab65a6&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bab65a6",
  null
  
)

export default component.exports
import { BaseDb } from "common/BaseDb"
import http, { HttpError, isHttpError, makeAuthHeader } from "common/http"
import HubDb from "components/hub/hub.db"
import UsersConfig from "components/users/users.config.json"
import { UsersSchema, UsersSchemaWithId } from "components/users/users.schema"
import { DateTime } from "luxon"
/**
 * Users db
 *
 * @class
 */
class UsersDb extends BaseDb<UsersSchema> {
    public async getUserIdFromEmail(userEmail: string, sessionId?: string): Promise<number | false | HttpError> {
        const response = await http.post<{ id: number }>(
            `${this.apiUrl}/${this.component}/get/user/id/from/email`,
            { email: userEmail },
            {
                headers: {
                    ...makeAuthHeader(sessionId)
                }
            }
        )

        if (isHttpError(response)) {
            if (response.statusCode === 401) return false
            else return response
        }

        return response.id
    }

    public async createNewUserAndAddToProject(userEmail: string, currentProjectId?: number): Promise<UsersSchemaWithId | HttpError> {
        const hub = await HubDb.readAll({
            filter2: {
                "data.projects": ["contains", currentProjectId]
            }
        })

        if (isHttpError(hub)) return hub

        const newUser: UsersSchema = {
            created_by: 0,
            modified_by: 0,
            date_created: DateTime.utc().toISO(),
            date_modified: DateTime.utc().toISO(),
            data: {
                name: "",
                email: userEmail,
                picture: "",
                roles: [UsersConfig.roles[1]],
                access: UsersConfig.access,
                todos: [],
                current_project: currentProjectId ?? 0,
                login_count: 0,
                current_hub: hub[0].id,
                flags: {},
                active: true,
                tokens: {
                    ios_push: [],
                    android_push: []
                },
                project_settings: []
            }
        }

        const createNewUserResponse = await this.create(newUser)
        return isHttpError(createNewUserResponse) ? createNewUserResponse : createNewUserResponse[0]
    }

    public async createNewUserAndAddToHub(userEmail: string, hubId: number): Promise<UsersSchemaWithId | HttpError> {
        const hub = await HubDb.read(hubId)
        if (isHttpError(hub)) return hub

        const newUser: UsersSchema = {
            created_by: 0,
            modified_by: 0,
            date_created: DateTime.utc().toISO(),
            date_modified: DateTime.utc().toISO(),
            data: {
                name: "",
                email: userEmail,
                picture: "",
                roles: [UsersConfig.roles[1]],
                access: UsersConfig.access,
                todos: [],
                current_project: 0,
                login_count: 0,
                current_hub: hub[0].id,
                flags: {},
                active: true,
                tokens: {
                    ios_push: [],
                    android_push: []
                },
                project_settings: []
            }
        }

        const createNewUserResponse = await this.create(newUser)
        return isHttpError(createNewUserResponse) ? createNewUserResponse : createNewUserResponse[0]
    }

    public async setUserCurrentProject(userId: number, projectId: number, sessionID?: string): Promise<{ projectId: number } | HttpError> {
        return http.put<{ projectId: number }>(
            `${process.env.FLYPAPER_API_URL}/users/${userId}/set/current/project/${projectId}`,
            {},
            {
                headers: { ...makeAuthHeader(sessionID) }
            }
        )
    }

    public async setUserCurrentHub(userId: number, hubId: number, sessionID?: string): Promise<{ hubId: number } | HttpError> {
        return http.put<{ hubId: number }>(
            `${process.env.FLYPAPER_API_URL}/users/${userId}/set/current/hub/${hubId}`,
            {},
            {
                headers: { ...makeAuthHeader(sessionID) }
            }
        )
    }

    public async addProjectSettingsToUser(userId: number, projectId: number, sessionID?: string): Promise<UsersSchemaWithId | HttpError> {
        return http.post<UsersSchemaWithId>(
            `${process.env.FLYPAPER_API_URL}/users/add/project/settings/${userId}/${projectId}`,
            {},
            {
                headers: { ...makeAuthHeader(sessionID) }
            }
        )
    }
}

export default new UsersDb("users")

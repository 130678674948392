import SubmitBase from "components/feedback/submit/submit.client.base"
import { Component } from "vue-property-decorator"
/**
 * Submit Web Component
 *
 * @description The base Feedback component for both Web and Mobile.
 * @augments SubmitBase
 * @global
 */
@Component
export default class Submit extends SubmitBase {}

import { ProjectLocation, What3WordsResponse } from "components/widgets/fAddress/fAddress"
import Coordinates from "coordinate-parser"

import http, { isHttpError } from "./http"

/**
 * @param latitude latitude
 * @param logitude logitude
 * @returns what3words from latitude & logitude
 */
export async function getWhat3WordsFromLongitudeAndLatitude(latitude: number, logitude: number): Promise<What3WordsResponse | undefined> {
    if (logitude && latitude) {
        const what3WordsResponse = await http.get<What3WordsResponse>(
            process.env.FLYPAPER_API_URL + `/project/what3words/from/${latitude}/${logitude}`
        )

        if (isHttpError(what3WordsResponse)) return

        return what3WordsResponse
    }
}

/**
 * Provide what3words and it returns Longitude and Latitude
 *
 * @param what3words what3words to get longitude and lattitude from
 * @returns what3WordsResponse
 */
export async function getLongitudeAndLatitudeFromWhat3Words(what3words: string): Promise<What3WordsResponse | undefined> {
    const what3WordsResponse = await http.get<What3WordsResponse>(process.env.FLYPAPER_API_URL + `/project/latitude/logitude/from/${what3words}`)
    if (isHttpError(what3WordsResponse)) return

    return what3WordsResponse
}

/**
 * @param localAddress address to get latitude & longitude from
 * @returns latitude, longitude
 */
export function getLattitudeLongitudeFromDegree(localAddress: string):
    | {
          latitude: number
          longitude: number
      }
    | undefined {
    try {
        const position = new Coordinates(localAddress)
        const latitude = position.getLatitude()
        const longitude = position.getLongitude()

        if (latitude && longitude) {
            return {
                latitude: latitude,
                longitude: longitude
            }
        }
    } catch (err) {
        return
    }
}

/**
 * @param addressToTest string to match with what3words
 * @returns regex result
 */
export function validateWhat3words(addressToTest: string): boolean {
    const regexWhat3Words =
        /^\/{0,}[^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]{1,}[.｡。･・︒។։။۔።।][^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]{1,}[.｡。･・︒។։။۔።।][^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]{1,}$/

    return regexWhat3Words.test(addressToTest)
}

/**
 * Create project address object out of What3WordsResponse
 *
 * @param data what 3 words response
 * @param localAddress local address to store in location.address
 * @returns ProjectLocation to store in project schema
 */
export function setProjectAddress(data: What3WordsResponse, localAddress: string): ProjectLocation {
    const projectAddress: ProjectLocation = {
        location: { latitude: -1, longitude: -1, what3words: "", address: "" }
    }

    projectAddress.location.latitude = data.coordinates.lat
    projectAddress.location.longitude = data.coordinates.lng
    projectAddress.location.what3words = data.words
    projectAddress.location.address = localAddress

    return projectAddress
}

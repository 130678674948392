import { ConversationsSchemaWithId } from "components/conversations/conversations.schema"
import { store } from "components/protest.store"
import { Module, Mutation, VuexModule } from "vuex-module-decorators"

@Module({
    dynamic: true,
    store,
    name: "conversations",
    namespaced: true
})
export default class ConversationsStore extends VuexModule {
    public conversations: Array<ConversationsSchemaWithId> = []
    public sseLocks: Array<{ id: number; expires: number; prop: string | undefined }> = []

    @Mutation
    public setConversations(conversations: Array<ConversationsSchemaWithId>): void {
        this.conversations = conversations
    }

    @Mutation
    public setSseLocks(locks: Array<{ id: number; expires: number; prop: string | undefined }>): void {
        this.sseLocks = locks
    }
}

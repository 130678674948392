import { render, staticRenderFns } from "./fAddress.template?vue&type=template&id=4e8dd69b&scoped=true&lang=pug&"
import script from "./fAddress.client?vue&type=script&lang=ts&"
export * from "./fAddress.client?vue&type=script&lang=ts&"
import style0 from "./fAddress.component.vue?vue&type=style&index=0&id=4e8dd69b&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4e8dd69b",
  null
  
)

export default component.exports
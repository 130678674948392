import Axios, { AxiosError, AxiosRequestConfig, RawAxiosRequestHeaders } from "axios"
import ClientToken from "common/clientToken"
// eslint-disable-next-line no-restricted-imports
import Logger from "common/Logger"
import get from "lodash/get"
import { DateTime } from "luxon"
import qs from "qs"

import { getErrorMessage } from "./Errors"

const LOGGER = new Logger("http.ts")
export interface RequestConfig {
    headers?: RawAxiosRequestHeaders
    params?: Record<string, unknown>
    responseType?: "arraybuffer" | "blob" | "document" | "json" | "text" | "stream"
}

export interface HttpError {
    errorMessage: string
    statusCode: number
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    record: any
}

/**
 * @param error the object to check
 * @returns true if is an instance of HttpError
 */
export function isHttpError(error: unknown): error is HttpError {
    return get(error, "errorMessage") && get(error, "statusCode") ? true : false
}

/**
 *
 * @param error
 */
export function isAxiosError(error: unknown): error is AxiosError<any, any> {
    return (error as any).isAxiosError
}

/**
 * @param headers the existing header object
 * @returns a modified header object that includes the Authorization: Bearer token
 */
const appendAuthAndTimezoneToHeaders = (headers?: RawAxiosRequestHeaders): RawAxiosRequestHeaders | undefined => {
    const token = new ClientToken().get()
    const timezoneOfUser = DateTime.local().zone.name

    if (!headers) headers = {}

    if (!headers.Authorization && token) headers.Authorization = `Bearer ${token}`

    if (!headers["User-Timezone"]) headers["User-Timezone"] = timezoneOfUser

    return headers
}

/**
 * @param url the url to make the request to
 * @param method the method to use for the request
 * @param [data] optional data to send in the request body
 * @param [config] optional configuration object to use (headers, query params, responseType)
 * @returns AxiosRequestConfig
 */
const createRequestParams = (
    url: string,
    method: "put" | "get" | "post" | "patch" | "delete",
    data?: unknown,
    config?: RequestConfig
): AxiosRequestConfig => {
    const axiosConfig: AxiosRequestConfig = {
        url,
        method,
        data
    }

    if (config) {
        axiosConfig.params = config.params
        axiosConfig.headers = config.headers
        axiosConfig.responseType = config.responseType
    }

    axiosConfig.maxBodyLength = 100000000
    axiosConfig.maxContentLength = 100000000

    axiosConfig.headers = appendAuthAndTimezoneToHeaders(axiosConfig.headers)
    axiosConfig.paramsSerializer = {
        serialize: (params) => {
            // @ts-ignore the allowEmptyArrays exists
            return qs.stringify(params, { arrayFormat: "brackets", allowEmptyArrays: true })
        }
    }

    return axiosConfig
}

/**
 * @param url the url to make the request to
 * @param method the method to use for the request
 * @param [data] optional data to send in the request body
 * @param [config] optional configuration object to use (headers, query params, responseType)
 * @param [base64] optional boolean to indicate if the response should be base64
 * @returns the response or an HttpError
 */
async function makeAxiosCall<T>(
    url: string,
    method: "put" | "get" | "post" | "patch" | "delete",
    data?: unknown,
    config?: RequestConfig,
    base64?: boolean
): Promise<T | HttpError> {
    try {
        const response = await Axios.request(createRequestParams(url, method, data, config))
        if (base64) {
            try {
                const dataType = response.headers["content-type"]
                if (!dataType) {
                    return {
                        errorMessage: "Failed get base64 version of record",
                        statusCode: 500,
                        record: undefined
                    }
                }

                const base64String = Buffer.from(response.data, "binary").toString("base64")
                if (base64String.trim().length === 0) {
                    return {
                        errorMessage: "Zero byte file",
                        statusCode: 500,
                        record: undefined
                    }
                }

                return `data:${dataType};base64,${base64String}` as unknown as T
            } catch (error) {
                const message = getErrorMessage(error)
                LOGGER.error("Failed to convert buffer to base64", { error: { message } })
                return {
                    errorMessage: "Failed to convert buffer to base64",
                    statusCode: 500,
                    record: undefined
                }
            }
        }

        return response.data
    } catch (error) {
        let sessionId
        if (config && config.headers && config.headers.Authorization) {
            const auth = config.headers.Authorization
            if (typeof auth === "string") sessionId = auth.split(" ").pop()
        }

        if (isAxiosError(error)) {
            // Error
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                LOGGER.debug(
                    "Recieved error from server",
                    {
                        status: error.response.status,
                        data: error.response.data,
                        headers: error.response.headers,
                        config: error.config
                    },
                    sessionId
                )

                return {
                    statusCode: get(error, "response.status") || 500,
                    errorMessage: get(error, "response.data") || "Unknown error occurred",
                    record: undefined
                }
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                LOGGER.error(
                    "Request made no response from server",
                    {
                        request: error.request,
                        config: error.config
                    },
                    sessionId
                )

                return {
                    errorMessage: "No response from server",
                    statusCode: 500,
                    record: undefined
                }
            } else {
                // Something happened in setting up the request that triggered an Error
                LOGGER.error(
                    "Unknown error occurred during request",
                    {
                        message: error.message,
                        config: error.config
                    },
                    sessionId
                )

                return {
                    errorMessage: error.message || "Unknown error occurred",
                    statusCode: 500,
                    record: undefined
                }
            }
        } else {
            return {
                errorMessage: getErrorMessage(error),
                statusCode: 500,
                record: undefined
            }
        }
    }
}

/**
 * Makes auth header
 *
 * @param [sessionId] optional sessionId
 * @returns auth header
 */
export function makeAuthHeader(sessionId?: string): RawAxiosRequestHeaders {
    return sessionId ? { Authorization: "Bearer " + sessionId } : {}
}

/**
 * Makes params
 *
 * @param [params] optional params object
 * @returns params
 */
export function makeParams(params?: Record<string, unknown>): Record<string, unknown> {
    return params || {}
}

const http = {
    get: <T>(url: string, config?: RequestConfig, base64?: boolean): Promise<T | HttpError> => {
        if (!config) config = {}

        if (base64) config.responseType = "arraybuffer"

        return makeAxiosCall<T>(url, "get", undefined, config, base64)
    },
    put: <T>(url: string, data: unknown, config?: RequestConfig): Promise<T | HttpError> => {
        return makeAxiosCall<T>(url, "put", data, config)
    },
    post: <T>(url: string, data: unknown, config?: RequestConfig): Promise<T | HttpError> => {
        return makeAxiosCall<T>(url, "post", data, config)
    },
    patch: <T>(url: string, data: unknown, config?: RequestConfig): Promise<T | HttpError> => {
        return makeAxiosCall<T>(url, "patch", data, config)
    },
    delete: <T>(url: string, config?: RequestConfig): Promise<T | HttpError> => {
        return makeAxiosCall<T>(url, "delete", undefined, config)
    }
}

export default http

import { store } from "components/protest.store"
import { TodosSchemaWithId } from "components/todos/todos.schema"
import { Module, Mutation, VuexModule } from "vuex-module-decorators"

@Module({
    dynamic: true,
    store,
    name: "todos",
    namespaced: true
})
export default class TodosStore extends VuexModule {
    public currentUserOpenTodos: Array<TodosSchemaWithId> = []

    @Mutation
    public setCurrentTodos(todos: Array<TodosSchemaWithId>): void {
        this.currentUserOpenTodos = todos
    }
}

import { BaseDb } from "common/BaseDb"
import { ConstraintsSchemaWithId } from "components/planscan/constraints/constraints.schema"
/**
 * Constraints Db
 *
 * @class
 */
export class ConstraintsDb extends BaseDb<ConstraintsSchemaWithId> {}

export default new ConstraintsDb("planscan/constraints")

import BaseSse from "common/BaseSse"

// @ts-ignore need this ignore because webpacking in docker for some reason doesn't pick up this type
const files = require.context(".", true, /\.sse\.ts$/)
const sseModules: BaseSse[] = []

files.keys().forEach((fileName: string) => {
    if (fileName === "./protest.sse.ts") return
    const sseClass = files(fileName).default
    const sseClassInstance: BaseSse = new sseClass()
    sseModules.push(sseClassInstance)
})

export default sseModules

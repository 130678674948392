import BaseVueComponent from "common/BaseVueComponent"
import { Component, Prop } from "vue-property-decorator"

@Component
export default class FButtonBase extends BaseVueComponent {
    @Prop(Boolean) public disabled!: boolean
    @Prop({ default: "", type: String }) public activeClass!: string
    @Prop({ default: "", type: String }) public href!: string
    @Prop({ default: "", type: String }) public id!: string
    @Prop({ default: "", type: String }) public color!: string
    @Prop({ default: "", type: String }) public tooltip!: string
    @Prop({ default: "", type: String }) public selectedToggle!: string
    @Prop({ default: 0 }) public badgeCount!: number | string
    @Prop({ default: 500, type: Number }) public tooltipOpenDelay!: number
    @Prop({ default: 0, type: Number }) public tooltipCloseDelay!: number
    @Prop() public appendIcon: string | string[] | undefined
    @Prop(String) public buttonText: string | undefined
    @Prop(String) public type: string | undefined
    @Prop() public prependIcon: string | string[] | undefined
    @Prop(Boolean) public outlined!: boolean
    @Prop({ type: String }) public customStyle!: string
    @Prop({ default: "", type: String }) public maxWidth!: string
    @Prop(Boolean) public boldText!: boolean
    @Prop(Boolean) public loading!: boolean
    @Prop(Boolean) public text!: boolean
    @Prop({ default: false, type: Boolean }) public block!: boolean
    @Prop(Boolean) public icon!: boolean
    @Prop(Boolean) public xLarge!: boolean
    @Prop(Boolean) public large!: boolean
    @Prop(Boolean) public medium!: boolean
    @Prop(Boolean) public small!: boolean
    @Prop(Boolean) public xSmall!: boolean
    @Prop(Boolean) public fab!: boolean
    @Prop(String) public target: string | undefined
    @Prop(String) public to: string | undefined
    @Prop(String) public width: string | undefined
    @Prop(String) public minWidth: string | undefined
    @Prop(String) public buttonType!: string
    @Prop(Boolean) public isOpen!: boolean
    @Prop(Boolean) public isButtonToggle!: boolean

    public buttonTypeIcon = {
        dropDown: "mdi-menu-down"
    }
    public get buttonColor(): string {
        if (this.color === "") return this.themedButtonColor
        else return this.color
    }

    public get prependIcons(): string[] | undefined {
        if (!this.prependIcon) return
        if (Array.isArray(this.prependIcon)) return this.prependIcon
        return [this.prependIcon]
    }

    public get prependIconSpacers(): string[] | undefined {
        if (!this.prependIcons) return
        const icons = this.prependIcons
        return this.prependIcons.map((x, index) => {
            if (index === icons.length - 1 && !this.buttonText) return ""
            return "mr-1"
        })
    }

    public get appendIcons(): string[] | undefined {
        if (!this.appendIcon && !this.buttonType) return
        else if (!this.appendIcon) if (this.buttonType === "dropDown") return [this.buttonTypeIcon.dropDown]

        if (this.appendIcon) {
            let icons = []
            if (Array.isArray(this.appendIcon)) icons = this.appendIcon
            else icons = [this.appendIcon]

            if (this.buttonType === "dropDown") icons.push(this.buttonTypeIcon.dropDown)

            return icons
        }
    }

    public get appendIconSpacers(): string[] | undefined {
        if (!this.appendIcons) return
        const prependIcons = this.prependIcons
        return this.appendIcons.map((x, index) => {
            const iconClass = []
            if (this.buttonType && this.isOpen && this.appendIcons && index === this.appendIcons.length - 1) iconClass.push("rotateIcon")
            if (index === 0) {
                if (this.buttonText || (prependIcons && prependIcons.length > 0)) iconClass.push("ml-1")
            } else {
                iconClass.push("ml-1")
            }

            return iconClass.join(" ")
        })
    }
}

import AttachmentsUploaderBase from "components/attachments/uploader/uploader.client.base"
import uploaderConfig from "components/attachments/uploader/uploader.config.json"
import { Component, Prop, Watch } from "vue-property-decorator"
import vue2Dropzone from "vue2-dropzone"
@Component({
    components: {
        vueDropzone: vue2Dropzone
    }
})
export default class AttachmentsUploaderWeb extends AttachmentsUploaderBase {
    @Prop(Boolean) public clearSuccessful!: boolean
    @Prop({ type: Boolean, default: false }) public allowSingleUpload!: boolean
    public refGuid: string = Math.random().toString()

    public get uploaderOptions(): UploaderOptions {
        const options: UploaderOptions = {
            url: this.uploadUrl,
            createImageThumbnails: false,
            maxFilesize: this.maxFileSize ? this.maxFileSize : uploaderConfig.maxFileSize,
            addRemoveLinks: false,
            uploadMultiple: true,
            parallelUploads: 10,
            acceptedFiles: this.acceptedFiles ? this.acceptedFiles : uploaderConfig.allowedFileTypes,
            timeout: 120000,
            headers: { "My-Awesome-Header": "header value" },
            autoProcessQueue: !this.uploadOnSave
        }

        if (this.allowSingleUpload) options.maxFiles = 1
        return options
    }

    @Watch("uploadNow")
    public onUploadNowChange(newVal: boolean): void {
        // @ts-ignore processQueue exists on this ref
        if (newVal) this.$refs[this.refGuid].processQueue()
    }

    public success(payload: unknown, serverResponse: unknown[]): void {
        // @ts-ignore removeFile exists on this ref
        if (this.clearSuccessful) this.$refs[this.refGuid].removeFile(payload)
        // const dropzoneComponent = this.$refs[this.refGuid] as Vue
        const loaderElement = document.querySelector(".loader")
        if (loaderElement) loaderElement.remove()

        this.$emit("updated-record", serverResponse[0])
    }

    public addedFileToUpload(): void {
        const previewElement = document.querySelector(".dz-preview")
        const dropzoneComponent = this.$refs[this.refGuid] as Vue
        dropzoneComponent.$el.children[1].children[2].remove()
        const loaderElement = document.createElement("div")
        loaderElement.className = "dz-loader"
        if (previewElement) previewElement.appendChild(loaderElement)

        this.$emit("uploading", true)
    }

    public onQueueComplete(): void {
        this.$emit("uploading", false)
    }
}

import BaseVueComponent from "common/BaseVueComponent"
import { Component, Prop } from "vue-property-decorator"

/**
 * Saving Indicator Base Component
 *
 * @description The base Saving Indicator component for both Web and Mobile.
 * @augments BaseVueComponent
 * @global
 */
@Component
export default class SavingIndicatorBase extends BaseVueComponent {
    @Prop({ type: String, default: "saved" }) public status!: "saving" | "error" | "saved" | "locked" | "validated"

    public get iconToShow(): string {
        switch (this.status) {
            case "error":
                return "mdi-alert-circle"
            case "saved":
                return "mdi-cloud-check"
            case "locked":
                return "mdi-lock"
            case "validated":
                return "mdi-check-circle-outline"
            default:
                return ""
        }
    }

    public get iconColor(): string {
        switch (this.status) {
            case "error":
                return "red"
            case "saved":
                return "green"
            case "locked":
                return "red"
            case "validated":
                return "blue"
            default:
                return ""
        }
    }

    public get inProgress(): boolean {
        return this.status === "saving"
    }

    public get message(): string {
        switch (this.status) {
            case "error":
                return "Will be saved when all errors have been fixed."
            case "saved":
                return "Saved."
            case "validated":
                return "Validated."
            case "saving":
                return "Saving in progress..."
            case "locked":
                return this.project?.data.active ? "Locked from editing." : "Project is inactive"
        }
    }
}

import { Component } from "vue-property-decorator"

import FButtonBase from "./fButton.client.base"

@Component
export default class FButtonWeb extends FButtonBase {
    public get badgeXOffset(): number {
        const digits = this.badgeCount.toString().length
        return Math.max(17 + 3 * digits, 20)
    }

    public get styles(): string {
        return this.$store.state.userPreferences.darkMode ? "color:#000000" : "color:#ffffff"
    }

    get truncateTextIfMaxWidth(): string | undefined {
        if (this.maxWidth !== "") return `max-width: ${this.maxWidth}; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;`
        return
    }

    public get allowTooltip(): boolean {
        return this.isEmbedded ? false : true
    }
}

import { Sessions } from "common/client.utils"
import { store } from "components/protest.store"
import { detect } from "detect-browser"
import Vue from "vue"
import VueCookies from "vue-cookies"
import VueRouter, { RouterOptions } from "vue-router"

const auth = (): Promise<typeof import("*.vue")> => import(/* webpackChunkName: "auth" */ "components/auth/auth.component.vue")
const daily = (): Promise<typeof import("*.vue")> => import(/* webpackChunkName: "daily" */ "components/daily/daily.component.vue")
const project = (): Promise<typeof import("*.vue")> => import(/* webpackChunkName: "project" */ "components/project/project.component.vue")
const planscan = (): Promise<typeof import("*.vue")> => import(/* webpackChunkName: "planscan" */ "components/planscan/planscan.component.vue")

const dashboard = (): Promise<typeof import("*.vue")> => import(/* webpackChunkName: "dashboard" */ "components/dashboard/dashboard.component.vue")
const surveytemplates = (): Promise<typeof import("*.vue")> =>
    import(/* webpackChunkName: "surveyTemplates" */ "components/surveys/templates/templates.component.vue")

const documentation = (): Promise<typeof import("*.vue")> =>
    import(/* webpackChunkName: "documentation" */ "components/documentation/documentation.component.vue")

const about = (): Promise<typeof import("*.vue")> => import(/* webpackChunkName: "about" */ "components/about/about.component.vue")
const index = (): Promise<typeof import("*.vue")> => import(/* webpackChunkName: "index" */ "components/index/index.component.vue")
const errors = (): Promise<typeof import("*.vue")> => import(/* webpackChunkName: "errors" */ "components/errors/errors.component.vue")

const theme = (): Promise<typeof import("*.vue")> => import(/* webpackChunkName: "theme" */ "components/theme/theme.component.vue")
const unsubscribe = (): Promise<typeof import("*.vue")> =>
    import(/* webpackChunkName: "unsubscribe" */ "components/unsubscribe/unsubscribe.component.vue")

const hub = (): Promise<typeof import("*.vue")> => import(/* webpackChunkName: "hub" */ "components/hub/hub.component.vue")
const gallery = (): Promise<typeof import("*.vue")> =>
    import(/* webpackChunkName: "gallery" */ "components/attachments/gallery/gallery.component.vue")

const usersAdmin = (): Promise<typeof import("*.vue")> => import(/* webpackChunkName: "hub" */ "components/users/admin/admin.component.vue")

const defaultMetaDescription = "Let's get back to building."
const routes: RouterOptions["routes"] = [
    {
        path: "/",
        component: index,
        meta: {
            is_public: true,
            metaTags: [
                {
                    name: "description",
                    content: defaultMetaDescription
                }
            ]
        }
    },
    {
        path: "/index",
        component: index,
        name: "index",
        meta: {
            is_public: true,
            metaTags: [
                {
                    name: "description",
                    content: defaultMetaDescription
                }
            ]
        }
    },
    {
        path: "/documentation",
        component: documentation,
        name: "documentation",
        meta: {
            metaTags: [
                {
                    name: "description",
                    content: "Documentation for FlyPaper Technologies construction management application"
                }
            ]
        }
    },
    {
        path: "/about",
        component: about,
        name: "about",
        meta: {
            metaTags: [
                {
                    name: "description",
                    content: "About page for FlyPaper Technologies construction management application"
                }
            ]
        }
    },
    {
        path: "/daily",
        name: "daily",
        component: daily,
        meta: {
            metaTags: [
                {
                    name: "description",
                    content: "Siteline Daily"
                }
            ]
        }
    },
    {
        path: "/dailies",
        redirect: "/daily"
    },
    {
        path: "/auth",
        component: auth,
        name: "auth",
        meta: {
            is_public: true,
            metaTags: [
                {
                    name: "description",
                    content: defaultMetaDescription
                }
            ]
        }
    },
    {
        path: "/project",
        component: project,
        name: "project",
        meta: {
            metaTags: [
                {
                    name: "description",
                    content: "Project administration for FlyPaper."
                }
            ]
        }
    },
    {
        path: "/surveys/templates",
        component: surveytemplates,
        name: "survey templates",
        meta: {
            metaTags: [
                {
                    name: "description",
                    content: "Survey templates for FlyPaper Technologies construction management application"
                }
            ]
        }
    },
    {
        path: "/dashboard",
        component: dashboard,
        name: "dashboard",
        meta: {
            metaTags: [
                {
                    name: "description",
                    content: "Dashboard for FlyPaper Technologies construction management application"
                }
            ]
        }
    },
    {
        path: "/theme",
        component: theme,
        name: "Theme",
        meta: {
            metaTags: [
                {
                    name: "description",
                    content: "Dynamic theme settings for FlyPaper"
                }
            ]
        }
    },
    {
        path: "/hub",
        component: hub,
        name: "Hub",
        meta: {
            metaTags: [
                {
                    name: "description",
                    content: "FlyPaper user hub administration."
                }
            ]
        }
    },
    {
        path: "/users/admin",
        component: usersAdmin,
        name: "User admin",
        meta: {
            metaTags: [
                {
                    name: "description",
                    content: "FlyPaper user administration."
                }
            ]
        }
    },
    {
        path: "/attachments/gallery",
        component: gallery,
        name: "Gallery",
        meta: {
            is_public: true,
            metaTags: [
                {
                    name: "description",
                    content: "FlyPaper attachment gallery."
                }
            ]
        }
    },
    {
        path: "/planscan",
        component: planscan,
        name: "Planscan",
        meta: {
            metaTags: [
                {
                    name: "description",
                    content: "FlyPaper Planscan."
                }
            ]
        }
    },
    {
        path: "*",
        component: errors,
        name: "errors",
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        props: (route: any): any => ({ ...route.query }),
        meta: {
            is_public: true,
            metaTags: [
                {
                    name: "description",
                    content: defaultMetaDescription
                }
            ]
        }
    },
    {
        path: "/unsubscribe",
        component: unsubscribe,
        name: "Unsubscribe",
        meta: {
            is_public: true,
            metaTags: [
                {
                    name: "description",
                    content: "Siteline Daily Report email successful unsubscribe"
                }
            ]
        }
    }
]

const router = new VueRouter({
    routes,
    mode: "history"
})

Vue.use(VueCookies)

router.beforeEach(async (to, from, next) => {
    const userId = await new Sessions().getSessionUserId()

    if (userId !== 0 && (to.path === "/index" || to.path === "/")) {
        localStorage.setItem("data-route", to.name ? to.name : "dashboard")
        next({
            name: "dashboard"
        })
    }

    next()
})

// if route is NOT public and user isn't signed in send them back to index
router.beforeEach(async (to, from, next) => {
    if (to.matched.some((record) => !record.meta.is_public)) {
        const userId = await new Sessions().getSessionUserId()
        if (userId !== 0) {
            next()
            return
        }

        // HACK commit to reset the setPageOffSetLimit to add 'scroll to top' button
        store.commit("setPageOffSetLimit", 500)

        // HACK store the name of route so that we can redirect to that route after login
        localStorage.setItem("data-route", to.name ? to.name : "documentation")
        next({ name: "index" })
    }

    next()
})

router.beforeEach(async (to, from, next) => {
    const browser = detect()
    // @ts-ignore Basically we do this any time we access the window object.
    const ignoreBrowserIncompatibility = window.$cookies.get("ignoreBrowser")
    store.commit("setBrowserName", browser?.name)
    if (browser !== null && ignoreBrowserIncompatibility === null) {
        switch (browser.name) {
            case "ie":
                store.commit("setBrowserSupport", false)
                break

            case "firefox":
                if (parseFloat(browser.version) < 68) store.commit("setBrowserSupport", false)
                else store.commit("setBrowserSupport", true)
                break

            case "safari":
                if (parseFloat(browser.version) < 10) store.commit("setBrowserSupport", false)
                else store.commit("setBrowserSupport", true)
                break

            default:
                store.commit("setBrowserSupport", true)
                break
        }

        if (!store.state.isBrowserSupported && to.fullPath !== "/" && to.path !== "/index" && to.path !== "/auth") {
            // next({ name: "index" })
            next({
                name: "index",
                query: {
                    nextUrl: to.fullPath
                }
            })

            return
        }
    }

    next()
})

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithName = to.matched
        .slice()
        .reverse()
        .find((r) => r.name)

    // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched
        .slice()
        .reverse()
        .find((r) => r.meta && r.meta.metaTags)

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithName && nearestWithName.name) {
        document.title = nearestWithName.name
            .toLowerCase()
            .split(" ")
            .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
            .join(" ")
    }

    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map((el) => el.parentNode?.removeChild(el))

    // Skip rendering meta tags if there are none.
    if (!nearestWithMeta) return next()

    // Turn the meta tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags
        .map((tagDef: { [key: string]: string }) => {
            const tag = document.createElement("meta")

            Object.keys(tagDef).forEach((key) => {
                tag.setAttribute(key, tagDef[key])
            })

            // We use this to track which meta tags we create, so we don't interfere with other ones.
            tag.setAttribute("data-vue-router-controlled", "")

            return tag
        })
        // Add the meta tags to the document head.
        .forEach((tag: HTMLMetaElement) => document.head.appendChild(tag))

    next()
})

router.beforeEach(async (to, from, next) => {
    // @ts-ignore it's a string
    if (to.path === "/auth" && to.query["projectInvited"]) localStorage.setItem("project-invited", to.query["projectInvited"])

    next()
})

router.afterEach((to, from) => {
    if ((window as any).gtag) {
        ;(window as any).gtag("config", "G-6VQ98S1LQL", {
            page_path: to.fullPath,
            page_title: to.name || document.title
        })
    }
})

export { router }

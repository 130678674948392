import BaseSse from "common/BaseSse"
import { AnyParsed } from "common/schemaParser"
import { getModule } from "vuex-module-decorators"

import DailyStore from "./daily.store"
export default class DailySse extends BaseSse {
    private dailyStore = getModule(DailyStore)
    public sse(data: AnyParsed): void {
        if (data && data.name === "daily") {
            if (this.dailyStore.currentDaily === null) return
            if (data.object.id === this.dailyStore.currentDaily.id) this.dailyStore.setCurrentDaily(data.object)
        }
    }
}

import { isHttpError } from "common/http"
import { ActivitiesSchemaWithId } from "components/planscan/activities/activities.schema"
import { ConstraintsSchemaWithId } from "components/planscan/constraints/constraints.schema"
import { MilestonesSchemaWithId } from "components/planscan/milestones/milestones.schema"
import { store } from "components/protest.store"
import { DateTime } from "luxon"
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators"

import { ActivitiesViewModel } from "./activities/activities"
import activitiesDb from "./activities/activities.db"
import { ConstraintsViewModel } from "./constraints/constraints"
import constraintsDb from "./constraints/constraints.db"
import { MilestonesViewModel } from "./milestones/milestones"
import PlanscanDb from "./planscan.db"
import { PlanscanSchemaWithId } from "./planscan.schema"

@Module({
    dynamic: true,
    store,
    name: "Planscan",
    namespaced: true
})
export default class PlanscanStore extends VuexModule {
    public loading = true
    public allActivities: Array<ActivitiesViewModel> = []
    public allConstraints: Array<ConstraintsViewModel> = []
    public allMilestones: Array<MilestonesViewModel> = []

    public allRawActivities: Array<ActivitiesSchemaWithId> = []
    public allRawConstraints: Array<ConstraintsSchemaWithId> = []
    public allRawMilestones: Array<MilestonesSchemaWithId> = []

    public currentSchedule: PlanscanSchemaWithId | null = null
    public pendingActivityUpdate = false
    public pendingConstraintUpdate = false

    @Mutation
    public setLoading(loading: boolean): void {
        this.loading = loading
    }

    @Mutation
    public setAllActivities(allActivities: Array<ActivitiesViewModel>): void {
        this.allActivities = allActivities
    }

    @Mutation
    public setAllRawActivities(allRawActivities: Array<ActivitiesSchemaWithId>): void {
        this.allRawActivities = allRawActivities
        this.pendingActivityUpdate = false
    }

    @Mutation
    public updateRawActivity({ index, updatedActivity }: { index: number; updatedActivity: ActivitiesSchemaWithId }): void {
        this.allRawActivities.splice(index, 1, updatedActivity)
    }

    @Mutation
    public setAllRawConstraints(allRawConstraints: Array<ConstraintsSchemaWithId>): void {
        this.allRawConstraints = allRawConstraints
        this.pendingConstraintUpdate = false
    }

    @Mutation
    public setAllConstraints(allConstraints: Array<ConstraintsViewModel>): void {
        this.allConstraints = allConstraints
    }

    @Mutation
    updateRawConstraint(arg0: { index: number; updatedConstraint: ConstraintsSchemaWithId }): void {
        this.allRawConstraints.splice(arg0.index, 1, arg0.updatedConstraint)
    }

    @Mutation
    public setAllMilestones(allMilestones: Array<MilestonesViewModel>): void {
        this.allMilestones = allMilestones
    }

    @Mutation
    public setAllRawMilestones(allRawMilestones: Array<MilestonesSchemaWithId>): void {
        this.allRawMilestones = allRawMilestones
    }

    @Mutation
    updateRawMilestone(arg0: { index: number; updatedMilestone: MilestonesSchemaWithId }): void {
        this.allRawMilestones.splice(arg0.index, 1, arg0.updatedMilestone)
    }

    @Mutation
    public setCurrentSchedule(schedule: PlanscanSchemaWithId | null): void {
        this.currentSchedule = schedule
    }

    @Action
    public clear(): void {
        this.setAllActivities([])
        this.setAllMilestones([])
        this.setAllConstraints([])
        this.setAllRawActivities([])
        this.setAllRawConstraints([])
        this.setAllRawMilestones([])
        this.setCurrentSchedule(null)
    }

    @Action
    public updateActivityIfExists(updatedActivity: ActivitiesSchemaWithId): void {
        const index = this.allRawActivities.findIndex((storeActivity) => storeActivity.id === updatedActivity.id)

        if (index !== -1) {
            const currentVersion = this.allRawActivities[index]
            if (DateTime.fromISO(updatedActivity.date_modified) <= DateTime.fromISO(currentVersion.date_modified)) return
            this.updateRawActivity({ index, updatedActivity })
        }
    }

    @Action
    public updateMilestoneIfExists(updatedMilestone: MilestonesSchemaWithId): void {
        const index = this.allRawMilestones.findIndex((storeActivity) => storeActivity.id === updatedMilestone.id)

        if (index !== -1) {
            const currentVersion = this.allRawMilestones[index]
            if (DateTime.fromISO(updatedMilestone.date_modified) <= DateTime.fromISO(currentVersion.date_modified)) return
            this.updateRawMilestone({ index, updatedMilestone })
        }
    }

    @Action
    public updateConstraintIfExists(updatedConstraint: ConstraintsSchemaWithId): void {
        const index = this.allRawConstraints.findIndex((storeActivity) => storeActivity.id === updatedConstraint.id)

        if (index !== -1) {
            const currentVersion = this.allRawConstraints[index]
            if (DateTime.fromISO(updatedConstraint.date_modified) <= DateTime.fromISO(currentVersion.date_modified)) return
            this.updateRawConstraint({ index, updatedConstraint })
        }
    }

    @Action
    public async replaceSchedule(schedule: number | PlanscanSchemaWithId): Promise<void> {
        if (typeof schedule === "number") {
            const planscan = await PlanscanDb.readAll({
                filter: {
                    id: schedule
                }
            })

            if (isHttpError(planscan)) return
            this.setCurrentSchedule(planscan[0])
        } else {
            this.setCurrentSchedule(schedule)
        }

        await Promise.all([this.reQueryForConstraints(), this.reQueryForActivities()])
    }

    @Action
    public async reQueryForActivities(): Promise<void> {
        if (!this.currentSchedule) return
        this.setPendingActivityUpdate(true)

        const activities = await activitiesDb.readAll({
            filter: {
                id: this.currentSchedule.data.activities
            }
        })

        if (isHttpError(activities)) return
        this.setAllRawActivities(activities)
    }

    @Mutation
    public setPendingActivityUpdate(arg0: boolean): void {
        this.pendingActivityUpdate = arg0
    }

    @Mutation
    public setPendingConstraintUpdate(arg0: boolean): void {
        this.pendingConstraintUpdate = arg0
    }

    @Action
    public async reQueryForConstraints(): Promise<void> {
        if (!this.currentSchedule) return
        this.setPendingConstraintUpdate(true)

        const constraints = await constraintsDb.readAll({
            filter: {
                id: this.currentSchedule.data.constraints
            }
        })

        if (isHttpError(constraints)) return
        this.setAllRawConstraints(constraints)
    }
}

import BaseSse from "common/BaseSse"
import { AnyParsed } from "common/schemaParser"
import { getModule } from "vuex-module-decorators"

import { WorklogsSchemaWithId } from "./worklogs.schema"
import WorklogsStore from "./worklogs.store"
export default class WorklSse extends BaseSse {
    private worklogsStore = getModule(WorklogsStore)
    public sse(data: AnyParsed): void {
        if (data && data.name === "worklog") this.worklogsStore.updateWorklogIfExists(data.object as WorklogsSchemaWithId)
    }
}

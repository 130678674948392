/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import BaseVueComponent from "common/BaseVueComponent"
import ReadOnlyTextField from "components/widgets/readOnlyTextField/readOnlyTextField.component.vue"
import get from "lodash/get"
import { Component, Prop, Watch } from "vue-property-decorator"
@Component({
    components: {
        ReadOnlyTextField
    }
})
export default class FDropdownBase extends BaseVueComponent {
    @Prop(String) public label!: string
    @Prop(String) public placeholder!: string
    @Prop() public items!: any[]
    @Prop(Boolean) public openOnTabFocus!: boolean
    @Prop() public itemText!: string
    @Prop() public itemValue!: string
    @Prop() public hint!: string
    @Prop() public prependIcon!: string
    @Prop() public prependInnerIcon!: string
    @Prop() public errorMessages!: string
    @Prop() public rules!: string[]
    @Prop() public search!: string
    @Prop() public elementID!: string

    @Prop({ default: "{ offsetY: true }" }) public menuProps!: string
    @Prop({ type: String, default: "mdi-menu-down" }) public appendIcon!: string
    @Prop({ type: String }) public appendOuterIcon!: string
    @Prop({ type: Boolean, default: false }) public smallChips!: boolean
    @Prop({ type: Boolean, default: false }) public chips!: boolean
    @Prop({ type: Boolean, default: false }) public readonly!: boolean
    @Prop({ type: Boolean, default: false }) public allowCreateNew!: boolean
    @Prop({ type: Boolean, default: false }) public allowAutocomplete!: boolean
    @Prop({ type: Boolean, default: false }) public textOnly!: boolean
    @Prop() public itemsIndexToDisable!: number[] | undefined

    @Prop({ type: Boolean, default: false }) public scrollable!: boolean
    @Prop({ type: Boolean, default: false }) public required!: boolean
    @Prop({ type: Boolean, default: false }) public returnObject!: boolean
    @Prop({ type: Boolean, default: false }) public multiple!: boolean
    @Prop({ type: Boolean, default: false }) public hideDetails!: boolean
    @Prop({ type: Boolean, default: false }) public outlined!: boolean
    @Prop({ type: Boolean, default: false }) public outline!: boolean
    @Prop({ type: Boolean, default: false }) public dense!: boolean
    @Prop({ type: Boolean, default: false }) public clearable!: boolean
    @Prop({ type: Function }) public filter!: (item: any, queryText: string, itemText: string) => boolean
    @Prop() public selectedItem!: unknown
    @Prop() public value!: unknown
    @Prop({ type: Boolean, default: false }) public filled!: boolean
    public selectedText = ""
    private wasClicked = false

    public getSelectedItemText(value: string): string {
        if (!this.itemValue || !this.itemText) return value
        if (!isNaN(Number(value)) && Number(value) < 0) return ""

        const schemaObject = this.items.find((element) => {
            const elementValue = get(element, this.itemValue)
            return elementValue === value
        })

        if (!schemaObject) return value

        return get(schemaObject, this.itemText)
    }

    public get selectedItemValue(): unknown {
        return this.value && this.value !== "" ? this.value : this.selectedItem
    }

    public set selectedItemValue(value: unknown) {
        this.onItemsChanged()

        // this.$emit("onSelectedItemChanged", value)
        this.$emit("input", value)
    }

    public itemNeedsToDisable(value: any): boolean {
        if (this.itemsIndexToDisable?.includes(this.items.indexOf(value))) return true

        return false
    }

    public click(value: any): void {
        this.$emit("click", value)
    }

    public focus(value: any): void {
        if (this.openOnTabFocus && !this.wasClicked) {
            // @ts-ignore this could exist
            this.$refs.autocomplete?.activateMenu()
            // @ts-ignore this could exist
            this.$refs.select?.activateMenu()
            // @ts-ignore this could exist
            this.$refs.combobox?.activateMenu()
        }

        this.$emit("focus", value)
    }

    public keydown(value: any): void {
        this.$emit("keydown", value)
    }

    public mousedown(value: any): void {
        this.wasClicked = true
        // NOTE: added the listener to the document to handle the case the user mouse downs on the input then drags the mouse outside of the input and releases
        document.addEventListener(
            "mouseup",
            () => {
                this.wasClicked = false
            },
            { once: true }
        )

        this.$emit("mousedown", value)
    }

    public mouseup(value: any): void {
        this.$emit("mouseup", value)
    }

    public reset(): void {
        // @ts-ignore It may exists
        if (this.$refs["combobox"]) this.$refs["combobox"].reset()
        // @ts-ignore It may exists
        if (this.$refs["autocomplete"]) this.$refs["autocomplete"].reset()
        // @ts-ignore It may exists
        if (this.$refs["select"]) this.$refs["select"].reset()
    }

    clearInput(): void {
        // @ts-ignore It may exists
        if (this.$refs["combobox"]) this.$refs["combobox"].clearableCallback()
        // @ts-ignore It may exists
        if (this.$refs["autocomplete"]) this.$refs["autocomplete"].clearableCallback()
        // @ts-ignore It may exists
        if (this.$refs["select"]) this.$refs["select"].clearableCallback()
    }

    public blur(value: any): void {
        if (value.relatedTarget && value.relatedTarget.type) return
        // @ts-ignore It may exists
        if (this.$refs["combobox"]) this.$refs["combobox"].blur()
        // @ts-ignore It may exists
        if (this.$refs["autocomplete"]) this.$refs["autocomplete"].blur()
        // @ts-ignore It may exists
        if (this.$refs["select"]) this.$refs["select"].blur()

        this.$emit("blur", value)
    }

    public get localSearch(): string {
        return this.search
    }

    public set localSearch(value: string) {
        if (value === this.search) return
        this.$emit("searchChanged", value)
    }

    @Watch("selectedItemValue", { immediate: true })
    @Watch("items", { immediate: true })
    public onItemsChanged(): void {
        if (Array.isArray(this.selectedItemValue)) {
            const output = this.selectedItemValue.map((element) => {
                return this.getSelectedItemText(element)
            })

            this.selectedText = output.join(",")
        } else {
            this.selectedText = this.getSelectedItemText(this.selectedItemValue as string)
        }
    }
}

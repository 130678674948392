export default class ClientTokenWeb implements ClientToken {
    public get(): string | undefined {
        return undefined
    }
    public remove(): boolean {
        return true
    }
    public set(token: string): boolean {
        return true
    }
}

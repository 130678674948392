import { Component, Watch } from "vue-property-decorator"

import FMenuBase from "./fMenu.client.base"

@Component
export default class FMenuWeb extends FMenuBase {
    @Watch("localShow")
    onPropertyChanged(value: boolean, oldValue: boolean): void {
        if (this.allowMenuPointer) {
            // HACK to show and hide menu pointer
            // When localShow is true then we are showing the menu else we are removing that.
            if (value) {
                // @ts-ignore need to cast this
                const protest = document.getElementById("protest") as HTMLElement
                const menuPointer = this.createMenuPointer()
                const oldelement = document.getElementById("menuPointer") as HTMLElement
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (!oldelement) protest.appendChild(menuPointer)
                const opacity = 0
                this.updateOpacity(opacity, menuPointer)
            } else {
                const oldelement = document.getElementById("menuPointer") as HTMLElement
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (oldelement) oldelement.remove()
            }
        }
    }

    /**
     * Gradualy Updates opacity of menu pointer. It will act as a menu show animation
     * updateOpacity is recursive function
     *
     * @param opacity new opacity value for div
     * @param menuPointer DIV to hold MenuPointer HTMLElement.
     */
    private updateOpacity(opacity: number, menuPointer: HTMLElement): void {
        // eslint-disable-next-line
        const _this = this
        if (opacity < 1) {
            opacity = opacity + 0.1
            menuPointer.style.opacity = String(opacity)
            window.setTimeout(function () {
                _this.updateOpacity(opacity, menuPointer)
            }, 50)
        }
    }

    /**
     * Creates menu pointer
     *
     * @returns menu pointer
     */
    private createMenuPointer(): HTMLDivElement {
        const div = document.createElement("div")
        div.className += "v-menu_pointer"
        // As only 1 menu can be shown at a time we dont need to worry about div id duplication
        div.id = "menuPointer"
        // @ts-ignore // children of badge
        const menuBtnDomRect = this.$refs.menuBtn.$children[0].$children[0].$el.getBoundingClientRect() as DOMRect
        // Calculate posion of pointer
        const left = menuBtnDomRect.left - menuBtnDomRect.width / 4
        const top = menuBtnDomRect.top + menuBtnDomRect.height / 2 + 5

        // HACK : Style need to be in parallel with menu style. had to do this in java script object and Moved style from protest.style.scss to here.
        div.style.left = left + "px"
        div.style.top = top + "px"
        div.style.position = "fixed"
        div.style.width = "0px"
        div.style.height = "0px"
        div.style.borderLeft = "15px solid transparent"
        div.style.borderRight = "15px solid transparent"
        div.style.borderBottom = "20px solid white"
        div.style.zIndex = "10"
        div.style.opacity = "0"

        return div
    }
}

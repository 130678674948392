import BaseVueComponent from "common/BaseVueComponent"
import { Component, Prop } from "vue-property-decorator"

@Component
export default class GroupByBase extends BaseVueComponent {
    @Prop(String) public groupBy!: string
    @Prop() public groupKeys!: string[]

    public updateGroupBy(value: string): void {
        this.$emit("onGroupByItemChanged", value)
    }
}

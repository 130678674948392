import BaseDialogComponent from "common/BaseDialogComponent"
import { Component, Prop } from "vue-property-decorator"

@Component
export default class FDialogBase extends BaseDialogComponent {
    @Prop(Boolean) public showCloseButton!: boolean
    @Prop({ type: Boolean, default: false }) public doneButtonLoading!: boolean
    @Prop({ type: Boolean, default: false }) public disableDone!: boolean
    @Prop({ type: Boolean, default: false }) public disableCancel!: boolean
    @Prop({ type: Boolean, default: false }) public allowFullScreen!: boolean
    @Prop({ default: 450 }) public minWidth!: string
    @Prop() public minHeight!: string
    public manuallAppliedFullScreen = false
    public cancel(): void {
        // TODO we need to remove localShow from here and let the consumer of fDialog be required to handle what happens when cancel is done.
        this.$emit("cancel")
        this.localShow = false
    }
    public done(): void {
        setTimeout(() => {
            this.$emit("done")
        }, 30)
    }
}

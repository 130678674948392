import BaseVueComponent from "common/BaseVueComponent"
import searchConfig from "components/widgets/search/search.config.json"
import { Component, Prop } from "vue-property-decorator"

@Component
export default class FuzzySearchBase extends BaseVueComponent {
    public showTips = false
    public searchTipsContent = searchConfig.searchTipsContent

    @Prop() public search: string | undefined
    @Prop() public searchLabel!: string

    public label = this.searchLabel && this.searchLabel.length !== 0 ? this.searchLabel : "Filter by keywords..."
    public get localSearch(): string | undefined {
        return this.search
    }

    public set localSearch(value: string | undefined) {
        this.$emit("searchChange", value)
    }
}

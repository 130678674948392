import Logger from "common/Logger"; const LOGGER = new Logger("BaseDb.ts");import http, { HttpError, isHttpError, makeAuthHeader, makeParams } from "common/http"
import chunk from "lodash/chunk"
import compact from "lodash/compact"
import get from "lodash/get"

export abstract class BaseDb<T extends { id?: number }> {
    protected readonly component: string
    constructor(component: string) {
        this.component = component
    }

    public get apiUrl(): string | undefined {
        return process.env.FLYPAPER_API_URL
    }

    /**
     * Creates a new record in the component
     *
     * @param recordToCreate The record object to send to the db
     * @param args The arguments, sessionId must be the first one
     * @param sessionId
     * @param parentId
     * @returns the created record or an [HTTPError]
     */
    public async create(
        recordToCreate: { id?: number } & Omit<T, "id">,
        sessionId?: string,
        parentId?: number
    ): Promise<Array<T & RequiredId> | HttpError> {
        if (sessionId !== undefined && typeof sessionId !== "string") {
            return {
                errorMessage: "session id was malformed",
                statusCode: 400,
                record: undefined
            }
        }

        const recordResponse = await http.post<Array<T & RequiredId>>(`${this.apiUrl}/${this.component}/storage/`, recordToCreate, {
            headers: {
                ...makeAuthHeader(sessionId)
            },
            params: {
                parentId
            }
        })

        return recordResponse
    }

    /**
     * Create multiple records in database
     *
     * @param recordsToCreate Array of records to create
     * @param args The arguments, sessionId must be the first one
     * @returns the array containing created record or an [HTTPError]
     */
    public async createMany(recordsToCreate: T[], ...args: unknown[]): Promise<Array<(T & RequiredId)[] | HttpError>> {
        const [sessionId] = args
        if (sessionId !== undefined && typeof sessionId !== "string") {
            return [
                {
                    errorMessage: "session id was malformed",
                    statusCode: 400,
                    record: undefined
                }
            ]
        }

        const recordResponseArray: Array<(T & RequiredId)[] | HttpError> = []

        // TODO could we do this async instead to speed it up?
        for (const record of recordsToCreate) {
            const recordResponse = await http.post<Array<T & RequiredId>>(`${this.apiUrl}/${this.component}/storage/`, record, {
                headers: {
                    ...makeAuthHeader(sessionId)
                }
            })

            if (isHttpError(recordResponse)) {
                recordResponse.record = record
                recordResponseArray.push(recordResponse)
                continue
            }

            recordResponseArray.push(recordResponse)
        }

        return recordResponseArray
    }

    /**
     * Deletes a record from the component
     *
     * @param recordId The id of the record to delete
     * @param [sessionId] The user's sessionId
     * @returns An empty array or HttpError
     */
    public async delete(recordId: number, sessionId?: string): Promise<T[] | HttpError> {
        recordId = Number(recordId)
        const recordResponse = await http.delete<T[]>(`${this.apiUrl}/${this.component}/storage/${recordId}`, {
            headers: {
                ...makeAuthHeader(sessionId)
            }
        })

        return recordResponse
    }

    /**
     * Patches the record if propsToPatch length > 0 otherwise updates the entire record
     *
     * @param updatedRecord The updated record that needs to be saved
     * @param propsToPatch The array of props to save to the database, if empty the entire record will be saved
     * @param [arrayMode] The mode to combine arrays if a patch operation is done
     * @param [sessionId] The sessionId to use for the request (required in server files)
     * @returns The saved record
     */
    public async patchOrUpdate(
        updatedRecord: T & RequiredId,
        propsToPatch: Array<string | undefined>,
        arrayMode: PatchArrayMode = "replace",
        sessionId?: string
    ): Promise<Array<T & RequiredId> | HttpError> {
        // patch the record if all of the propsToPatch are strings, if any are undefined then we update the whole thing
        if (propsToPatch.length > 0 && !propsToPatch.some((x) => x === undefined)) {
            const updatePatch: Record<string, unknown> = {}
            // NOTE we pass null into get so that all undefined props are set to null so they are not lost in the stringification when sent to the server
            for (const propToPatch of compact(propsToPatch)) updatePatch[propToPatch] = get(updatedRecord, propToPatch, null)

            return await this.patch(updatedRecord.id, updatePatch, arrayMode, sessionId)
        } else {
            return await this.update(updatedRecord, sessionId)
        }
    }

    /**
     * Patchs base db
     *
     * @param recordId The id of the record
     * @param patch The patch object
     * @param [arraymode] The mode to combine arrays
     * @param [sessionId] The user's sessionId
     * @returns The patched record
     */
    public async patch(
        recordId: number,
        patch: Record<string, unknown>,
        arraymode: PatchArrayMode = "append",
        sessionId?: string
    ): Promise<Array<T & RequiredId> | HttpError> {
        const updatedRecordResponse = await http.patch<Array<T & RequiredId>>(`${this.apiUrl}/${this.component}/storage/${recordId}`, patch, {
            headers: {
                ...makeAuthHeader(sessionId)
            },
            params: {
                arraymode
            }
        })

        return updatedRecordResponse
    }

    /**
     * Reads a record from the component
     *
     * @param recordId The id of the record to read
     * @param [sessionId] optional sessionId to use for the request
     * @returns the record
     */
    public async read(recordId: number, sessionId?: string): Promise<Array<T & RequiredId> | HttpError> {
        recordId = Number(recordId)
        const recordResponse = await http.get<Array<T & RequiredId>>(`${this.apiUrl}/${this.component}/storage/${recordId}`, {
            headers: {
                ...makeAuthHeader(sessionId)
            }
        })

        return recordResponse
    }

    /**
     * Reads the records that reference this record
     *
     * @param recordId The id of the record to find the references to
     * @param referenceComponent The full path to the component that references this record (i.e. daily/worklogs)
     * @param [referencePath] optional path to the array that references this record, defaults to plural form of this record
     * @param [sessionId] optional sessionId to use for the request
     * @returns the records from the referenceComponent that reference this record
     */
    public async readReferences<U>(
        recordId: number,
        referenceComponent: string,
        referencePath?: string,
        sessionId?: string
    ): Promise<Array<U & RequiredId> | HttpError> {
        recordId = Number(recordId)
        referenceComponent = referenceComponent.replaceAll("/", "_")
        const recordResponse = await http.get<Array<U & RequiredId>>(`${this.apiUrl}/${this.component}/storage/${recordId}`, {
            params: {
                referenceComponent,
                referencePath
            },
            headers: {
                ...makeAuthHeader(sessionId)
            }
        })

        return recordResponse
    }

    /**
     * Reads the parents and itself of a record from the component
     *
     * @param recordId The id of the record to read the parents of
     * @param parentIdField The field in the record that contains a reference to the record's parent
     * @param [sessionId] optional sessionId to use for the request
     * @returns the parents and the record itself in an array
     */
    public async readParents(
        recordId: number,
        parentIdField: string,
        sessionId?: string
    ): Promise<Array<T & RequiredId & { depth: number }> | HttpError> {
        recordId = Number(recordId)
        const recordResponse = await http.get<Array<T & RequiredId & { depth: number }>>(`${this.apiUrl}/${this.component}/storage/${recordId}`, {
            params: {
                parents: parentIdField
            },
            headers: {
                ...makeAuthHeader(sessionId)
            }
        })

        return recordResponse
    }

    /**
     * Reads the children and itself of a record from the component
     *
     * @param recordId The id of the record to read the children of
     * @param parentIdField The field in the record that contains a reference to the record's child
     * @param [sessionId] optional sessionId to use for the request
     * @returns the children and the record itself in an array
     */
    public async readChildren(
        recordId: number,
        parentIdField: string,
        sessionId?: string
    ): Promise<Array<T & RequiredId & { depth: number }> | HttpError> {
        recordId = Number(recordId)
        const recordResponse = await http.get<Array<T & RequiredId & { depth: number }>>(`${this.apiUrl}/${this.component}/storage/${recordId}`, {
            params: {
                children: parentIdField
            },
            headers: {
                ...makeAuthHeader(sessionId)
            }
        })

        return recordResponse
    }

    /**
     * Reads all records of the component
     *
     * @template T
     * @param [params] The query parameters to send in the request
     * @param [sessionId] optional sessionId to use for the request
     * @returns all records filtered by @params if any
     */
    public async readAll<U = T & RequiredId>(params?: Record<string, Record<string, any>>, sessionId?: string): Promise<Array<U> | HttpError> {
        // break up the query if filter is only an array of ids and the array is larger than 2000
        if (
            typeof params?.filter === "object" &&
            Object.keys(params.filter).length === 1 &&
            Array.isArray(params.filter.id) &&
            params.filter.id.length > 2000
        ) {
            // LOGGER.debug("Breaking up read all request", { number_of_ids: params.filter.id.length })
            // allow 10 requests of 2000 to all hit at once
            const chunks = chunk(chunk(params.filter.id, 2000), 10)
            const mergedResponse = []
            // let chunkIndex = 1
            for (const chunkOfArray of chunks) {
                const allResults = await Promise.all(
                    chunkOfArray.map((x) => {
                        return http.post<Array<U>>(
                            `${this.apiUrl}/${this.component}/storage/all`,
                            {
                                filter: {
                                    id: x
                                }
                            },
                            {
                                headers: {
                                    ...makeAuthHeader(sessionId)
                                }
                            }
                        )
                    })
                )

                // LOGGER.debug("Chunk done", { chunkIndex, chunkTotal: chunks.length })
                // chunkIndex++

                for (const readAllResponse of allResults) {
                    if (isHttpError(readAllResponse)) return readAllResponse
                    mergedResponse.push(...readAllResponse)
                }
            }

            return mergedResponse
        } else {
            const readAllResponse = await http.post<Array<U>>(
                `${this.apiUrl}/${this.component}/storage/all`,
                { ...makeParams(params) },
                {
                    headers: {
                        ...makeAuthHeader(sessionId)
                    }
                }
            )

            return readAllResponse
        }
    }

    /**
     *  Updates a record in the component
     *
     * @param updatedRecord The updated record to save
     * @param [sessionId] optional sessionId to use for the request
     * @returns the updated record in an array
     */
    public async update(updatedRecord: T, sessionId?: string): Promise<Array<T & RequiredId> | HttpError> {
        const updatedRecordResponse = await http.put<Array<T & RequiredId>>(
            `${this.apiUrl}/${this.component}/storage/${"" + updatedRecord.id}`,
            updatedRecord,
            {
                headers: {
                    ...makeAuthHeader(sessionId)
                }
            }
        )

        return updatedRecordResponse
    }
}

import Logger from "common/Logger"; const LOGGER = new Logger("todos.client.base.ts");import BaseVueComponent from "common/BaseVueComponent"
import { isHttpError } from "common/http"
import TodosDb, { ToDoStatus } from "components/todos/todos.db"
import { TodosUtilsMixin } from "components/todos/todos.utils"
import DataTable from "components/widgets/dataTable/dataTable.component.vue"
import Tags from "components/widgets/tags/tags.component.vue"
import { DateTime } from "luxon"
import { Component, Mixins, Watch } from "vue-property-decorator"

import { TodosSchemaWithId } from "./todos.schema"
/**
 * TODOs Base Component
 *
 * @description The base TODOs component for both Web and Mobile.
 * @augments BaseVueComponent
 * @global
 */
@Component({ components: { DataTable, Tags } })
export default class TodosBase extends Mixins(BaseVueComponent, TodosUtilsMixin) {
    public tableLoading = false

    /**
     * Gets formatted date
     *
     * @param dateString input date string
     * @returns formatted date
     */
    public getFormattedDate(dateString: string): string {
        return DateTime.fromISO(dateString).toFormat("dd MMM yyyy")
    }

    /**
     * Returns todos base
     *
     * @param newVal new list of ids for todos
     * @returns to dos change
     */
    @Watch("user.data.todos", { immediate: true })
    private async onTodosChange(newVal: Array<number>): Promise<void> {
        this.tableLoading = true

        const todoTableData = await TodosDb.readAll({
            filter: {
                id: newVal,
                "data.status": ToDoStatus.pending
            }
        })

        if (!isHttpError(todoTableData)) this.todosStore.setCurrentTodos(todoTableData)

        this.tableLoading = false
    }

    /**
     * Performs action
     *
     * @param link action link
     * @returns action
     */
    protected async performAction(link: string): Promise<void> {
        const apiResponse = await TodosDb.performToDoAction(link)

        if (isHttpError(apiResponse)) {
            this.snackEmitter.showCustomSnack({
                message: "Failed to perform action.",
                color: "error"
            })

            LOGGER.error("Failed to perform action ", apiResponse)
        }
    }

    /**
     * Gets to dosdata
     *
     * @returns action TodosSchemaWithId array in reverse
     */
    get toDosdata(): Array<TodosSchemaWithId> {
        return this.todosStore.currentUserOpenTodos.reverse()
    }
}

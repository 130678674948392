import { lightGreyHorizontalBorders, noTopAndBottomHorizontalBorders, noVerticalBorders } from "components/reports/reports.utils"
import { Content, Table, TDocumentDefinitions } from "pdfmake/build/pdfmake"
import { Component } from "vue-property-decorator"

import DataTableBase from "./dataTable.client.base"

@Component
export default class DataTableWeb extends DataTableBase {
    public pdf(): Content {
        const headerRow = this.headers.map((x) => ({ text: x.text, bold: true }))
        const widths = this.headers.map((x) => (x.width === undefined ? "auto" : x.width))

        const rows: Content[][] = []
        for (const item of this.items) {
            const row: Content[] = []
            for (const column of this.headers) {
                const value = item[column.value] === null || item[column.value] === undefined ? "" : item[column.value]
                row.push({ text: value })
            }

            rows.push(row)
        }

        const table: Table = {
            headerRows: 1,
            widths,
            body: [headerRow, ...rows]
        }

        return {
            table,
            layout: { ...noTopAndBottomHorizontalBorders, ...noVerticalBorders, ...lightGreyHorizontalBorders },
            margin: [0, 20]
        }
    }

    public async printPdf(): Promise<void> {
        const documentDefinition: TDocumentDefinitions = {
            content: this.pdf(),
            defaultStyle: {
                fontSize: 10
            }
        }

        const pdfMake = (await import("pdfmake/build/pdfmake")).default
        const pdfFonts = (await import("pdfmake/build/vfs_fonts")).default
        pdfMake.vfs = pdfFonts.pdfMake.vfs
        pdfMake.createPdf(documentDefinition).open()
    }
}

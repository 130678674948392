import BaseVueComponent from "common/BaseVueComponent"
import { Component, Prop } from "vue-property-decorator"

@Component
export default class ReadOnlyTextFieldBase extends BaseVueComponent {
    @Prop() public value!: string | number | string[]
    @Prop({ default: "", type: String }) public label!: string
    @Prop({ default: "", type: String }) public prependInnerIcon!: string
    @Prop({ default: "", type: String }) public appendInnerIcon!: string
    @Prop({ default: "", type: String }) public prependIcon!: string

    @Prop({ default: false, type: Boolean }) public textOnly!: boolean

    public get icon(): string {
        return this.prependInnerIcon || this.appendInnerIcon || this.prependIcon
    }

    public get localValue(): string {
        return this.value || (!isNaN(Number(this.value)) && Number(this.value) > -1) ? this.value.toString() : " "
    }
}

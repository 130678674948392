import BaseVueComponent from "common/BaseVueComponent"
import compact from "lodash/compact"
import isEqual from "lodash/isEqual"
import uniq from "lodash/uniq"
import { Component, Prop } from "vue-property-decorator"

@Component
export default class TagsBase extends BaseVueComponent {
    @Prop({ default: () => [], type: Array }) public autoCompleteList!: string[]
    @Prop({ default: false }) public disabled!: boolean
    @Prop({ default: false }) public clearable!: boolean
    @Prop({ default: false }) public readonly!: boolean
    @Prop({ default: false }) public textOnly!: boolean
    @Prop({ default: () => "Tags", type: String }) public label!: string
    @Prop({ default: () => "Inner", type: String }) public iconPosition!: string
    public newTag = ""
    @Prop({ default: false }) public reload!: boolean
    public search = ""
    public showEditTags = false
    @Prop({ default: () => [], type: Array }) public value!: string[]

    public get localTagList(): string[] {
        return this.value
    }

    public set localTagList(tagList: string[]) {
        const lowerCaseTags = compact(uniq(tagList.map((tag) => tag.trim().toLowerCase())))
        if (isEqual(this.value.sort(), lowerCaseTags.sort())) return

        this.$emit("input", lowerCaseTags)
    }

    public get tagButtonText(): string {
        if (this.value.length > 0) return "Edit Tags"

        return "Add Tag"
    }

    public get titleCasedTags(): string[] {
        return this.value.map((tag) => {
            return tag.replace(/\b\w/g, (l) => l.toUpperCase())
        })
    }

    public remove(tag: string): void {
        if (this.disabled) return
        const index = this.localTagList.findIndex((x) => x.toLowerCase().trim() === tag.toLowerCase().trim())
        if (index !== -1) this.localTagList = this.localTagList.filter((x) => x.toLowerCase().trim() !== tag.toLowerCase().trim())
    }
}

import BaseVueComponent from "common/BaseVueComponent"
import { Component, Prop } from "vue-property-decorator"

@Component
export default class FAlertBase extends BaseVueComponent {
    @Prop(Boolean) public prominent!: boolean
    @Prop(Boolean) public text!: boolean
    @Prop(String) public type!: string
    @Prop(String) public icon!: string
    @Prop(String) public border!: string
    @Prop(String) public outlined!: string
    @Prop(String) public color!: string
    @Prop(String) public closeText!: string
    @Prop(String) public dismissible!: boolean
    @Prop({ type: Boolean, default: false }) public dense!: boolean
}

type ErrorWithMessage = {
    message: string
}

/**
 * Determines whether error with message is
 *
 * @param error
 * @returns error with message
 */
function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
    return typeof error === "object" && error !== null && "message" in error && typeof (error as Record<string, unknown>).message === "string"
}

/**
 * To error with message
 *
 * @param maybeError
 * @returns error with message
 */
function toErrorWithMessage(maybeError: unknown): ErrorWithMessage {
    if (isErrorWithMessage(maybeError)) return maybeError

    try {
        return new Error(JSON.stringify(maybeError))
    } catch {
        // fallback in case there's an error stringifying the maybeError
        // like with circular references for example.
        return new Error(String(maybeError))
    }
}

/**
 * Gets error message
 *
 * @param error
 * @returns
 */
export function getErrorMessage(error: unknown) {
    return toErrorWithMessage(error).message
}

import { BaseDb } from "common/BaseDb"
import { HttpError, isHttpError } from "common/http"
import { HubSchemaWithId } from "components/hub/hub.schema"

export class HubDb extends BaseDb<HubSchemaWithId> {
    /**
     * Adds user to a hub
     *
     * @param hubId The hub id.
     * @param userId The user id.
     * @param sendInviteEmail Send invite email yes or no.
     * @param invitedBy The user who invited this person.
     * @param [sessionId] The optional session id.
     * @returns user to hub
     */
    public async addUserToHub(
        hubId: number,
        userId: number,
        sendInviteEmail: boolean,
        invitedBy: number,
        sessionId?: string
    ): Promise<HubSchemaWithId[] | HttpError> {
        const hubResponse = await this.read(hubId, sessionId)
        if (isHttpError(hubResponse)) return hubResponse

        const hub = hubResponse[0]

        if (hub.data.users.some((user) => user.id === userId)) return [hub]

        // TODO hook this up to the companies config.json? YES!
        const roles = hub.data.users.length === 0 ? ["admin"] : ["collaborator"]

        hub.data.users.push({
            id: userId,
            roles,
            active: true,
            metadata: {
                invited_by: invitedBy,
                invite_sent: !sendInviteEmail
            }
        })

        return this.update(hub, sessionId)
    }

    /**
     * removeUserFromHub
     *
     * @param hubId The hub id.
     * @param userId The user id.
     * @param [sessionId] the optional session id.
     * @returns HubSchema[]
     */
    public async removeUserFromHub(hubId: number, userId: number, sessionId?: string): Promise<HubSchemaWithId[] | HttpError> {
        const read = await this.read(hubId, sessionId)
        if (isHttpError(read)) return read
        read[0].data.users = read[0].data.users.filter((user) => {
            if (user.id !== userId) return user
        })

        const updated = await this.update(read[0], sessionId)
        if (isHttpError(updated)) return updated
        return updated
    }
}

export default new HubDb("hub")

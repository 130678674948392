import { render, staticRenderFns } from "./document.template?vue&type=template&id=5eac1bca&scoped=true&lang=pug&"
import script from "./document.client?vue&type=script&lang=ts&"
export * from "./document.client?vue&type=script&lang=ts&"
import style0 from "./document.style?vue&type=style&index=0&id=5eac1bca&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5eac1bca",
  null
  
)

export default component.exports
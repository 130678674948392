import { BaseDb } from "common/BaseDb"
import { HttpError, isHttpError } from "common/http"
import { CompaniesSchema, CompaniesSchemaWithId } from "components/companies/companies.schema"
import hubDb from "components/hub/hub.db"
import { DateTime } from "luxon"

class CompaniesDb extends BaseDb<CompaniesSchema> {
    /**
     * Get companies that belong to a specific hub
     *
     * @param hubId The id of the hub to get the companiues from
     * @param [sessionId] Optional session id
     * @returns all companies in the hub
     */
    public async readCompaniesInHub(hubId: number, sessionId?: string): Promise<CompaniesSchemaWithId[] | HttpError> {
        const hub = await hubDb.read(hubId, sessionId)
        if (isHttpError(hub)) return hub

        const companiesInHub = await this.readAll(
            {
                filter: {
                    id: hub[0].data.companies
                }
            },
            sessionId
        )

        return companiesInHub
    }
    /**
     * Creates empty company
     *
     * @param companyName The name of the company
     * @param userId The id of the user creating the company
     * @param hubId The id of the hub in which this company is created
     * @param [sessionId] Optional session id
     * @returns empty company
     */
    public async createEmptyCompany(
        companyName: string,
        userId: number,
        hubId: number | undefined,
        sessionId?: string
    ): Promise<CompaniesSchemaWithId | HttpError> {
        const now = DateTime.utc().toISO()
        const newCompany: CompaniesSchema = {
            created_by: userId,
            modified_by: userId,
            date_created: now,
            date_modified: now,
            data: {
                name: companyName,
                active: true,
                attachments: []
            }
        }

        const newCompanyResponse = await this.create(newCompany, sessionId, hubId)

        return isHttpError(newCompanyResponse) ? newCompanyResponse : newCompanyResponse[0]
    }

    public async create(
        recordToCreate: CompaniesSchema,
        sessionId: string | undefined,
        hubId: number | undefined
    ): Promise<HttpError | (CompaniesSchema & RequiredId)[]> {
        return super.create(recordToCreate, sessionId, hubId)
    }

    /**
     * Gets company name from id
     *
     * @param id The id of the company to be retrieved
     * @param [sessionId] Optional session id to pass.
     * @returns company name from id
     */
    public async getCompanyNameFromId(id: number, sessionId?: string): Promise<string> {
        const company = await this.read(id, sessionId)

        if (isHttpError(company)) return "Nowhere"

        return company[0].data.name
    }

    /**
     * Gets company names from ids
     *
     * @param ids An array of ids
     * @param [sessionId] Optional session id to pass
     * @returns company names from ids
     */
    public async getCompanyNamesFromIds(ids: number[], sessionId?: string): Promise<{ [key: number]: string } | HttpError> {
        const companies = await this.readAll(
            {
                filter: {
                    id: ids
                }
            },
            sessionId
        )

        if (isHttpError(companies)) return companies

        return companies.reduce((map, company) => {
            map[company.id] = company.data.name
            return map
        }, {} as { [key: number]: string })
    }
}

const companiesDb = new CompaniesDb("companies")
export default companiesDb

import BaseVueComponent from "common/BaseVueComponent"
import { Component, Prop } from "vue-property-decorator"

import { updateDateToView } from "../updateDate/updateDate"
import { sortUpdateDates } from "../updateDate/updateDate.utils"

@Component
export default class PickUpdateDateBase extends BaseVueComponent {
    @Prop() public updateDatesToShow!: updateDateToView[]
    @Prop() public selectedUpdateForLookAhead!: string
    @Prop({ default: "Update date" }) public labelText!: string
    @Prop({ default: false, type: Boolean }) public icon!: boolean

    public get localSelectedDate() {
        return this.selectedUpdateForLookAhead
    }
    public set localSelectedDate(item) {
        this.$emit("updateSelectedDate", item)
    }

    public get sortedUpdateDatesToShow() {
        return sortUpdateDates(this.updateDatesToShow)
    }
}

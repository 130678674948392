import AttachmentsBase from "components/attachments/attachments.client.base"
import AttachmentsUploader from "components/attachments/uploader/uploader.component.vue"
import AttachmentsViewer from "components/attachments/viewer/viewer.component.vue"
import { Component } from "vue-property-decorator"

@Component({
    components: {
        AttachmentsUploader,
        AttachmentsViewer
    }
})
export default class AttachmentsWeb extends AttachmentsBase {}

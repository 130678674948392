import { BaseDb } from "common/BaseDb"
import { HttpError, isHttpError } from "common/http"
import { ShiftsSchema } from "components/project/shifts/shifts.schema"
import { DateTime } from "luxon"

class ShiftsDb extends BaseDb<ShiftsSchema> {
    /**
     * Creates new shift
     *
     * @param shiftNumber number of shift within project
     * @param [sessionId] Session id if needed
     * @returns ShiftsSchema
     */
    public async createNewShift(shiftNumber: number, sessionId?: string): Promise<ShiftsSchema | HttpError> {
        const now = DateTime.utc().toISO()
        const newShift: ShiftsSchema = {
            date_created: now,
            modified_by: 0,
            created_by: 0,
            date_modified: now,
            data: {
                name: "Shift " + shiftNumber,
                start_time: "07:00",
                end_time: "17:00"
            }
        }

        const newShiftResponse = await this.create(newShift, sessionId)
        return isHttpError(newShiftResponse) ? newShiftResponse : newShiftResponse[0]
    }
}

const shiftsDb = new ShiftsDb("project/shifts")
export default shiftsDb

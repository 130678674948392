import http, { isHttpError } from "common/http"
import { CompaniesSchemaWithId } from "components/companies/companies.schema"
import { HubSchemaWithId } from "components/hub/hub.schema"
import { ProjectSchemaWithId } from "components/project/project.schema"
import { UsersSchemaWithId } from "components/users/users.schema"

import { FeedbackPostIssueAttachmentParams, FeedbackPostIssueData } from "./feedback"

/**
 * Feedback Create Issue Method
 *
 * @param user User schema
 * @param hub hub schema
 *  @param project Project schema
 * @param company Company schema
 * @param feedbackPostIssueData feedbackPostIssueData
 * @description Create a new feedback issue within Protest and GitLab respectively.
 * @returns FeedbackPostIssueAttachmentParams
 */
export async function feedbackPostIssue(
    user: UsersSchemaWithId,
    hub: HubSchemaWithId,
    project: ProjectSchemaWithId,
    company: CompaniesSchemaWithId,
    feedbackPostIssueData: FeedbackPostIssueData
): Promise<undefined | FeedbackPostIssueAttachmentParams> {
    if (feedbackPostIssueData.title && feedbackPostIssueData.description) {
        /**
         * Tag Feedback Description w/ Metadata
         */
        const submitterMetaData = `\n[Hub: ${hub.data.name}/${hub.id}, User: ${user.data.name}/${user.id}, Email: ${user.data.email}, Company: ${company.data.name}/${company.id}, Project:  ${project.data.name}/${project.id}]\n\n`

        // eslint-disable-next-line prefer-const
        let { description, ...theRestOfFeedback } = feedbackPostIssueData
        description = submitterMetaData + description
        const attachments = feedbackPostIssueData.attachments
        if (feedbackPostIssueData.attachments) {
            for (const attachment of feedbackPostIssueData.attachments)
                description = description + `\n\n ![` + attachment.filename + `](` + attachment.url + `)`
        }

        const feedback: FeedbackPostIssueData = {
            description,
            attachments,
            ...theRestOfFeedback
        }

        const feedbackService = await http.post<FeedbackPostIssueAttachmentParams>(process.env.FLYPAPER_API_URL + "/feedback/create/issue", feedback)

        if (isHttpError(feedbackService)) return

        return feedbackService
    }
}

import BaseSse from "common/BaseSse"
import { AnyParsed } from "common/schemaParser"
import { getModule } from "vuex-module-decorators"

import { NotesSchemaWithId } from "./notes.schema"
import NotesStore from "./notes.store"
export default class NotesSse extends BaseSse {
    private notesStore = getModule(NotesStore)
    public sse(data: AnyParsed): void {
        if (data && data.name === "note") this.notesStore.updateRecordIfExists(data.object as NotesSchemaWithId)
    }
}

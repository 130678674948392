import BaseVueComponent from "common/BaseVueComponent"
import { Component, Prop } from "vue-property-decorator"

@Component
export default class BaseDialogComponent extends BaseVueComponent {
    @Prop({ default: "Cancel" }) public cancelText!: string
    @Prop(Boolean) public dialog!: boolean
    @Prop(Boolean) public hideCancel!: boolean
    @Prop(Boolean) public hideDone!: boolean
    @Prop(Boolean) public hideCancelAndDone!: boolean
    @Prop({ default: "Done" }) public doneText!: string
    @Prop({ type: Boolean, default: false }) public fullscreen!: boolean
    @Prop(Boolean) public hideHeader!: boolean
    @Prop({ type: Boolean, default: false }) public hideOverlay!: boolean
    @Prop({ type: Boolean, default: false }) public persistent!: boolean
    @Prop({ default: "" }) public title!: string
    @Prop(Boolean) public value!: boolean

    @Prop({ default: "auto" }) public width!: string | number
    @Prop({ default: "720" }) public maxWidth!: string | number

    @Prop({ default: false, type: Boolean }) public disableDone!: boolean

    /**
     * Gets local show
     *
     * @returns boolean
     */
    public get localShow(): boolean {
        return this.value
    }

    /**
     * Sets local show
     *
     * @param show A boolean representing if to display the dialog or not.
     */
    public set localShow(show: boolean) {
        this.$emit("input", show)
    }
}

import BaseVueComponent from "common/BaseVueComponent"
import { Component, Prop } from "vue-property-decorator"

@Component
export default class FButtonToggleBase extends BaseVueComponent {
    @Prop() public toggleValue!: string[] | string
    @Prop({ default: false, type: Boolean }) public disabled!: boolean
    @Prop({ default: [], type: Array }) public optionList!: string[]
    @Prop({
        default: function () {
            return [""]
        },
        type: [Array, String]
    })
    public disabledOptions!: string[]
    @Prop({ default: "", type: String }) public activeClass!: string
    @Prop({ default: false, type: Boolean }) public showButton!: boolean
    @Prop({ default: false, type: Boolean }) public multiple!: boolean
    @Prop({ default: false, type: Boolean }) public small!: boolean
    @Prop({ default: false, type: Boolean }) public isMultipleView!: boolean

    public get localToggle(): string[] | string {
        return this.toggleValue
    }

    public set localToggle(toggle: string[] | string) {
        this.$emit("change", toggle)
    }

    public buttonClick(range: string | string) {
        this.$emit("buttonClick", range)
    }

    public isButtonDisabled(option: string): boolean {
        return this.disabledOptions.includes(option)
    }
}

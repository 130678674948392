import { Component } from "vue-property-decorator"

import FToolbarBase from "./fToolbar.client.base"

@Component
export default class FToolbarWeb extends FToolbarBase {
    constructor() {
        super()
        // HACK to do the sticky toolbar
        window.addEventListener("scroll", this.handleScroll)
    }

    beforeDestroy() {
        window.removeEventListener("scroll", this.handleScroll)
    }

    public handleScroll() {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (this.tab === undefined) return
        if (!this.elementId) return
        const firstDummy = "navbarMarginElement"
        const secondDummy = "secondDummyToolbarElement"
        if (this.$store.state.userPreferences.stickyMode) {
            const navbar = document.getElementById(this.toolBarId)
            if (!navbar) return
            const navbarMarginElement = document.getElementById(firstDummy)
            if (window.scrollY >= 0 && !navbarMarginElement) {
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (this.stickyClasses && this.stickyClasses.length > 0) {
                    if (this.isEmbedded) navbar.classList.add(this.stickyClasses[0])
                    else navbar.classList.add(this.stickyClasses[1])
                }

                if (this.$route.name === "planscan") {
                    const heightSize = this.$vuetify.breakpoint.name === "sm" ? 115 : 60
                    this.insertAfter(navbar, firstDummy, `${navbar.getClientRects()[0].height + heightSize}px`) // timeline should be added to 64px from top after scroll
                } else {
                    this.insertAfter(navbar, firstDummy, `${navbar.getClientRects()[0].height}px`)
                }
            } else if (window.scrollY <= 0) {
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (this.stickyClasses && this.stickyClasses.length > 0) {
                    if (this.isEmbedded) navbar.classList.remove(this.stickyClasses[0])
                    else navbar.classList.remove(this.stickyClasses[1])
                }

                if (navbarMarginElement) navbarMarginElement.outerHTML = ""
            }

            const secondaryToolbar = document.getElementById(this.elementId)
            if (!secondaryToolbar) {
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (this.stickyClasses && this.stickyClasses.length > 0) {
                    if (this.isEmbedded) navbar.classList.remove(this.stickyClasses[0])
                    else navbar.classList.remove(this.stickyClasses[1])
                }

                return
            }

            const navbarY = navbar.getClientRects()[0].y + navbar.getClientRects()[0].height
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (!secondaryToolbar.getClientRects() || !secondaryToolbar.getClientRects()[0]) return
            const secondaryToolbarY = secondaryToolbar.getClientRects()[0].y
            if (secondaryToolbarY - navbarY <= 0) {
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (this.stickyClasses && this.stickyClasses.length > 0) {
                    if (this.isEmbedded) secondaryToolbar.classList.add(this.stickyClasses[2])
                    else secondaryToolbar.classList.add(this.stickyClasses[3])
                }

                this.stickyMode = true

                if (this.$route.name === "Planscan")
                    this.insertAfter(secondaryToolbar, secondDummy, `${secondaryToolbar.getClientRects()[0].height + 60}px`)
                else this.insertAfter(secondaryToolbar, secondDummy, `${secondaryToolbar.getClientRects()[0].height}px`)
                this.stickyRounded = false
                this.$emit("stickyRounded", this.stickyRounded)
            } else {
                const secondDummyElement = document.getElementById(secondDummy)
                // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                if (!secondDummyElement || !secondDummyElement.getClientRects() || !secondDummyElement.getClientRects()[0]) return
                const secondDummyY = secondDummyElement.getClientRects()[0].y
                if (navbarY - secondDummyY < 0) {
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    if (this.stickyClasses && this.stickyClasses.length > 0) {
                        if (this.isEmbedded) secondaryToolbar.classList.remove(this.stickyClasses[2])
                        else secondaryToolbar.classList.remove(this.stickyClasses[3])
                        this.stickyRounded = true
                        this.$emit("stickyRounded", this.stickyRounded)
                    }

                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
                    if (secondDummyElement) secondDummyElement.outerHTML = ""
                }
            }

            const gapChart = document.getElementById("Timeline")
            if (gapChart) {
                let chartHeight = 0
                chartHeight = gapChart.getClientRects()[0].height
                const distanceFromTop = window.scrollY - chartHeight - 130
                document.documentElement.style.setProperty("--toolbarHeight", `${distanceFromTop + 60}px`)
            }
        } else {
            document.documentElement.style.setProperty("--toolbarHeight", "0px")
            const navbar = document.getElementById(this.toolBarId)
            if (!navbar) return
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (this.stickyClasses && this.stickyClasses.length > 0) {
                if (this.isEmbedded) navbar.classList.remove(this.stickyClasses[0])
                else navbar.classList.remove(this.stickyClasses[1])
            }

            const navbarMarginElement = document.getElementById(firstDummy)
            if (navbarMarginElement) navbarMarginElement.outerHTML = ""

            const secondaryToolbar = document.getElementById(this.elementId)
            if (!secondaryToolbar) return

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (this.stickyClasses && this.stickyClasses.length > 0) {
                if (this.isEmbedded) secondaryToolbar.classList.remove(this.stickyClasses[2])
                else secondaryToolbar.classList.remove(this.stickyClasses[3])
            }

            const secondaryMarginElement = document.getElementById(secondDummy)
            if (secondaryMarginElement) secondaryMarginElement.outerHTML = ""
            this.stickyMode = false
            this.stickyRounded = false
        }
    }

    /**
     * Insert element after targetElement
     *
     * @param targetElement targetElement
     * @param name name
     * @param height height
     */
    private insertAfter(targetElement: HTMLElement, name: string, height: string): void {
        const newElement = document.createElement("div")
        newElement.id = name
        newElement.style.height = height
        // target is what you want it to go after. Look for this elements parent.
        const parent = targetElement.parentNode

        if (!parent) return

        // if the parents last child is the targetElement...
        if (parent.lastChild === targetElement) {
            // add the newElement after the target element.
            parent.appendChild(newElement)
        } else {
            // else the target has siblings, insert the new element between the target and it's next sibling.
            parent.insertBefore(newElement, targetElement.nextSibling)
        }
    }
}

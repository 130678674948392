import BaseVueComponent from "common/BaseVueComponent"
import attachmentsConfig from "components/attachments/attachments.config.json"
import { Component, Prop } from "vue-property-decorator"
@Component
export default class AttachmentsBase extends BaseVueComponent {
    @Prop(String) public acceptedFiles!: string
    @Prop({ default: 0, type: Number }) public badge!: number
    @Prop(Boolean) public dialogButtonAttachment!: boolean
    @Prop() public dialogButtonAttachmentWithViewer!: Array<number>
    @Prop(Boolean) public dialogButtonBlockAdd!: boolean
    @Prop(Boolean) public disabled!: boolean
    @Prop(Boolean) public uploadNow!: boolean
    @Prop(Boolean) public uploadOnSave!: boolean
    @Prop(Boolean) public clearSuccessful!: boolean
    @Prop(String) public uploadUrl!: string
    @Prop({ type: Boolean, default: false }) public allowSingleUpload!: boolean
    @Prop(Number) public maxFileSize!: number

    public attachmentsConfig = attachmentsConfig

    public showDialog = false

    public uploading(isUploading: boolean): void {
        this.$emit("uploading", isUploading)
    }
}

import TodosBase from "components/todos/todos.client.base"
import { Component } from "vue-property-decorator"

import { TodosDataViewModel, TodosHeader } from "./todos"
/**
 * TODOs Web Component
 *
 * @description The TODOs component for Web.
 * @augments TodosBase
 * @global
 */
@Component
export default class TodosWeb extends TodosBase {
    public singleExpand = false
    public expanded: Array<TodosDataViewModel> = []

    /**
     * Gets headers
     *
     * @returns TodosHeader
     */
    get headers(): TodosHeader[] {
        return [
            {
                text: "",
                align: "center",
                sortable: false,
                value: "data.icon"
            },
            { text: "Title", align: "center", value: "data.title" },
            { text: "Due Date", align: "center", value: "date_created" },
            { text: "Actions", align: "center", value: "data-table-expand" }
        ]
    }

    /**
     * Handles row click : expand the row
     *
     * @param todosDataViewModel TodosDataViewModel object
     */
    public async handleRowClick(todosDataViewModel: TodosDataViewModel): Promise<void> {
        // Expand panel on row click
        const index = this.expanded.findIndex((x) => x.id === todosDataViewModel.id)
        if (index < 0) this.expanded.push(todosDataViewModel)
        else this.expanded.splice(index)
    }

    /**
     * Handles action click
     *
     * @param link api or url to fire
     * @param type type of link
     * @returns action click
     */
    public async handleActionClick(link: string, type: string): Promise<void> {
        switch (type) {
            case "api":
                this.performAction(link)
                break
            case "link":
                this.$router.replace({
                    path: link
                })

                break
        }
    }

    /**
     * Closes menu
     */
    public closeMenu(): void {
        // @ts-ignore fmenu has this method
        this.$refs.fmenu.closeMenu()
    }
}

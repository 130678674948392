import { DailySchema } from "components/daily/daily.schema"
import { store } from "components/protest.store"
import { Module, Mutation, VuexModule } from "vuex-module-decorators"

@Module({
    dynamic: true,
    store,
    name: "daily",
    namespaced: true
})
export default class DailyStore extends VuexModule {
    public currentDaily: DailySchema | null = null

    @Mutation
    public setCurrentDaily(daily: DailySchema | null): void {
        this.currentDaily = daily
    }
}

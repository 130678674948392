import BaseVueComponent from "common/BaseVueComponent"
import searchConfig from "components/widgets/search/search.config.json"
import { Component, Prop } from "vue-property-decorator"
@Component
export default class SearchBase extends BaseVueComponent {
    public showTips = false
    public searchTipsContent = searchConfig.searchTipsContent

    @Prop({ type: Boolean, default: false }) public dense!: boolean
    @Prop({ type: Boolean, default: false }) public hideSearch!: boolean
    @Prop() public search: string | undefined
    @Prop() public searchLabel!: string
    @Prop() public groupBy: string | undefined
    @Prop() public groupKeys:
        | {
              key: string
              value: string
          }[]
        | undefined

    @Prop() public sortBy: string | undefined
    @Prop({ type: Boolean, default: false }) public hideSortClerable!: boolean
    @Prop() public sortKeys:
        | {
              key: string
              value: string
          }[]
        | undefined

    @Prop({ type: Boolean, default: true }) public sortDesc: boolean | undefined

    public label = this.searchLabel && this.searchLabel.length !== 0 ? this.searchLabel : "Filter by keywords..."
    public get localSearch(): string | undefined {
        return this.search
    }

    public set localSearch(value: string | undefined) {
        this.$emit("searchChange", value)
    }

    public get localGroupBy(): string | undefined {
        return this.groupBy
    }

    public set localGroupBy(value: string | undefined) {
        this.$emit("groupChange", value ? value : this.groupKeys?.[0].value)
    }

    public get localSortBy(): string | undefined {
        return this.sortBy
    }

    public set localSortBy(value: string | undefined) {
        this.$emit("sortChange", value ? value : this.sortKeys?.[0].value)
    }

    public get localSortDesc(): boolean | undefined {
        return this.sortDesc
    }

    public set localSortDesc(value: boolean | undefined) {
        this.$emit("sortDescChange", value)
    }

    public toggleSortDesc(): void {
        if (this.localSortDesc !== undefined) this.localSortDesc = !this.localSortDesc
    }
}

import BaseStore from "common/BaseStore"
import { store } from "components/protest.store"
import { Module } from "vuex-module-decorators"

import { NotesSchemaWithId } from "./notes.schema"

@Module({
    dynamic: true,
    store,
    name: "notes",
    namespaced: true
})
export default class NotesStore extends BaseStore<NotesSchemaWithId> {}

import BaseSse from "common/BaseSse"
import { AnyParsed } from "common/schemaParser"
import compact from "lodash/compact"
import get from "lodash/get"
import set from "lodash/set"
import { DateTime } from "luxon"
import { getModule } from "vuex-module-decorators"

import { ConversationsSchemaWithId } from "./conversations.schema"
import ConversationsStore from "./conversations.store"

export default class ConversationsSse extends BaseSse {
    private conversationsStore = getModule(ConversationsStore)
    public sse(data: AnyParsed): void {
        if (data && data.name === "conversation") {
            // NOTE remove expired sseLocks
            this.conversationsStore.setSseLocks(this.conversationsStore.sseLocks.filter((x) => x.expires > DateTime.local().toMillis()))

            if (!data.object.id) return
            const relatedLocks = this.conversationsStore.sseLocks.filter((x) => x.id === data.object.id)

            // NOTE There is a whole record lock so we just return
            if (relatedLocks.some((x) => x.prop === undefined)) return

            const lockedProps = compact(relatedLocks.map((x) => x.prop))

            const index = this.conversationsStore.conversations.findIndex((conversation) => conversation.id === data.object.id)

            if (index !== -1) {
                const currentVersion = this.conversationsStore.conversations[index]
                let updatedVersion = data.object as ConversationsSchemaWithId
                // NOTE for any prop that is locked we will use the value that is already in the store
                for (const lockedProp of lockedProps) updatedVersion = set(updatedVersion, lockedProp, get(currentVersion, lockedProp))

                const updatedConversations = [...this.conversationsStore.conversations]
                updatedConversations[index] = updatedVersion
                this.conversationsStore.setConversations(updatedConversations)
            }
        }
    }
}

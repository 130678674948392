import { BaseDb } from "common/BaseDb"
import { HttpError, isHttpError } from "common/http"
import { ActivitiesSchemaWithId } from "components/planscan/activities/activities.schema"
import PlanscanDb from "components/planscan/planscan.db"
import ProjectDb from "components/project/project.db"

class ActivitiesDb extends BaseDb<ActivitiesSchemaWithId> {
    public async createMany(
        recordsToCreate: ActivitiesSchemaWithId[],
        projectId: number,
        sessionId: string | undefined
    ): Promise<Array<(ActivitiesSchemaWithId & RequiredId)[] | HttpError>> {
        if (sessionId !== undefined && typeof sessionId !== "string") {
            return [
                {
                    errorMessage: "session id was malformed",
                    statusCode: 400,
                    record: undefined
                }
            ]
        }

        const projectResponse = await ProjectDb.read(projectId, sessionId)
        if (isHttpError(projectResponse)) return [projectResponse]

        const planscanId = projectResponse[0].data.planscan.schedule
        if (!planscanId) {
            return [
                {
                    errorMessage: "planscan id was not found",
                    statusCode: 400,
                    record: undefined
                }
            ]
        }

        const planscanResponse = await PlanscanDb.read(planscanId, sessionId)
        if (isHttpError(planscanResponse)) return [planscanResponse]

        let newActivityNumber = 1
        if (planscanResponse[0].data.activities.length > 0) {
            const highestActivityId = Math.max(...planscanResponse[0].data.activities)

            const activityResponse = await this.read(highestActivityId, sessionId)
            if (isHttpError(activityResponse)) return [activityResponse]
            newActivityNumber = activityResponse[0].data.number + 1
        }

        const recordResponseArray: Array<(ActivitiesSchemaWithId & RequiredId)[] | HttpError> = []

        // TODO this is really slow for a lot of records. We should do some asyncronously, maybe 20 at a time?
        // We should also rework this so all of the requests happen on the server
        for (const [index, record] of recordsToCreate.entries()) {
            record.data.number = newActivityNumber + index
            const recordResponse = await this.create(record, sessionId, planscanId)

            if (isHttpError(recordResponse)) {
                recordResponse.record = record
                recordResponseArray.push(recordResponse)
                continue
            }

            planscanResponse[0].data.activities.push(recordResponse[0].id)

            recordResponseArray.push(recordResponse)
        }

        const updatedPlanscanResponse = await PlanscanDb.update(planscanResponse[0])
        if (isHttpError(updatedPlanscanResponse)) recordResponseArray.push(updatedPlanscanResponse)

        return recordResponseArray
    }
}

const activitiesDb = new ActivitiesDb("planscan/activities")

export default activitiesDb

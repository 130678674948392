import BaseVueComponent from "common/BaseVueComponent"
import { getIconForRecordType } from "components/filters/filters.utils"
import { Component, Prop } from "vue-property-decorator"

@Component
export default class SortByBase extends BaseVueComponent {
    @Prop(String) public sortBy!: string
    @Prop() public sortKeys!: string[] | never
    @Prop({ type: Boolean, default: false }) public hideSortClerable!: boolean
    @Prop(Boolean) public sortDesc!: boolean

    public updateSortBy(value: string): void {
        this.$emit("onSortByItemChanged", value)
    }

    public updateSortOrder(): void {
        this.$emit("onSortOrderChanged")
    }

    public getIconForRecordType(recordType: string | undefined): string | undefined {
        return getIconForRecordType(recordType)
    }
}

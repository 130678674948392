import Logger from "common/Logger"; const LOGGER = new Logger("schemaParser.ts");import { tryEach } from "async"
import { CompaniesSchema, Convert as CompaniesConvert } from "components/companies/companies.schema"
import { ConversationsSchema, Convert as ConversationsConvert } from "components/conversations/conversations.schema"
import { Convert as DailyConvert, DailySchema } from "components/daily/daily.schema"
import { Convert as NoteConvert, NotesSchema } from "components/daily/notes/notes.schema"
import { Convert as WorklogConvert, WorklogsSchema } from "components/daily/worklogs/worklogs.schema"
import { Convert as HubConvert, HubSchema } from "components/hub/hub.schema"
import { ActivitiesSchema, Convert as ActivitiesConvert } from "components/planscan/activities/activities.schema"
import { ConstraintsSchema, Convert as ConstraintsConvert } from "components/planscan/constraints/constraints.schema"
import { Convert as MilestonesConvert, MilestonesSchema } from "components/planscan/milestones/milestones.schema"
import { Convert as PlanscansConvert, PlanscanSchema } from "components/planscan/planscan.schema"
import { AreasSchema, Convert as AreaConvert } from "components/project/areas/areas.schema"
import { Convert as ProjectConvert, ProjectSchema } from "components/project/project.schema"
import { Convert as TodoConvert, TodosSchema } from "components/todos/todos.schema"
import { Convert as UsersConvert, UsersSchema } from "components/users/users.schema"
import { JSONPath } from "jsonpath-plus"

import { getErrorMessage } from "./Errors"
import http, { isHttpError } from "./http"

// TODO need to document this file.
interface WorklogParsed {
    name: "worklog"
    object: WorklogsSchema
}

interface NoteParsed {
    name: "note"
    object: NotesSchema
}

interface UserParsed {
    name: "user"
    object: UsersSchema
}

interface ProjectParsed {
    name: "project"
    object: ProjectSchema
}

interface CompanyParsed {
    name: "company"
    object: CompaniesSchema
}

interface TodoParsed {
    name: "todo"
    object: TodosSchema
}

interface DailyParsed {
    name: "daily"
    object: DailySchema
}

interface PlanscanParsed {
    name: "planscan"
    object: PlanscanSchema
}

interface ActivityParsed {
    name: "activity"
    object: ActivitiesSchema
}

interface ConstraintParsed {
    name: "constraint"
    object: ConstraintsSchema
}

interface MilestoneParsed {
    name: "milestone"
    object: MilestonesSchema
}

interface ConversationParsed {
    name: "conversation"
    object: ConversationsSchema
}

interface HubParsed {
    name: "hub"
    object: HubSchema
}

interface AreaParsed {
    name: "area"
    object: AreasSchema
}

export type AnyParsed =
    | TodoParsed
    | NoteParsed
    | ProjectParsed
    | WorklogParsed
    | UserParsed
    | CompanyParsed
    | DailyParsed
    | PlanscanParsed
    | ActivityParsed
    | ConstraintParsed
    | MilestoneParsed
    | ConversationParsed
    | HubParsed
    | AreaParsed
    | undefined

/**
 * @param data the data to parse
 * @returns A parsed typed object
 */
export default async function parse(data: unknown): Promise<AnyParsed> {
    if (Array.isArray(data)) return

    try {
        const stringToParse = typeof data === "string" ? data : JSON.stringify(data)
        return new Promise<AnyParsed>((resolve, reject) => {
            tryEach<AnyParsed>(
                [
                    (callback): void => {
                        try {
                            callback(null, { name: "worklog", object: WorklogConvert.toWorklogsSchema(stringToParse) })
                        } catch {
                            callback(new Error("no"))
                        }
                    },
                    (callback): void => {
                        try {
                            callback(null, { name: "note", object: NoteConvert.toNotesSchema(stringToParse) })
                        } catch {
                            callback(new Error("no"))
                        }
                    },
                    (callback): void => {
                        try {
                            callback(null, { name: "user", object: UsersConvert.toUsersSchema(stringToParse) })
                        } catch {
                            callback(new Error("no"))
                        }
                    },
                    (callback): void => {
                        try {
                            callback(null, { name: "project", object: ProjectConvert.toProjectSchema(stringToParse) })
                        } catch {
                            callback(new Error("no"))
                        }
                    },
                    (callback): void => {
                        try {
                            callback(null, { name: "hub", object: HubConvert.toHubSchema(stringToParse) })
                        } catch {
                            callback(new Error("no"))
                        }
                    },
                    (callback): void => {
                        try {
                            callback(null, { name: "company", object: CompaniesConvert.toCompaniesSchema(stringToParse) })
                        } catch {
                            callback(new Error("no"))
                        }
                    },
                    (callback): void => {
                        try {
                            callback(null, { name: "todo", object: TodoConvert.toTodosSchema(stringToParse) })
                        } catch {
                            callback(new Error("no"))
                        }
                    },
                    (callback): void => {
                        try {
                            callback(null, { name: "daily", object: DailyConvert.toDailySchema(stringToParse) })
                        } catch {
                            callback(new Error("no"))
                        }
                    },
                    (callback): void => {
                        try {
                            callback(null, { name: "planscan", object: PlanscansConvert.toPlanscanSchema(stringToParse) })
                        } catch {
                            callback(new Error("no"))
                        }
                    },
                    (callback): void => {
                        try {
                            callback(null, { name: "activity", object: ActivitiesConvert.toActivitiesSchema(stringToParse) })
                        } catch {
                            callback(new Error("no"))
                        }
                    },
                    (callback): void => {
                        try {
                            callback(null, { name: "constraint", object: ConstraintsConvert.toConstraintsSchema(stringToParse) })
                        } catch {
                            callback(new Error("no"))
                        }
                    },
                    (callback): void => {
                        try {
                            callback(null, { name: "milestone", object: MilestonesConvert.toMilestonesSchema(stringToParse) })
                        } catch {
                            callback(new Error("no"))
                        }
                    },
                    (callback): void => {
                        try {
                            callback(null, { name: "conversation", object: ConversationsConvert.toConversationsSchema(stringToParse) })
                        } catch {
                            callback(new Error("no"))
                        }
                    },
                    (callback): void => {
                        try {
                            callback(null, { name: "area", object: AreaConvert.toAreasSchema(stringToParse) })
                        } catch {
                            callback(new Error("no"))
                        }
                    }
                ],
                (err, result) => {
                    if (err) {
                        reject("Failed to parse")
                        return
                    }

                    resolve(result)
                }
            )
        })
    } catch (error) {
        LOGGER.info("Failed to parse data", { data, message: getErrorMessage(error) })
        return
    }
}

/**
 *
 * @param schemaUrl The url of the schema to check. E.g. /planscan/activities/schema
 * @param propToCheck The property to search for
 */
export async function doesSchemaHaveProperty(schemaUrl: string, propToCheck: string): Promise<boolean> {
    // NOTE: This probably isn't the best since you will be checking multiple properties for a single schema
    // NOTE: should maybe do the http call outside of this method to only query it one time
    const schema = await http.get<object>(schemaUrl)
    if (isHttpError(schema)) return false
    const props = JSONPath<object[]>({ path: "$..properties", json: schema })
    for (const prop of props) if (Object.keys(prop).includes(propToCheck)) return true

    return false
}

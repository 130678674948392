import BaseVueComponent from "common/BaseVueComponent"
import { Component, Prop } from "vue-property-decorator"

interface TableHeader {
    align?: "start" | "center" | "end"
    class?: string | string[]
    divider?: boolean
    filter?: (value: any, search: string, item: any) => boolean
    sort?: (a: any, b: any) => number
    sortable?: boolean
    text: string
    value: string
    width?: string | number
}

@Component
export default class DataTableBase extends BaseVueComponent {
    @Prop(Boolean) public disablePagination!: boolean
    @Prop(Array) public headers!: TableHeader[]
    @Prop(Boolean) public hideDefaultFooter!: boolean
    @Prop(Array) public items!: any[]
}

import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"persistent":_vm.persistent,"scrollable":"","hide-overlay":_vm.hideOverlay,"fullscreen":_vm.fullscreen || _vm.manuallAppliedFullScreen,"width":_vm.width,"max-width":_vm.maxWidth,"min-height":_vm.minHeight ? _vm.minHeight : ''},model:{value:(_vm.localShow),callback:function ($$v) {_vm.localShow=$$v},expression:"localShow"}},[_c(VCard,{attrs:{"min-width":_vm.minWidth ? _vm.minWidth : undefined}},[(_vm.hideHeader === false)?_c('FToolbar',{staticClass:"flex-grow-0",attrs:{"lowercase":"","customStyle":"color: #ffffff;","backgroundColor":_vm.isDarkMode ? 'complementary' : 'primary',"title":_vm.title,"dense":"","flat":""}},[_c(VSpacer),(_vm.allowFullScreen && !_vm.manuallAppliedFullScreen)?_c('FButton',{staticStyle:{"margin-right":"-24px"},attrs:{"icon":"","prependIcon":"mdi-fullscreen"},on:{"click":_vm.toggleFullscreen}}):_vm._e(),(_vm.allowFullScreen && _vm.manuallAppliedFullScreen)?_c('FButton',{staticStyle:{"margin-right":"-24px"},attrs:{"icon":"","prependIcon":"mdi-fullscreen-exit"},on:{"click":_vm.toggleFullscreen}}):_vm._e(),(!_vm.persistent || _vm.showCloseButton)?_c('FButton',{attrs:{"icon":""},on:{"click":_vm.cancel}},[_c(VIcon,{staticStyle:{"color":"#ffffff"}},[_vm._v("mdi-close")])],1):_vm._e()],1):_vm._e(),_c(VCardText,[_c(VContainer,{staticClass:"pt-2",attrs:{"fluid":""}},[_vm._t("default"),(!_vm.hideCancelAndDone)?_c(VRow,{staticClass:"justify-end"},[(!_vm.hideCancel)?_c('FButton',{attrs:{"outlined":"","disabled":_vm.disableCancel,"prependIcon":"mdi-cancel","buttonText":_vm.cancelText},on:{"click":_vm.cancel}}):_vm._e(),(!_vm.hideDone)?_c('FButton',{attrs:{"outlined":"","disabled":_vm.disableDone,"loading":_vm.doneButtonLoading,"prependIcon":"mdi-check","buttonText":_vm.doneText},on:{"click":_vm.done}}):_vm._e()],1):_vm._e()],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import Logger from "common/Logger"; const LOGGER = new Logger("document.client.web.ts");import { getErrorMessage } from "common/Errors"
import AttachmentsViewerDocumentBase from "components/attachments/viewer/document/document.client.base"
import { DateTime } from "luxon"
import { getDocument, GlobalWorkerOptions, PDFDocumentProxy } from "pdfjs-dist"
import printJS from "print-js"
import { Component, Prop, Watch } from "vue-property-decorator"
@Component
export default class AttachmentsViewerDocumentWeb extends AttachmentsViewerDocumentBase {
    public pageCount = 0
    public currentPage = 1
    public loadedRatio = 0
    public pageRendering = false
    public pageNumPending = -1
    public scale = 100
    public localFullScreenMode = false
    public canvas!: HTMLCanvasElement
    public ctx!: CanvasRenderingContext2D | null
    public pdfDoc!: PDFDocumentProxy

    @Prop() public src!: string
    @Prop() public imageBase64Src!: string
    @Prop({
        default: function () {
            return []
        }
    })
    public pdfsBase64toPrint!: string[]
    @Prop({ type: Boolean, default: true }) public fullScreenMode!: boolean
    @Prop({ type: Boolean, default: true }) public isBase64!: boolean
    @Prop({ type: Boolean, default: false }) public blankStickyMode!: boolean
    @Prop() public name!: string
    @Prop() public names!: string[]
    @Prop() public buttonTexts!: string[]
    @Prop({
        default: function () {
            return []
        },
        type: [Array, String]
    })
    public downloadSrcs!: string[]

    public scaleLevel = [40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160]

    get pdfSrc(): string {
        return this.src
    }

    get pageCountText(): string {
        return "Page " + this.currentPage + " of " + this.pageCount
    }

    public get dark(): boolean {
        return true
    }

    /**
     * Mounted web
     *
     * @returns mounted
     */
    async mounted(): Promise<void> {
        GlobalWorkerOptions.workerSrc = "../../node_modules/pdfjs-dist/build/pdf.worker.mjs"
        // TODO Aniket to reference file from code instead of third-party URL

        if (this.pdfSrc) this.loadPDf(this.pdfSrc)
    }

    public updated(): void {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!this.canvas) return
        this.canvas.onfullscreenchange = (event) => {
            this.localFullScreenMode = !this.localFullScreenMode
        }
    }
    /**
     * Get page info from document, resize canvas accordingly, and render page.
     *
     * @param num Page number.
     */
    public renderPage(num: number): void {
        if (this.pageRendering) {
            this.pageNumPending = num
            return
        }

        this.pageRendering = true
        // Using promise to fetch the page

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const localThis = this

        this.pdfDoc.getPage(num).then(function (page) {
            //  HACK the scaling resulted in reverse action of zoom in and zoom out making 200- scale to fix this reverse action
            let viewport = page.getViewport({
                scale: localThis.minHeight() / page.getViewport({ scale: (200 - localThis.scale) / 100 }).height
            })

            if (!localThis.fullScreenMode) {
                // const unscaledViewport = page.getViewport({ scale: 1 })
                // const scale = Math.min(localThis.canvas.height / unscaledViewport.height, localThis.canvas.width / unscaledViewport.width)
                // viewport = page.getViewport({ scale: scale })
                viewport = page.getViewport({ scale: 800 / page.getViewport({ scale: 1 }).width })
                // HACK 800 here is for pdf quality
                // In non full screen mode width is 100% always and it's set in canvas creation time
            }

            localThis.canvas.height = localThis.blankStickyMode ? viewport.height / 3 : viewport.height
            localThis.canvas.width = localThis.blankStickyMode ? viewport.width / 2.45 : viewport.width

            // Render PDF page into canvas context
            const renderContext = {
                canvasContext: localThis.ctx as CanvasRenderingContext2D,
                viewport: viewport
            }

            const renderTask = page.render(renderContext)

            // Wait for rendering to finish
            renderTask.promise.then(function () {
                localThis.pageRendering = false
                if (localThis.pageNumPending !== -1) {
                    // New page rendering is pending
                    localThis.renderPage(localThis.pageNumPending)
                    localThis.pageNumPending = -1
                }
            })
        })
    }

    /**
     * Populate activities to display
     */
    @Watch("src", { immediate: false })
    public async onNewPdf(): Promise<void> {
        this.loadPDf(this.pdfSrc)
    }

    /**
     * Loads pdf
     *
     * @param pdf pdf
     * @param pdfString
     */
    async loadPDf(pdfString: string): Promise<void> {
        try {
            const pdfTask = getDocument(this.isBase64 ? { data: atob(pdfString) } : pdfString)
            const pdf = await pdfTask.promise

            // eslint-disable-next-line @typescript-eslint/no-this-alias
            this.pdfDoc = pdf
            this.pageCount = this.pdfDoc.numPages
            // Fetch the first page
            const pageNumber = 1
            let localCanvas
            if (this.blankStickyMode) localCanvas = document.getElementById("blankStickyViewer") as HTMLCanvasElement | undefined
            else localCanvas = document.getElementById("viewer") as HTMLCanvasElement | undefined
            if (!localCanvas) return

            // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
            if (localCanvas.getContext) {
                this.canvas = localCanvas
                this.ctx = localCanvas.getContext("2d")
            } else {
                this.canvas = document.createElement("canvas")
                this.canvas.width = 300
                this.canvas.height = 300

                this.ctx = this.canvas.getContext("2d")
                let pdfFViewerDiv
                if (this.blankStickyMode) pdfFViewerDiv = document.getElementById("blankStickypdfViewer")
                else pdfFViewerDiv = document.getElementById("pdfViewer")
                if (pdfFViewerDiv) {
                    pdfFViewerDiv.innerHTML = ""
                    pdfFViewerDiv.appendChild(this.canvas)
                }
            }

            if (!this.fullScreenMode) this.canvas.style.width = "100%"

            this.renderPage(pageNumber)
        } catch (e) {
            LOGGER.error(getErrorMessage(e), { error: e, pdfString })
        }
    }

    /**
     * Go full screen
     */
    public async goFullScreen(): Promise<void> {
        await this.canvas.requestFullscreen()

        window.addEventListener("keydown", this.onFullScreenArrowKeys)
        window.addEventListener("wheel", this.onFullScreenMouseWheel)

        window.onresize = this.resizeWindow
    }

    /**
     * Mouses wheel
     *
     */
    private resizeWindow(): void {
        this.renderPage(this.currentPage)
    }

    private onFullScreenArrowKeys(event: KeyboardEvent): void {
        if (this.localFullScreenMode) {
            switch (event.key) {
                case "ArrowLeft":
                case "ArrowUp":
                    if (this.currentPage !== 1) this.currentPage--

                    break
                case "ArrowRight":
                case "ArrowDown":
                    if (this.currentPage !== this.pageCount) this.currentPage = this.currentPage + 1
                    break
            }
        }
    }

    /**
     * Mouses wheel
     *
     * @param event event
     */
    private onFullScreenMouseWheel(event: WheelEvent): void {
        if (this.localFullScreenMode) {
            if (event.deltaY > 0) {
                if (this.currentPage !== this.pageCount) this.currentPage = this.currentPage + 1
            } else {
                if (this.currentPage !== 1) this.currentPage = this.currentPage - 1
            }
        }
    }

    @Watch("src", { immediate: true, deep: true })
    public async resetPdfDate(): Promise<void> {
        this.currentPage = 1
    }

    @Watch("currentPage", { immediate: false, deep: true })
    @Watch("scale", { immediate: false, deep: true })
    public async updatePage(): Promise<void> {
        this.renderPage(this.currentPage)
    }

    get viewPrintMenuMode(): boolean {
        return this.pdfsBase64toPrint.length > 1
    }

    public initPrintDocument(): void {
        if (!this.viewPrintMenuMode) this.printPdf()
    }

    /**
     * Prints pdf
     *
     * @param documentIndex document index to launch
     */
    public printPdf(documentIndex = -1): void {
        if (this.pdfsBase64toPrint.length > 0 && documentIndex > -1) {
            printJS({
                printable: this.pdfsBase64toPrint[documentIndex],
                type: "pdf",
                base64: true
            })
        } else {
            if (this.isBase64) {
                printJS({
                    printable: this.pdfSrc,
                    type: "pdf",
                    base64: true
                })
            } else {
                printJS(this.pdfSrc)
            }
        }

        this.$emit("printComplied")
    }

    public minHeight(): number {
        // HACK to make sure the height of pdf will remain same all time when window resize happen
        // TODO Fetch toolbar height dynamically. For now it's hard-coded to 70
        return window.innerHeight - 70
    }

    /**
     * Downloads pdf
     */
    public downloadPdf(): void {
        // WIll; only work for base64
        if (this.isBase64) {
            const downloadLink = document.createElement("a")
            const fileName = DateTime.local().toFormat("yy-MM-dd") + "-" + this.project?.data.name + "-" + this.name + ".pdf"
            const str = "data:application/pdf;base64,"
            downloadLink.href = str + this.pdfSrc
            downloadLink.download = fileName
            downloadLink.click()
            this.$emit("printComplied")
        }
    }

    public downloadMultiPdf(): void {
        // WIll; only work for base64
        if (this.isBase64) {
            this.downloadSrcs.map((src, index) => {
                const downloadLink = document.createElement("a")
                const fileName = DateTime.local().toFormat("yy-MM-dd") + "-" + this.project?.data.name + "-" + this.names[index] + ".pdf"
                const str = "data:application/pdf;base64,"
                downloadLink.href = str + src
                downloadLink.download = fileName
                downloadLink.click()
                if (!fileName.includes("TOC")) this.$emit("printComplied")
            })
        }
    }
}

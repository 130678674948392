import { getAvatarUrl } from "common/avatar"
import { CompaniesSchemaWithId } from "components/companies/companies.schema"
import { companyName } from "components/companies/companies.utils"
import { ProjectSchema, ProjectSchemaWithId } from "components/project/project.schema"
import { UsersSchema, UsersSchemaWithId } from "components/users/users.schema"
import sortBy from "lodash/sortBy"
import Vue from "vue"
import { Component } from "vue-property-decorator"
/**
 * @param userId The id of the user in question.
 * @param users The total list of available users to compare against.
 * @returns {UsersSchema}, or the users email, or a string
 */
export function userEmail(userId: number, users: UsersSchema[]): string {
    const userRecord = users.find((userRecord) => userRecord.id === userId)
    return userRecord ? userRecord.data.email : "Unknown Email"
}

// TODO I think this should be undefined if the user record can't be achieved. The caller of this function

/**
 *  Get user name Id
 *
 * @param userId User Id
 * @param users All users
 * @returns string
 */
export function userName(userId: number, users: UsersSchema[]): string {
    const userRecord = users.find((userRecord) => userRecord.id === userId)
    return userRecord ? userRecord.data.name || userRecord.data.email : "Unknown"
}

/**
 *  Get user name Id
 *
 * @param userId User Id
 * @param users All users
 * @returns string
 */
function dateCreated(userId: number, users: UsersSchema[]): string {
    const userRecord = users.find((userRecord) => userRecord.id === userId)
    return userRecord ? userRecord.date_created : "Unknown"
}

// TODO I think this should be undefined if the user record can't be achieved. The caller of this function

/**
 * Checks if user has permission to create new company
 *
 * @param project Project to check for
 * @param userId Current user id
 * @returns Can you create company
 */
export function allowUserToEditProject(project: ProjectSchemaWithId, userId: number): boolean {
    return project.data.companies_in_project.some((x) => {
        return (
            x.active &&
            x.users.some((user) => {
                return user.id === userId && user.roles.includes("admin")
            })
        )
    })
}

/**
 * Get user picture
 *
 * @param userId User Id
 * @param users All users
 * @param isMobile is mobile
 * @returns User picture string
 */
export function userPicture(userId: number, users: UsersSchema[], isMobile?: boolean): string {
    const userRecord = users.find((userRecord) => userRecord.id === userId)

    if (userRecord) {
        const name = userRecord.data.name !== "" ? userRecord.data.name : userRecord.data.email
        // TODO: User random color for users
        return userRecord.data.picture.trim() !== "" ? userRecord.data.picture : getAvatarUrl(name, undefined, isMobile)
    }

    return ""
}

/**
 * Get user company name is project
 *
 * @param userId user id
 * @param companies companies to choose from
 * @param project user project
 * @returns string
 */
export function userCompanyNameInProject(userId: number, companies: CompaniesSchemaWithId[], project?: ProjectSchemaWithId): string {
    if (!project) return "Unknown company"

    const companyInProject = project.data.companies_in_project.find((company) => {
        return company.users.some((user) => user.id === userId)
    })

    if (!companyInProject) return "Unknown company"

    return companyName(companyInProject.company_id, companies)
}

/**
 * Get discipline from company
 *
 * @param companyId company id
 * @param project user project
 * @returns array of string
 */
export function getDiscipline(companyId: number, project?: ProjectSchemaWithId): string[] {
    if (!project) return []

    const companyInProject = project.data.companies_in_project.find((company) => {
        return company.company_id === companyId
    })

    if (!companyInProject) return []

    return companyInProject.disciplines || []
}

/**
 * Get company details from discipline
 *
 * @param discipline discipline
 * @param project user project
 * @returns array of companies in project
 */
export function getCompanyFromDiscipline(discipline: string, project: ProjectSchemaWithId): number | undefined {
    const companyInProject = project.data.companies_in_project.filter((company) => {
        if (company.disciplines?.includes(discipline)) return company
    })

    if (companyInProject.length === 0) return

    return companyInProject[0].company_id
}

/**
 * Get project user's project company
 *
 * @param project Current project
 * @param user Current user
 * @param userCompany User company
 * @param allCompanies Company to search from
 * @returns List of companies in project
 */
export function availableUserCompanies(
    project: ProjectSchemaWithId,
    user: UsersSchemaWithId,
    userCompany: CompaniesSchemaWithId,
    allCompanies: CompaniesSchemaWithId[]
): CompaniesSchemaWithId[] {
    if (isUserCollaboratorOnly(user.id, project)) return [userCompany]

    const companyIdsInProject = project.data.companies_in_project.map((x) => {
        if (x.active) return x.company_id
    })

    return sortBy(
        allCompanies.filter((company) => {
            return companyIdsInProject.indexOf(company.id) !== -1
        }),
        "data.name"
    )
}

// TODO Need this function until ticket #1052 is decided
/**
 * Check and returns if user is only project collaborator and not admin and team member
 *
 * @param userId user Id of user to check
 * @param project current project
 * @returns boolean
 */
export function isUserCollaboratorOnly(userId: number, project: ProjectSchema): boolean {
    const isUserAProjectCollaborator = project.data.companies_in_project.some((company) => {
        return company.users.find(
            (user) =>
                user.id === userId && user.roles.includes("collaborator") && !user.roles.includes("admin") && !user.roles.includes("team member")
        )
    })

    return isUserAProjectCollaborator
}

/**
 * Check and returns if user is project collaborator
 *
 * @param userId user Id of user to check
 * @param project project data
 * @returns boolean
 */
export function isUserCollaborator(userId: number, project: ProjectSchema): boolean {
    const isUserCollaborator = project.data.companies_in_project.some((company) => {
        return company.users.find((user) => user.id === userId && user.roles.includes("collaborator"))
    })

    return isUserCollaborator
}

/**
 * Check and returns if user is project collaborator
 *
 * @param userId user Id of user to check
 * @param project project data
 * @returns boolean
 */
export function isUserProjectTeamMember(userId: number, project: ProjectSchema): boolean {
    const isUserTeamMember = project.data.companies_in_project.some((company) => {
        return company.users.find((user) => user.id === userId && user.roles.includes("team member"))
    })

    return isUserTeamMember
}

/**
 * Determines whether user is viewer
 *
 * @param project the project to check
 * @param userId  the user's id
 * @returns true if the user is a viewer
 */
export function isUserViewer(project: ProjectSchema, userId: number): boolean {
    const isviewer = project.data.companies_in_project.some((company) => {
        return company.users.some((user) => {
            return user.id === userId && user.roles.length === 1 && user.roles.includes("viewer") && user.active
        })
    })

    return isviewer
}

/**
 * A simple validate email function to be used independent of the EmailClient class.
 * Will determine if an email provided is a valid email address or not.
 *
 * @param email The email to be tested.
 * @returns true or false
 */
export function isEmailValid(email: string): boolean {
    email = email.trim()
    const expression = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    return expression.test(email)
}

@Component
export class UserUtilsMixin extends Vue {
    public userEmail(userId: number, users: UsersSchema[]): string {
        return userEmail(userId, users)
    }

    public userName(userId: number, users: UsersSchema[]): string {
        return userName(userId, users)
    }

    public userDateCreated(userId: number, users: UsersSchema[]): string {
        return dateCreated(userId, users)
    }

    public userCompanyNameInProject(userId: number, companies: CompaniesSchemaWithId[], project?: ProjectSchemaWithId): string {
        return userCompanyNameInProject(userId, companies, project)
    }

    public userPicture(userId: number, users: UsersSchema[], isMobile?: boolean): string | UsersSchema {
        return userPicture(userId, users, isMobile)
    }
}

import { store } from "components/protest.store"
import { DateTime } from "luxon"
import { Action, Mutation, VuexModule } from "vuex-module-decorators"
import { Module } from "vuex-module-decorators"

@Module({
    dynamic: true,
    store,
    name: "base"
})
export default class BaseStore<T extends { id: number; date_modified: string }> extends VuexModule {
    public records: Array<T> = []

    @Mutation
    public setRecords(records: Array<T>): void {
        this.records = records
    }

    @Mutation
    public updateRecord({ index, updatedRecord }: { index: number; updatedRecord: T }): void {
        this.records.splice(index, 1, updatedRecord)
    }

    @Mutation
    public removeRecord(index: number): void {
        this.records.splice(index, 1)
    }

    @Action
    public updateRecordIfExists(updatedRecord: T): void {
        const index = this.records.findIndex((x) => x.id === updatedRecord.id)

        if (index !== -1) {
            const currentVersion = this.records[index]
            if (DateTime.fromISO(updatedRecord.date_modified) <= DateTime.fromISO(currentVersion.date_modified)) return
            this.updateRecord({ index, updatedRecord })
        }
    }

    @Action
    public removeRecordIfExists(removedRecord: T): void {
        const index = this.records.findIndex((x) => x.id === removedRecord.id)

        if (index !== -1) this.removeRecord(index)
    }
}

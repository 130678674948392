import { Component } from "vue-property-decorator"

import FBadgeBase from "./fBadge.client.base"

@Component({ components: {} })
export default class FBadgeWeb extends FBadgeBase {
    public get styles(): string {
        return this.$store.state.userPreferences.darkMode ? "color:#000000" : "color:#ffffff"
    }
}

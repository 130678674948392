import BaseVueComponent from "common/BaseVueComponent"
import videojs, { VideoJsPlayer, VideoJsPlayerOptions } from "video.js"
import { Component, Prop, Watch } from "vue-property-decorator"

@Component
export default class AttachmentsViewerMediaBase extends BaseVueComponent {
    public player: VideoJsPlayer | undefined
    @Prop({ type: String, default: {} }) public type!: string
    @Prop({ type: String, default: {} }) public src!: string
    @Prop({ type: String, default: {} }) public thumbnail!: string
    @Prop({ type: Boolean, default: false }) public isAudio!: boolean

    public beforeDestroy(): void {
        this.player?.dispose()
    }

    mounted(): void {
        this.initVideoPlayer()
    }

    public destroy(): void {
        this.player?.dispose()
    }

    @Watch("type", { immediate: false })
    @Watch("src", { immediate: false })
    initVideoPlayer(): void {
        // if (!this.type || !this.src) return
        if (!this.player) {
            const videoOptions: VideoJsPlayerOptions = {
                autoplay: false,
                controls: true,
                poster: this.thumbnail,
                inactivityTimeout: this.isAudio ? 0 : undefined,
                controlBar: {
                    fullscreenToggle: this.isAudio ? false : undefined
                },
                sources: [
                    {
                        src: this.src,
                        type: this.type
                    }
                ],
                height: 100
            }

            this.player = videojs(this.$refs.videoPlayer, videoOptions)

            // HACK to center align the HTML element of button
            // @ts-ignore html element check
            const element: HTMLElement = this.player.bigPlayButton.el()
            element.classList.add()
            element.style.left = "0px"
            element.style.top = "0px"
            element.style.padding = "0px"
            element.style.marginLeft = "calc(50% - 1.5em)"
            element.style.marginTop = this.isAudio ? "calc(50px - 1.5em)" : "calc(300px - 1.5em)"
            if (this.isAudio) element.title = "Play Audio"

            const control = document.getElementsByClassName("vjs-control-bar")
            // @ts-ignore html element check
            if (control.length > 0 && this.isAudio) control[0].style.height = "80px"
        }
    }
}

import BaseVueComponent from "common/BaseVueComponent"
import { Component, Prop } from "vue-property-decorator"

@Component
export default class FMenuBase extends BaseVueComponent {
    @Prop(Boolean) public disabled!: boolean
    @Prop(Boolean) public value!: boolean

    @Prop({ default: "mdi-close" }) public icon!: string
    @Prop({ default: "" }) public buttonText!: string
    @Prop({ default: "" }) public tooltip!: string
    @Prop() public minWidth!: number
    @Prop() public maxWidth!: number
    @Prop() public height!: number

    @Prop({ default: 200 }) public nudgeWidth!: number
    @Prop({ default: 0 }) public nudgeBottom!: number
    @Prop({ default: "center center", type: String }) public origin!: string
    @Prop({ default: "scale-transition" }) public transition!: string
    @Prop({ default: false }) public closeOnContent!: boolean

    @Prop({ default: false }) public closeOnClick!: boolean
    @Prop({ type: Boolean, default: false }) public offsetX!: boolean
    @Prop({ type: Boolean, default: false }) public offsetY!: boolean

    @Prop({ default: false }) public outlinedButton!: boolean
    @Prop({ default: false }) public textButton!: boolean
    @Prop({ default: false }) public iconButton!: boolean
    @Prop({ default: "" }) public buttonColor!: string
    @Prop({ default: "" }) public buttonStyle!: string
    @Prop({ default: false, type: Boolean }) public block!: boolean
    @Prop() public appendIcon: string | string[] | undefined
    @Prop({ default: false, type: Boolean }) public noIcon!: boolean

    @Prop(String) public buttonType!: string

    @Prop({ default: false }) public allowMenuPointer!: boolean
    @Prop({ default: 0 }) public badgeValue!: number

    public get localIcon() {
        return this.noIcon ? "" : this.icon
    }

    public get localShow(): boolean {
        return this.value
    }

    public set localShow(value: boolean) {
        this.$emit("input", value)
    }

    public get tooltipText(): string {
        if (typeof this.tooltip === "string") return this.tooltip
        else return ""
    }
    /**
     * Closes menu
     */
    public closeMenu(): void {
        this.localShow = false
    }
}

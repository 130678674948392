import { Component } from "vue-property-decorator"

import SavingIndicatorBase from "./savingIndicator.client.base"

/**
 * Saving Indicator Web Component
 *
 * @description The web Saving Indicator component.
 * @augments BaseVueComponent
 * @global
 */
@Component
export default class SavingIndicatorWeb extends SavingIndicatorBase {}

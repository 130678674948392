import { BaseDb } from "common/BaseDb"
import http, { HttpError, isHttpError, makeAuthHeader } from "common/http"
import { TodosSchema, TodosSchemaWithId } from "components/todos/todos.schema"
import { UsersSchema } from "components/users/users.schema"

export enum ToDoStatus {
    pending,
    completed
}

class TodosDb extends BaseDb<TodosSchema> {
    /**
     * Creates todos db from following params
     *
     * @param todoToCreate TodosSchema object
     * @param userId logged in user
     * @param [sessionId] user session
     * @returns create
     */
    public async create(todoToCreate: TodosSchema, sessionId: string, userId: number): Promise<Array<TodosSchemaWithId> | HttpError> {
        const todoResponse = await super.create(todoToCreate, sessionId)
        if (isHttpError(todoResponse)) return todoResponse

        const userPatch = {
            "data.todos": [todoResponse[0].id]
        }

        const userPatchResponse = await http.patch<UsersSchema[]>(`${this.apiUrl}/users/storage/${userId}`, userPatch, {
            headers: {
                ...makeAuthHeader(sessionId)
            }
        })

        if (isHttpError(userPatchResponse)) return userPatchResponse
        else console.log("issue in userPatchResponse")

        return todoResponse
    }

    /**
     * Performs to do action
     *
     * @param url url of action
     * @returns to do action
     */
    public async performToDoAction(url: string): Promise<void | HttpError> {
        const getResponse = await http.post(`${this.apiUrl}${url}`, "")
        if (isHttpError(getResponse)) return getResponse
        return
    }
}

const todosDb = new TodosDb("todos")
export default todosDb

import { DateTime } from "luxon"
import Vue from "vue"
import { Component } from "vue-property-decorator"

import { UpdateDate } from "../planscan.schema"
import { updateDateToView } from "./updateDate"

/**
 *
 * @param timestamp timestamp
 * @returns time in h:mm a format
 */
export function getTimeShort(timestamp: string): string {
    return DateTime.fromISO(timestamp).toFormat("h:mm a")
}

/**
 * get time
 *
 * @param isoDate iso date
 * @returns time
 */
export function getTime(isoDate: string): string {
    const dateTime = DateTime.fromISO(isoDate)
    return dateTime.hour + ":" + dateTime.minute
}

/**
 * get ISODate from date string
 *
 * @param isoDate iso date
 * @returns string
 */
export function getDate(isoDate: string): string {
    const dateTime = DateTime.fromISO(isoDate)
    return dateTime.toISODate()
}

/**
 *
 * @param date Update date from planscan schema
 * @param index index to push
 * @returns updateDateToView
 */
export function getUpdateDateToView(date: UpdateDate, index: number): updateDateToView {
    return {
        id: index,
        timestamp: date.timestamp,
        title: date.title ? date.title : "Untitled",
        time: getTime(date.timestamp),
        date: getDate(date.timestamp),
        displayName: date.title
            ? date.title + " - " + getDate(date.timestamp) + " - " + getTimeShort(date.timestamp)
            : getDate(date.timestamp) + " - " + getTimeShort(date.timestamp)
    }
}

/**
 * Update dates sort as per timestamp
 *
 * @param updateDates update dates to sort
 * @returns sorted update dates
 */
export function sortUpdateDates(updateDates: updateDateToView[]): updateDateToView[] {
    return updateDates.sort((x, y) => {
        if (DateTime.fromISO(x.timestamp) > DateTime.fromISO(y.timestamp)) return -1
        if (DateTime.fromISO(x.timestamp) < DateTime.fromISO(y.timestamp)) return 1
        return 0
    })
}

@Component
export class UpdateDateUtilsMixin extends Vue {
    public getTimeShort(timestamp: string): string {
        return getTimeShort(timestamp)
    }

    public getTime(isoDate: string): string {
        return getTime(isoDate)
    }

    public getDate(isoDate: string): string {
        return getDate(isoDate)
    }

    public getUpdateDateToView(date: UpdateDate, index: number): updateDateToView {
        return getUpdateDateToView(date, index)
    }

    public sortUpdateDates(updateDates: updateDateToView[]): updateDateToView[] {
        return sortUpdateDates(updateDates)
    }
}

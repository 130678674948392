import { getAvatarUrl } from "common/avatar"
import http, { isHttpError } from "common/http"
import companiesDb from "components/companies/companies.db"
import CompaniesDb from "components/companies/companies.db"
import { CompaniesSchemaWithId } from "components/companies/companies.schema"
import ConstraintsConfig from "components/planscan/constraints/constraints.config.json"
import PlanscanConfig from "components/planscan/planscan.config.json"
import ProjectConfig from "components/project/project.config.json"
import ProjectDb from "components/project/project.db"
import {
    AttachmentReference,
    CompaniesInProject,
    Location,
    ProjectSchema,
    ProjectSchemaWithId,
    User as ProjectUser
} from "components/project/project.schema"
import { UsersSchemaWithId } from "components/users/users.schema"
import cloneDeep from "lodash/cloneDeep"
import compact from "lodash/compact"
import uniq from "lodash/uniq"
import Vue from "vue"
import { Component } from "vue-property-decorator"

/**
 * Gets user project settings
 *
 * @param userId The user's id
 * @param project The project to get the settings from
 * @returns The user's settings within the project
 */
export function getUserProjectSettings(userId: number, project: ProjectSchema): ProjectUser | undefined {
    return project.data.companies_in_project.reduce((projectUser, company) => {
        if (!projectUser) projectUser = company.users.find((user) => user.id === userId)

        return projectUser
    }, undefined as ProjectUser | undefined)
}

/**
 * Counts admins in project
 *
 * @param companies The companies array within the project
 * @returns The number of admins in project
 */
export function countAdminsInProject(companies: CompaniesInProject[]): number {
    return companies.reduce((numberOfAdmins, company) => {
        numberOfAdmins += company.users.reduce((numberOfAdmins, user) => {
            if (user.roles.some((role) => role.toLowerCase() === "admin") && user.active && company.active) numberOfAdmins++

            return numberOfAdmins
        }, 0)

        return numberOfAdmins
    }, 0)
}

/**
 * Gets user project company
 *
 * @param userId The user's id
 * @param project The project to get the user's company from
 * @returns The company the user is a part of in the project or undefined
 */
export function getUserProjectCompany(userId: number, project: ProjectSchema): CompaniesInProject | undefined {
    return project.data.companies_in_project.find((company) => company.users.some((user) => user.id === userId))
}

/**
 * Determines whether user is in a project
 *
 * @param userId The id of the user you want to check
 * @param project The project to check
 * @returns True if the user is in the the project and is active
 */
export function isUserInProject(userId: number, project: ProjectSchema): boolean {
    for (const company of project.data.companies_in_project) if (company.users.some((x) => x.id === userId && x.active === true)) return true

    return false
}

/**
 *
 * @param project project to validate
 * @returns string | ProjectSchema
 */
export function validateProject(project: ProjectSchema): string | ProjectSchema {
    project.data.name = project.data.name.trim()
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!project.data.can_create_shifts) project.data.can_create_shifts = ["admin"]
    if (!project.data.can_create_shifts.includes("admin")) project.data.can_create_shifts.unshift("admin")

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!project.data.can_edit_areas) project.data.can_edit_areas = ["admin"]
    if (!project.data.can_edit_areas.includes("admin")) project.data.can_edit_areas.unshift("admin")

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!project.data.can_add_company) project.data.can_add_company = ["admin"]
    if (!project.data.can_add_company.includes("admin")) project.data.can_add_company.unshift("admin")

    if (project.data.name === "") return "Project name cannot be blank"
    if (project.data.location.lat === -1 || project.data.location.long === -1) return "Please enter a valid project address."

    if (project.data.companies_in_project.length === 0) return "There must be at least one company in order to create a project."

    if (countAdminsInProject(project.data.companies_in_project) < 1) return "There must be at least one project admin in order to create a project."

    for (const company of project.data.companies_in_project) {
        for (const user of company.users)
            if (company.users.filter((x) => x.id === user.id).length > 1) return "Duplicate user entry in project company."
    }

    const userIds = project.data.companies_in_project.reduce((activeUserIds, company) => {
        const activeCompanyUserIds = company.users.filter((x) => x.active === true).map((x) => x.id)
        activeUserIds.push(...activeCompanyUserIds)
        return activeUserIds
    }, [] as number[])

    if (compact(userIds).length !== userIds.length) return "A user cannot be in two companies in the same project."

    return project
}

/**
 * Get a Project's name from Id
 *
 * @param projectId The project id
 * @param projects The list of projects to search
 * @returns The project's name or "Unknown Project"
 */
export function projectName(projectId: number, projects: ProjectSchema[]): string {
    const projectRecord = projects.find((projectRecord) => projectRecord.id === projectId)
    return projectRecord ? projectRecord.data.name : "Unknown Project"
}

/**
 * The project address
 *
 * @param projectId current Project id
 * @param projects All projects
 * @returns Address for project
 */
export function projectAddress(projectId: number, projects: ProjectSchema[]): string {
    const projectRecord = projects.find((projectRecord) => projectRecord.id === projectId)
    return projectRecord ? projectRecord.data.location.address : "Unknown Address"
}

/**
 * Colors luminance
 *
 * @param hex of color
 * @param lum dark/light
 * @returns luminance
 */
export function colorLuminance(hex: string, lum: number): string {
    // validate hex string
    if (!hex) return "000000"
    hex = String(hex).replace(/[^0-9a-f]/gi, "")
    if (hex.length < 6) hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]

    // convert to decimal and change luminosity
    let rgb = "#",
        c,
        i

    for (i = 0; i < 3; i++) {
        c = parseInt(hex.substr(i * 2, 2), 16)
        c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16)
        rgb += ("00" + c).substr(c.length)
    }

    return rgb
}

/**
 * Get project logo or default logo with project name string
 *
 * @param projectId project id to get logo for
 * @param sessionId User session id
 * @param base64 if true returns base64 string
 * @returns string logo
 */
export async function getProjectLogo(
    projectId: number,
    sessionId?: string,
    base64?: boolean
): Promise<{
    url: string
    isSvg: boolean
}> {
    const project = await ProjectDb.read(projectId, sessionId)
    if (isHttpError(project)) {
        return {
            url: "",
            isSvg: false
        }
    }

    const logos: AttachmentReference[] = project[0].data.attachments.filter((attachment) => attachment.tags.indexOf("logo") !== -1)

    if (logos.length > 0) {
        const attachmentStreamUrl = `${process.env.FLYPAPER_HOST}/attachments/thumbnail/${logos[0].id}`
        return {
            url: attachmentStreamUrl,
            isSvg: false
        }
    } else {
        const url = getAvatarUrl(project[0].data.name, undefined)
        let avtarurl = await http.get<string>(url, {
            headers: {
                Authorization: "Bearer " + sessionId
            }
        })

        if (isHttpError(avtarurl)) avtarurl = ""

        return {
            url: avtarurl,
            isSvg: !base64
        }
    }
}

/**
 * Get project logo or default logo with project name string
 *
 * @param projectId project id to get logo for
 * @param projects All projects
 * @returns string logo
 */
export function getProjectLogoSync(projectId: number, projects: ProjectSchemaWithId[]): string {
    const project = projects.find((x) => x.id === projectId)
    if (!project) return ""

    const logos: AttachmentReference[] = project.data.attachments.filter((attachment) => attachment.tags.indexOf("logo") !== -1)

    if (logos.length > 0) {
        const attachmentStreamUrl = `${process.env.FLYPAPER_HOST}/attachments/thumbnail/${logos[0].id}`
        return attachmentStreamUrl
    } else {
        let projectName = project.data.name
        projectName = projectName.split(" ").join("+")
        return `https://ui-avatars.com/api/?name=${projectName}`
    }
}

/**
 * @param project project data
 * @param user user data
 * @returns if user is project team member or admin
 */
export function isProjectTeamMemberOrAdmin(project: ProjectSchemaWithId, user: UsersSchemaWithId): boolean {
    for (const companyInProject of project.data.companies_in_project) {
        if (companyInProject.active) {
            for (const companyUser of companyInProject.users) {
                if (companyUser.active && companyUser.id === user.id)
                    if (companyUser.roles.includes("admin") || companyUser.roles.includes("team member")) return true
            }
        }
    }

    return false
}

/**
 * Get all admin users email
 *
 * @param project Project for which to get admin users for
 * @param allUsers All users
 * @returns string[] | Array of admin user emails
 */
export function getAllAdminUsers(project: ProjectSchemaWithId, allUsers: UsersSchemaWithId[]): string[] {
    const adminEmails: string[] = []
    const projectAdminIds = getAllAdminUserIds(project)

    projectAdminIds.forEach((userId) => {
        const adminUsersToPush = allUsers.find((user) => {
            return userId === user.id
        })

        if (adminUsersToPush) adminEmails.push(adminUsersToPush.data.email)
    })

    return uniq(adminEmails)
}

/**
 * Get all admin users email
 *
 * @param project Project for which to get admin users for
 * @returns string[] | Array of admin user emails
 */
export function getAllAdminUserIds(project: ProjectSchemaWithId): number[] {
    const adminUserIds: number[] = []

    for (const companyInProject of project.data.companies_in_project) {
        if (companyInProject.active) {
            for (const companyUser of companyInProject.users)
                if (companyUser.roles.includes("admin") && companyUser.active) adminUserIds.push(companyUser.id)
        }
    }

    return uniq(adminUserIds)
}

/**
 *
 * @param projectName project Name
 * @param projectCode projectCode
 * @param location location
 * @param enablePlanScan
 * @returns ProjectSchema as blank project
 */
export function createBlankProject(
    projectName: string,
    projectCode?: string | undefined,
    location?: Location | undefined,
    enablePlanScan?: boolean
): ProjectSchema {
    const blankProject: ProjectSchema = {
        date_created: "",
        date_modified: "",
        created_by: 0,
        modified_by: 0,
        data: {
            name: projectName,
            active: true,
            code: projectCode || undefined,
            areas: [],
            distribution_lists: [],
            attachments: [],
            connections: {},
            location: location || ProjectConfig.defaults.location,
            shift_ids: [],
            survey_templates: [],
            workdays: ProjectConfig.weekdays_with_indexes.map((x) => x.day),
            daily: {
                settings: {
                    active: true,
                    automatically_create: false,
                    lock_daily_after: 30
                },
                daily_report_grouping: "Tags"
            },
            planscan: {
                schedule: -1,
                settings: {
                    can_update_dates: PlanscanConfig.defaults.can_update_dates,
                    active: enablePlanScan ?? false,
                    // TODO this needs to come from the hub not the config file.
                    can_complete_activities: PlanscanConfig.defaults.can_complete_activities,
                    // TODO this needs to come from the hub not the config file.
                    constraint_types: ConstraintsConfig.default_constraint_types,
                    ppc: {
                        // TODO this needs to come from the hub not the config file
                        calculation_method: ProjectConfig.ppcCalculationMethods[0],
                        constraint_types: []
                    }
                }
            },
            can_invite_user: ProjectConfig.roles.map((role) => {
                return role.name
            }),
            can_add_company: ProjectConfig.defaultAuthorisedRolesToAddCompany,
            can_create_shifts: ProjectConfig.defaultAuthorisedRoles,
            can_edit_areas: ProjectConfig.defaultAuthorisedRoles,
            daily_ids: [],
            quantity_ids: [],
            quantities: {
                settings: {
                    active: false
                }
            },
            companies_in_project: []
        }
    }

    return blankProject
}

/**
 *
 * @param companyName
 * @param allCompanies
 */
export function getCompanyByName(companyName: string, allCompanies: CompaniesSchemaWithId[]) {
    // TODO this should be done on the server where allCompanies can be checked properly
    const existingCompany = allCompanies.find((company) => {
        return company.data.name.toLowerCase() === companyName.toLowerCase()
    })

    return existingCompany
}

/**
 *
 * @param project
 * @param companyId
 * @param sessionId
 */
export async function addCompanyToProjectRecord(project: ProjectSchema, companyId: number, sessionId?: string) {
    project = cloneDeep(project)
    const companyInProjectIndex = project.data.companies_in_project.findIndex((company) => company.company_id === companyId)

    const companyResponse = await companiesDb.read(companyId, sessionId)
    if (isHttpError(companyResponse)) return companyResponse

    const company = companyResponse[0]

    if (companyInProjectIndex >= 0 && project.data.companies_in_project[companyInProjectIndex].active === false) {
        // NOTE reactivate the company but make sure all the users are marked inactive we don't want users to be reactivated by accident
        project.data.companies_in_project[companyInProjectIndex].active = true
        project.data.companies_in_project[companyInProjectIndex].users = project.data.companies_in_project[companyInProjectIndex].users.map(
            (user) => {
                user.active = false
                return user
            }
        )
    } else if (companyInProjectIndex < 0) {
        project.data.companies_in_project.push({
            company_id: companyId,
            active: true,
            users: [],
            color: company.data.color,
            project_logo: company.data.attachments.find((attachment) => attachment.tags.includes("logo"))
        })
    }

    return project
}

/**
 *
 * @param companyNameToAdd companyNameToAdd
 * @param user user schema
 * @param allCompanies allCompanies
 * @param hubToAddCompanyTo
 * @param projectToAddCompanyTo
 */
export async function createCompanyAndLinkItToHub(
    companyNameToAdd: string,
    user: UsersSchemaWithId,
    allCompanies: CompaniesSchemaWithId[],
    hubToAddCompanyTo: number | undefined,
    projectToAddCompanyTo: number | undefined
): Promise<CompaniesSchemaWithId | undefined> {
    if (projectToAddCompanyTo && !hubToAddCompanyTo) return
    const existingCompany = getCompanyByName(companyNameToAdd, allCompanies)
    const companyToAdd = existingCompany ?? (await CompaniesDb.createEmptyCompany(companyNameToAdd, user.id, hubToAddCompanyTo))
    if (isHttpError(companyToAdd)) return
    if (projectToAddCompanyTo) {
        const projectUpdate = await ProjectDb.addCompanyToProject(companyToAdd.id, projectToAddCompanyTo)
        if (isHttpError(projectUpdate)) return
        return companyToAdd
    } else {
        return companyToAdd
    }
}

@Component
export class ProjectUtilsMixin extends Vue {
    public projectName(projectId: number, projects: ProjectSchema[]): string {
        return projectName(projectId, projects)
    }
    public projectAddress(projectId: number, projects: ProjectSchema[]): string {
        return projectAddress(projectId, projects)
    }

    public projectLogo(
        projectId: number,
        sessionId?: string,
        base64?: boolean
    ): Promise<{
        url: string
        isSvg: boolean
    }> {
        return getProjectLogo(projectId, sessionId, base64)
    }

    public projectLogoSync(projectId: number, projects: ProjectSchemaWithId[]): string {
        return getProjectLogoSync(projectId, projects)
    }
}

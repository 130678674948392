import documentationConfig from "components/documentation/documentation.config.json"
import Vue from "vue"
import { Component } from "vue-property-decorator"

@Component
export class DocumentationUtilsMixin extends Vue {
    public getSrcPath(asset: string): string {
        return `${documentationConfig.assets + asset}`
    }

    public tutorialTitle(category: string): string {
        return `Tutorials for ${category}.`
    }

    // TODO: Update Tutorial typings
    /**
     * get Tutorials For Category and tags
     *
     * @param categories Tutorial category
     * @param tags Tutorial tags
     * @returns tutorials for specified category
     */
    public getTutorials(categories?: string[], tags?: string[]): Tutorial[] {
        // @ts-ignore Typings issue
        return documentationConfig.tutorials.filter((tutorial) => {
            return (
                (categories?.length === 0 && tags?.length === 0) ||
                (categories
                    ?.map((element) => {
                        return element.toLowerCase()
                    })
                    .includes(tutorial.category) &&
                    (tags && tags.length > 0
                        ? tags
                              .map((element) => {
                                  return element.toLowerCase()
                              })
                              .some((r) => tutorial.tags.indexOf(r) >= 0)
                        : true))
            )
        })
    }
}

import Vue from "vue"
import { Component } from "vue-property-decorator"
import { getModule } from "vuex-module-decorators"

import TodosStore from "./todos.store"
@Component
export class TodosUtilsMixin extends Vue {
    protected todosStore = getModule(TodosStore)

    get todosLength(): number {
        return this.todosStore.currentUserOpenTodos.length
    }
}

import FButton from "components/widgets/fButton/fButton.component.vue"
import ButtonDisplayBase from "components/widgets/fToolbar/buttonDisplay/buttonDisplay.client.base"
import compact from "lodash/compact"
import { VNode } from "vue"
import { Component, Prop } from "vue-property-decorator"
import { VList, VListItem, VMenu } from "vuetify/lib"

/**
 * ButtonDisplay Web Component
 */
@Component
export default class ButtonDisplayWeb extends ButtonDisplayBase {
    @Prop(String) buttonMenuMinWidth!: string

    private renderVListItem(h: Vue.CreateElement, slot: VNode): VNode | undefined {
        if (slot.tag) return h(VListItem, [slot])
    }

    public render(h: Vue.CreateElement) {
        const children: VNode[] = []
        const menuItems: VNode[] = []

        const addToRender = (slot: VNode[] | undefined, breakPoint: boolean) => {
            if (slot) {
                if (breakPoint) children.push(...slot)
                else menuItems.unshift(...compact(slot.map((node) => this.renderVListItem(h, node))))
            }
        }

        addToRender(this.$slots["menu-buttons-sm"], this.$vuetify.breakpoint.smAndUp)
        addToRender(this.$slots["menu-buttons-md"], this.$vuetify.breakpoint.mdAndUp)
        addToRender(this.$slots["menu-buttons-lg"], this.$vuetify.breakpoint.lgAndUp)

        if (this.$slots.default) {
            const definedSlots = this.$slots.default
            if (this.$vuetify.breakpoint.width <= this.buttonBreakpoint)
                menuItems.push(...compact(definedSlots.map((slot) => this.renderVListItem(h, slot))))
            else children.push(...definedSlots)
        }

        if (this.$slots["menu-buttons"]) menuItems.push(...compact(this.$slots["menu-buttons"].map((slot) => this.renderVListItem(h, slot))))

        if (menuItems.length > 0) {
            const menu = h(VMenu, {
                props: {
                    "offset-y": true,
                    "min-width": this.buttonMenuMinWidth,
                    "close-on-content-click": false
                },
                scopedSlots: {
                    activator: (props) => {
                        props.props = {
                            flat: true,
                            icon: true,
                            prependIcon: "mdi-dots-vertical",
                            color: this.themedButtonColor
                        }

                        return h(FButton, props)
                    },
                    default: (props) => h(VList, props, menuItems)
                }
            })

            children.unshift(menu)
        }

        if (children.length > 0) return h("v-row", { style: "flex-direction: row-reverse; align-items:center" }, children)
    }
}

import BaseVueComponent from "common/BaseVueComponent"
import { Component, Prop } from "vue-property-decorator"

import actionAlertConfig from "./actionAlert.config.json"

@Component
export default class ActionAlertBase extends BaseVueComponent {
    @Prop({ type: Boolean, default: false }) public disabled!: boolean
    @Prop({ default: "", type: String }) public headerText!: string
    @Prop({ default: "", type: String }) public buttonText!: string
    @Prop({ default: actionAlertConfig.contentText, type: String }) public contentText!: string
    @Prop({ default: "mdi-plus-circle", type: String }) public buttonIcon!: string
    @Prop({ default: "/assets/images/flypaper-icons/daily.svg", type: String }) public alertImage!: string
    @Prop({ type: Boolean, default: false }) public loading!: boolean
    @Prop({ default: "vertical", type: String }) public layout!: string
    @Prop({ type: String }) public customStyle!: string

    get smallScreen(): boolean {
        return this.$vuetify.breakpoint.xs
    }
}

import { BaseDb } from "common/BaseDb"
import http, { HttpError, isHttpError } from "common/http"
import { ProjectSchema, ProjectSchemaWithId } from "components/project/project.schema"
import isEqual from "lodash/isEqual"

import { addCompanyToProjectRecord } from "./project.utils"

export class ProjectDb extends BaseDb<ProjectSchema> {
    /**
     * Adds company to project
     *
     * @param companyId The company id.
     * @param projectId The project id.
     * @param [sessionId] The current user session id.
     * @returns company to project
     */
    public async addCompanyToProject(companyId: number, projectId: number, sessionId?: string): Promise<ProjectSchema[] | HttpError> {
        const projectResponse = await this.read(projectId, sessionId)
        if (isHttpError(projectResponse)) return projectResponse

        const project = projectResponse[0]
        const updatedProjectRecord = await addCompanyToProjectRecord(project, companyId, sessionId)
        if (isHttpError(updatedProjectRecord)) return updatedProjectRecord
        if (isEqual(project, updatedProjectRecord)) return [project]
        return this.update(updatedProjectRecord, sessionId)
    }

    /**
     * Creates a new record in the component
     *
     * @param recordToCreate The record object to send to the db
     * @param sessionId sessionId
     * @param parentId parentId if any
     * @param hubId hubId
     * @param companyName companyName
     * @param companyId
     * @param userId userId
     * @returns the created record or an [HTTPError]
     */
    public async create(
        recordToCreate: ProjectSchema,
        sessionId?: string,
        parentId?: number,
        hubId?: number,
        companyId?: number,
        userId?: number
    ): Promise<Array<ProjectSchemaWithId> | HttpError> {
        const newProject = await http.post<ProjectSchemaWithId[]>(
            `${process.env.FLYPAPER_API_URL}/project/storage`,
            {
                hubId: hubId,
                newProject: recordToCreate,
                companyId: companyId,
                userId: userId
            },
            sessionId
                ? {
                      headers: {
                          Authorization: "Bearer " + sessionId
                      }
                  }
                : undefined
        )

        return newProject
    }
}

const projectDb = new ProjectDb("project")
export default projectDb

import { v4 as uuidv4 } from "uuid"
import { Component } from "vue-property-decorator"
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete"

import FAddress from "./fAddress.client.base"

@Component({
    components: {
        VuetifyGoogleAutocomplete
    }
})
export default class FAddressWeb extends FAddress {
    public id = uuidv4()
    public showSnackEmmiter(): void {
        // throw new Error("Method not implemented.")
    }
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.green-active-btn[data-v-23e16007]:not(.v-btn--disabled) {
    background-color: #4caf50 !important;
    color: #fff !important;
}
.rotateIcon[data-v-23e16007] {
    transform: rotate(180deg);
}
.custom-hover-style[data-v-23e16007]:hover:before {
    background-color: #fff !important;
}
.custom-hover-style-dark[data-v-23e16007]:hover:before {
    background-color: #000 !important;
}
`, "",{"version":3,"sources":["webpack://./components/widgets/fButton/fButton.component.vue"],"names":[],"mappings":";AAGA;IACA,oCAAA;IACA,sBAAA;AACA;AACA;IACA,yBAAA;AACA;AACA;IACA,iCAAA;AACA;AACA;IACA,iCAAA;AACA","sourcesContent":["<script lang=\"ts\" src=\"./fButton.client\"></script>\n<template lang=\"pug\" src=\"./fButton.template\"></template>\n<style scoped>\n.green-active-btn:not(.v-btn--disabled) {\n    background-color: #4caf50 !important;\n    color: #fff !important;\n}\n.rotateIcon {\n    transform: rotate(180deg);\n}\n.custom-hover-style:hover:before {\n    background-color: #fff !important;\n}\n.custom-hover-style-dark:hover:before {\n    background-color: #000 !important;\n}\n</style>\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

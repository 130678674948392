import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isButtonToggle)?_c(VBtn,_vm._b({class:{ 'font-weight-bold': _vm.boldText, 'custom-hover-style-dark': _vm.$store.state.userPreferences.darkMode && _vm.isEmbedded, 'custom-hover-style': !_vm.$store.state.userPreferences.darkMode && _vm.isEmbedded },style:(_vm.customStyle),attrs:{"value":_vm.$props.selectedToggle,"active-class":_vm.activeClass,"href":_vm.href},on:{"click":function (x) { return _vm.$emit('click', x); }}},'v-btn',_vm.$props,false),[_vm._l((_vm.prependIcons),function(icon,index){return (_vm.prependIcons)?_c(VIcon,{key:icon,class:_vm.prependIconSpacers[index]},[_vm._v(_vm._s(icon))]):_vm._e()}),_vm._v(_vm._s(_vm.buttonText)),_vm._l((_vm.appendIcons),function(icon,index){return (_vm.appendIcons)?_c(VIcon,{key:icon,class:_vm.appendIconSpacers[index]},[_vm._v(_vm._s(icon))]):_vm._e()}),_vm._t("default")],2):(!_vm.$props.block)?_c(VBadge,{attrs:{"content":_vm.badgeCount,"value":_vm.badgeCount,"offset-x":_vm.badgeXOffset,"offset-y":"16","overlap":"","bordered":""},scopedSlots:_vm._u([{key:"badge",fn:function(ref){return [_c('span',{style:(_vm.styles)},[_vm._v(_vm._s(_vm.badgeCount))])]}}])},[(_vm.tooltip != '' && _vm.allowTooltip)?_c(VTooltip,{attrs:{"bottom":"","open-delay":_vm.tooltipOpenDelay,"close-delay":_vm.tooltipCloseDelay},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g(_vm._b({class:{ 'font-weight-bold': _vm.boldText, 'custom-hover-style-dark': _vm.$store.state.userPreferences.darkMode && _vm.isEmbedded, 'custom-hover-style': !_vm.$store.state.userPreferences.darkMode && _vm.isEmbedded },style:(_vm.customStyle),attrs:{"active-class":_vm.activeClass,"href":_vm.href},on:{"click":function (x) { return _vm.$emit('click', x); }}},'v-btn',_vm.$props,false),on),[_vm._l((_vm.prependIcons),function(icon,index){return (_vm.prependIcons)?_c(VIcon,{key:icon,class:_vm.prependIconSpacers[index]},[_vm._v(_vm._s(icon))]):_vm._e()}),_c('span',{style:(_vm.truncateTextIfMaxWidth)},[_vm._v(_vm._s(_vm.buttonText))]),_vm._l((_vm.appendIcons),function(icon,index){return (_vm.appendIcons)?_c(VIcon,{key:icon,class:_vm.appendIconSpacers[index]},[_vm._v(_vm._s(icon))]):_vm._e()}),_vm._t("default")],2)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tooltip))])]):[_c(VBtn,_vm._b({class:{ 'font-weight-bold': _vm.boldText, 'custom-hover-style-dark': _vm.$store.state.userPreferences.darkMode && _vm.isEmbedded, 'custom-hover-style': !_vm.$store.state.userPreferences.darkMode && _vm.isEmbedded },style:(_vm.customStyle),attrs:{"active-class":_vm.activeClass,"href":_vm.href},on:{"click":function (x) { return _vm.$emit('click', x); }}},'v-btn',_vm.$props,false),[_vm._l((_vm.prependIcons),function(icon,index){return (_vm.prependIcons)?_c(VIcon,{key:icon,class:_vm.prependIconSpacers[index]},[_vm._v(_vm._s(icon))]):_vm._e()}),_c('span',{style:(_vm.truncateTextIfMaxWidth)},[_vm._v(_vm._s(_vm.buttonText))]),_vm._l((_vm.appendIcons),function(icon,index){return (_vm.appendIcons)?_c(VIcon,{key:icon,class:_vm.appendIconSpacers[index]},[_vm._v(_vm._s(icon))]):_vm._e()}),_vm._t("default")],2)]],2):_c(VRow,[(_vm.tooltip != '' && _vm.allowTooltip)?_c(VTooltip,{attrs:{"bottom":"","open-delay":_vm.tooltipOpenDelay,"close-delay":_vm.tooltipCloseDelay},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VBtn,_vm._g(_vm._b({class:{ 'font-weight-bold': _vm.boldText, 'custom-hover-style-dark': _vm.$store.state.userPreferences.darkMode && _vm.isEmbedded, 'custom-hover-style': !_vm.$store.state.userPreferences.darkMode && _vm.isEmbedded },style:(_vm.customStyle),attrs:{"active-class":_vm.activeClass,"href":_vm.href},on:{"click":function (x) { return _vm.$emit('click', x); }}},'v-btn',_vm.$props,false),on),[_vm._l((_vm.prependIcons),function(icon,index){return (_vm.prependIcons)?_c(VIcon,{key:icon,class:_vm.prependIconSpacers[index]},[_vm._v(_vm._s(icon))]):_vm._e()}),_c('span',{style:(_vm.truncateTextIfMaxWidth)},[_vm._v(_vm._s(_vm.buttonText))]),_vm._l((_vm.appendIcons),function(icon,index){return (_vm.appendIcons)?_c(VIcon,{key:icon,class:_vm.appendIconSpacers[index]},[_vm._v(_vm._s(icon))]):_vm._e()}),_vm._t("default")],2)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.tooltip))])]):[_c(VBtn,_vm._b({class:{ 'font-weight-bold': _vm.boldText, 'custom-hover-style-dark': _vm.$store.state.userPreferences.darkMode && _vm.isEmbedded, 'custom-hover-style': !_vm.$store.state.userPreferences.darkMode && _vm.isEmbedded },style:(_vm.customStyle),attrs:{"active-class":_vm.activeClass,"href":_vm.href},on:{"click":function (x) { return _vm.$emit('click', x); }}},'v-btn',_vm.$props,false),[_vm._l((_vm.prependIcons),function(icon,index){return (_vm.prependIcons)?_c(VIcon,{key:icon,class:_vm.prependIconSpacers[index]},[_vm._v(_vm._s(icon))]):_vm._e()}),_c('span',{style:(_vm.truncateTextIfMaxWidth)},[_vm._v(_vm._s(_vm.buttonText))]),_vm._l((_vm.appendIcons),function(icon,index){return (_vm.appendIcons)?_c(VIcon,{key:icon,class:_vm.appendIconSpacers[index]},[_vm._v(_vm._s(icon))]):_vm._e()}),_vm._t("default")],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }
import BaseVueComponent from "common/BaseVueComponent"
import documentationConfig from "components/documentation/documentation.config.json"
import { FeedbackPostIssueData } from "components/feedback/feedback"
import { feedbackPostIssue } from "components/feedback/feedback.utils"
import FeedbackSubmit from "components/feedback/submit/submit.component.vue"
import { Component, Mixins, Prop, Watch } from "vue-property-decorator"

import { DocumentationUtilsMixin } from "../documentation.utils"
/**
 * Tutorial Base Component
 *
 * @description The base Tutorial component for both Web and Mobile.
 * @augments BaseVueComponent
 * @global
 */
@Component({ components: { FeedbackSubmit } })
export default class TutorialBase extends Mixins(BaseVueComponent, DocumentationUtilsMixin) {
    @Prop({ default: () => [] }) public tags!: string[]
    @Prop({ default: () => [] }) public category!: string[]
    @Prop({ type: Boolean, default: false }) public limitResults!: boolean
    @Prop({ type: Boolean, default: false }) public compactView!: boolean

    public documentationConfig = documentationConfig
    public keys = ["Category", "Title", "Tags", "Description", "Body"]
    public sortBy = "category"
    public search = ""
    public page = 1
    public itemsPerPageOptions = [3, 6, 12, 25, 50, 100]
    public sortDesc = false
    public isFullscreen = false
    public showOpenedTutorial = false
    public openedTutorial: Tutorial | null = null
    public active_tab = 0
    public showMoreTutorials = true
    public showFilters = true
    public downloadMenu = false
    public showSuggestTutorialDialog = false
    public validated = false
    public emailBody = ""
    public emailSubject = ""
    public minimumFilterableTutorials = 6
    public itemsPerPage = this.minimumFilterableTutorials
    public defaultNumberOfTutorials = 3
    public rules = {
        notBlank: (v: string): boolean | string => !!v || "Cannot be blank.",
        noRule: true
    }
    public allTutorials: Tutorial[] = []

    public mounted(): void {
        if (this.limitResults) {
            this.showMoreTutorials = false
            this.showFilters = false
        }
    }

    get tutorialsToDisplay(): Tutorial[] {
        this.allTutorials = this.getTutorials(this.category, this.tags)

        if (!this.showMoreTutorials && this.allTutorials.length > this.defaultNumberOfTutorials)
            return this.allTutorials.slice(this.allTutorials.length - this.defaultNumberOfTutorials)
        else return this.allTutorials
    }

    /**
     * The name of the tutorial
     *
     * @param tutorial The tutorial
     */
    public openTutorial(tutorial: Tutorial): void {
        this.showOpenedTutorial = true
        this.openedTutorial = tutorial
    }

    @Watch("showOpenedTutorial", { immediate: true })
    public showOpenedTutorialWatcher(): void {
        this.active_tab = 0
        if (!this.showOpenedTutorial) this.openedTutorial = {} as Tutorial
    }

    public suggestATutorial(): void {
        if (!this.user) return
        this.showSuggestTutorialDialog = true
    }

    public async submitSuggestedTutorial(): Promise<void> {
        if (!this.project || !this.user || !this.hub || !this.company) {
            this.snackEmitter.showSomethingWentWrongSnack()
            return
        }

        const postData: FeedbackPostIssueData = {
            description: this.emailBody,
            title: this.emailSubject
        }

        const feedbackService = await feedbackPostIssue(this.user, this.hub, this.project, this.company, postData)

        if (!feedbackService) {
            this.snackEmitter.showCustomSnack({
                message: "Failed to send tutorial suggestion to the support team.",
                color: "error"
            })

            return
        }

        this.snackEmitter.showCustomSnack({
            message: "Successfully submited tutorial suggestion to the support.",
            color: "info"
        })
    }

    onShowDetailedView(): void {
        if (this.compactView) this.$emit("onShowDetailedView")
    }
}

import { Component } from "vue-property-decorator"

import FDialogBase from "./fDialog.client.base"

@Component
export default class FDialogWeb extends FDialogBase {
    public toggleFullscreen(): void {
        this.manuallAppliedFullScreen = !this.manuallAppliedFullScreen
    }
    public get isDarkMode(): boolean {
        return this.$store.state.userPreferences.darkMode
    }
}

import BaseSse from "common/BaseSse"
import { AnyParsed } from "common/schemaParser"
import { getModule } from "vuex-module-decorators"

import { ToDoStatus } from "./todos.db"
import TodosStore from "./todos.store"
export default class TodosSse extends BaseSse {
    private todosStore = getModule(TodosStore)
    public sse(data: AnyParsed): void {
        if (data && data.name === "todo") {
            const todoObject = this.todosStore.currentUserOpenTodos.some((todo) => todo.id === data.object.id)

            if (todoObject) {
                const newStatus = data.object.data.status
                if (newStatus === ToDoStatus.completed) {
                    const newCurrentUserOpenTodos = this.todosStore.currentUserOpenTodos.filter((todo) => todo.id !== data.object.id)
                    this.todosStore.setCurrentTodos(newCurrentUserOpenTodos)
                }
            }
        }
    }
}

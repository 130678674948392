import BaseVueComponent from "common/BaseVueComponent"
import { DateTime } from "luxon"
import { Component, Prop, Watch } from "vue-property-decorator"

import { datePresets, isDateValid } from "./dateRangePicker.utils"

@Component
export default class DateRangePickerBase extends BaseVueComponent {
    @Prop({
        default: function () {
            return []
        },
        type: [Array, String]
    })
    public dailyDates!: string[]
    @Prop({
        default: function () {
            return []
        },
        type: [Array, String]
    })
    public availableRanges!: string[]
    @Prop({
        default: function () {
            return []
        },
        type: [Array, String]
    })
    public monthlyDates!: string[]

    @Prop({ default: [] }) public dateRanges!: string[]
    @Prop({ type: String, default: "" }) public yearMaxDate!: string
    @Prop({ type: String, default: "" }) public yearMinDate!: string
    @Prop({ type: Boolean, default: false }) public isDateSticky!: boolean

    public dateRangePicker = false

    public getAllowedDates(val: string) {
        return this.dailyDates.length > 0 ? this.dailyDates.includes(val) : true
    }

    public get dateRange() {
        return this.dateRanges
    }
    public set dateRange(value: string[]) {
        if (this.isDateSticky && this.dateRangePicker) {
            this.$emit("editDateRange", value, this.dateRangePicker)
            const date1 = DateTime.fromFormat(value[0], "yyyy-MM-dd")
            const date2 = DateTime.fromFormat(value[1], "yyyy-MM-dd")
            let start = date1 < date2 ? date1 : date2
            const end = date1 > date2 ? date1 : date2
            const returnDates: string[] = []
            while (end >= start) {
                returnDates.push(start.toFormat("yyyy-MM-dd"))
                start = start.plus({ day: 1 })
            }

            this.$emit("editDateRange", returnDates, this.dateRangePicker)
        } else {
            this.$emit("editDateRange", value, this.dateRangePicker)
        }
    }

    public datePresetsInLabor(preset: string): string[] {
        if (preset !== "Today" && preset !== "Yesterday" && preset !== "Tomorrow") this.dateRangePicker = true
        else this.dateRangePicker = false
        if (preset === "All Time") {
            const end = this.yearMaxDate
            const start = this.yearMinDate
            return [start, end]
        } else {
            return datePresets(preset)
        }
    }

    public get datesRangeToDisplay() {
        return this.dateRange.length > 0 ? [this.dateRange[0], this.dateRange[this.dateRange.length - 1]].join(" ~ ") : "0"
    }

    public get datesToDisplay(): string | void {
        if (this.dateRangePicker) return this.dateRange.length > 0 ? this.dateRange.join(" ~ ") : "0"
        else return this.dateRange.length > 0 ? this.dateRange.join(" , ") : "0"
    }
    public isDailyPresent(date: string): boolean {
        if (this.dailyDates.indexOf(date) !== -1) return true
        else return false
    }

    /**
     * Gets dates in between
     *
     * @param startDate The start date
     * @param endDate The end date
     * @param [isDaily] The is daily check
     * @returns dates in between
     */
    public getDatesInBetween(startDate: string, endDate: string): string[] {
        let start = DateTime.fromFormat(startDate, "yyyy-MM-dd")
        const end = DateTime.fromFormat(endDate, "yyyy-MM-dd")
        const returnDates: string[] = []
        while (end >= start) {
            if (!this.isDateSticky) {
                if (this.isDailyPresent(start.toFormat("yyyy-MM-dd"))) returnDates.push(start.toFormat("yyyy-MM-dd"))
            } else {
                returnDates.push(start.toFormat("yyyy-MM-dd"))
            }

            start = start.plus({ day: 1 })
        }

        return returnDates
    }
    /**
     * Used for download daily attachments. Watches for changes in dateRangerPicker. Will eventually change the behavior of the calendar widget.
     */
    @Watch("dateRangePicker", { immediate: true })
    public datePickerTypeChanged(): void {
        if (this.dateRangePicker) {
            if (this.dateRange.length > 0) {
                this.dateRange.sort()
                this.dateRange = [this.dateRange[0], this.dateRange[this.dateRange.length - 1]]
            }

            this.dateRangePicker = true
        } else {
            if (this.dateRange.length > 0) {
                this.dateRange.sort()
                this.dateRange = this.getDatesInBetween(this.dateRange[0], this.dateRange[this.dateRange.length - 1])
            }
        }
    }

    public get invalidRanges(): string[] {
        if (!this.isDateSticky) {
            return this.availableRanges.filter((range) => {
                return !isDateValid(range, this.dailyDates, this.monthlyDates)
            })
        } else {
            return []
        }
    }
}

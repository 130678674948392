import { TableLayoutFunctions } from "pdfmake/build/pdfmake"

/**
 * @param value
 */
export function capitalizeFirstLetter(value: string) {
    return value.charAt(0).toUpperCase() + value.slice(1)
}

export const blueHeader: TableLayoutFunctions = {
    fillColor(rowIndex: number, node: any) {
        if (rowIndex === 0) return "#00263A"

        return "#ffffff"
    },
    hLineWidth(i: number, node: any) {
        if (i === 0 || i === node.table.body.length) return 0

        return i === node.table.headerRows ? 2 : 0
    },
    vLineWidth(i: number) {
        return 0
    }
}

export const blueHeaderZebraRowsLayout: TableLayoutFunctions = {
    fillColor(rowIndex: number, node: any) {
        if (rowIndex === 0) return "#00263A"

        return rowIndex % 2 === 0 ? "#ECEFF1" : ""
    },
    paddingLeft(i: number) {
        return i === 0 ? 0 : 8
    },
    paddingRight(i: number, node: any) {
        return i === node.table.widths.length - 1 ? 0 : 8
    }
}

export const noVerticalBorders: TableLayoutFunctions = {
    vLineWidth(i: number) {
        return 0
    }
}

export const onlyHorizontalBorderAtHeader: TableLayoutFunctions = {
    hLineWidth(i: number, node: any) {
        if (i === 0 || i === node.table.body.length) return 0

        return i === node.table.headerRows ? 2 : 0
    }
}

export const noHorizontalBorders: TableLayoutFunctions = {
    hLineWidth(i: number, node: any) {
        return 0
    }
}

export const noTopAndBottomHorizontalBorders: TableLayoutFunctions = {
    hLineWidth(i: number, node: any) {
        if (i === 0 || i === node.table.body.length) return 0

        return 1
    }
}

export const lightGreyHorizontalBorders: TableLayoutFunctions = {
    hLineColor(i: number, node: any) {
        return "#cccccc"
    }
}

import { whiteOrBlackText } from "common/client.utils"
/**
 * Returns URL to get avatar
 *
 * @param seed Name string
 * @param backgroundColor avatar background color (ignore )
 * @param isMobile If is mobile
 * @returns url for avatar
 */
export function getAvatarUrl(seed: string, backgroundColor?: string, isMobile?: boolean): string {
    if (isMobile) {
        if (backgroundColor) return "https://api.dicebear.com/9.x/initials/png?seed=" + seed + "&&background=" + backgroundColor
        else return "https://api.dicebear.com/9.x/initials/png?seed=" + seed
    }

    const textColor = backgroundColor ? (whiteOrBlackText(backgroundColor) === "black" ? "#000000" : "#FFFFFF") : undefined
    const seedWithNoSpecialCharacters = seed.replace(/[^a-z0-9]/gi, "-")
    const backgroundColorWithNoSpecialCharacter = backgroundColor ? backgroundColor.replace(/[^a-z0-9]/gi, "") : undefined
    const textColorWithNoSpecialCharacter = textColor ? textColor.replace(/[^a-z0-9]/gi, "") : undefined
    return (
        process.env.FLYPAPER_HOST +
        `/protest/avatar/${seedWithNoSpecialCharacters}/${backgroundColorWithNoSpecialCharacter}/${textColorWithNoSpecialCharacter}`
    )
}

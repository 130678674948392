import BaseDialogComponent from "common/BaseDialogComponent"
import Attachments from "components/attachments/attachments.component.vue"
import AttachmentsViewer from "components/attachments/viewer/viewer.component.vue"
import { FeedbackPostIssueAttachmentParams, FeedbackPostIssueData } from "components/feedback/feedback"
import { Component, Watch } from "vue-property-decorator"

import { AttachmentReference } from "../feedback.schema"
import { feedbackPostIssue } from "../feedback.utils"
/**
 * Submit Base Component
 *
 * @description The base Submit subcomponent of Feedback designed specifically for submission of GitLab issues.
 * @augments BaseVueComponent
 */
@Component({
    components: {
        Attachments,
        AttachmentsViewer
    }
})
export default class SubmitBase extends BaseDialogComponent {
    public attachmentIds: AttachmentReference[] = []
    public feedbackPostIssueAttachmentParams: FeedbackPostIssueAttachmentParams = {
        saved: false,
        upload_url: "/"
    }
    public feedbackPostIssueData: FeedbackPostIssueData = {} as FeedbackPostIssueData
    public isAttachmentUploading = false
    public rulesDescription = [
        (v: string): boolean | string => !!v || "Description is required",
        (v: string): boolean | string => (v && v.length >= 10) || "Description must be at least 10 characters"
    ]
    public rulesTitle = [
        (v: string): boolean | string => !!v || "Title is required",
        (v: string): boolean | string => (v && v.length >= 10) || "Title must be at least 10 characters"
    ]
    public maxFileSize = 10

    /**
     * Feedback Create Issue Method
     *
     * @description Create a new feedback issue within Protest and GitLab respectively.
     */
    public async feedbackPostIssue(): Promise<void> {
        if (!this.project || !this.user || !this.hub || !this.company) {
            this.snackEmitter.showSomethingWentWrongSnack()
            return
        }

        const feedbackService = await feedbackPostIssue(this.user, this.hub, this.project, this.company, this.feedbackPostIssueData)

        if (!feedbackService) {
            this.snackEmitter.showCustomSnack({
                message: "Failed to send feedback issue for FlyPaper.",
                color: "error"
            })

            return
        }

        this.feedbackPostIssueAttachmentParams = feedbackService
        this.localShow = false

        this.snackEmitter.showCustomSnack({
            message: "Successfully created a new Feedback Issue for FlyPaper.",
            color: "success"
        })

        this.feedbackPostIssueData = {} as FeedbackPostIssueData
        this.attachmentIds = []
        this.$emit("input", false)
    }

    // public mergeAttachments(gitLabAttachment: string): void {
    //     const attachment: { alt: string; path: string; url: string; attachmentId: number } = JSON.parse(gitLabAttachment)
    //     // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    //     if (!attachment || attachment.url === undefined) return
    //     if (this.feedbackPostIssueData.attachments === undefined) this.feedbackPostIssueData.attachments = []
    //     this.feedbackPostIssueData.attachments.push({ filename: attachment.alt, url: attachment.url, attachmentId: attachment.attachmentId })
    //     this.attachmentIds.push({ id: attachment.attachmentId, tags: [] })
    //     return
    // }

    public mergeAttachments(attachmentsResponses: string[]): void {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (!attachmentsResponses) return
        attachmentsResponses.map((attachmentsResponse) => {
            const attachment: {
                alt: string
                attachmentId: number
                url: string
            } = JSON.parse(attachmentsResponse)

            if (this.feedbackPostIssueData.attachments === undefined) this.feedbackPostIssueData.attachments = []
            this.feedbackPostIssueData.attachments.push({
                filename: attachment.alt,
                url: attachment.url,
                attachmentId: attachment.attachmentId
            })

            this.attachmentIds.push({ id: attachment.attachmentId, tags: [] })
        })

        return
    }

    @Watch("feedbackPostIssueData", { immediate: true })
    public feedbackAttachments(): void {
        if (!this.feedbackPostIssueData.attachments || this.feedbackPostIssueData.attachments.length === 0) return

        for (const attachment of this.feedbackPostIssueData.attachments) this.attachmentIds.push({ id: attachment.attachmentId, tags: [] })
        return
    }

    public removeAttachment(id: number): void {
        const indexToRemoveFromViewer = this.attachmentIds.findIndex((attachment) => attachment.id === id)
        this.attachmentIds.splice(indexToRemoveFromViewer, 1)

        if (this.feedbackPostIssueData.attachments) {
            const indexToRemoveFromIssueData = this.feedbackPostIssueData.attachments.findIndex((attachment) => attachment.attachmentId === id)
            this.feedbackPostIssueData.attachments.splice(indexToRemoveFromIssueData, 1)
        }

        return
    }

    public isUploading(uploadingInProgress: boolean): void {
        this.isAttachmentUploading = uploadingInProgress
    }
}

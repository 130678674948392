import Logger from "common/Logger"; const LOGGER = new Logger("protest.client.web.ts");import "core-js/stable"
import "url-search-params-polyfill"

import { getNestedPropertyValueOrNull, whiteOrBlackText } from "common/client.utils"
import { isHttpError } from "common/http"
import hubConfig from "components/hub/hub.config.json"
import planscanConfig from "components/planscan/planscan.config.json"
import ProtestBase from "components/protest.client.base"
import { router } from "components/protest.client.routes"
import Todos from "components/todos/todos.component.vue"
import UsersDb from "components/users/users.db"
import { UsersSchemaWithId } from "components/users/users.schema"
import { DateTime } from "luxon"
import { Component, Watch } from "vue-property-decorator"
import Vuetify from "vuetify"
import { VuetifyPreset } from "vuetify/types/services/presets"
import VuetifyGoogleAutocomplete from "vuetify-google-autocomplete"

// eslint-disable-next-line import/no-internal-modules
import variables from "../assets/scss/variables.scss"

const vuetifyOpts: VuetifyPreset = {
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            // @ts-ignore I don't want to set all of the things
            light: {
                primary: variables.primary,
                accent: variables.accent,
                secondary: "#000000"
            },
            // @ts-ignore I don't want to set all of the things
            dark: {
                primary: "#ffffff",
                accent: variables.accent,
                secondary: "#000000",
                complementary: "#00263A"
            }
        }
    }
}

ProtestBase.use(Vuetify)
ProtestBase.use(VuetifyGoogleAutocomplete, {
    apiKey: process.env.FLYPAPER_GOOGLE_MAPS_API_KEY
})

/** Protest Vue Component */
@Component({
    components: {
        Profile: () => import("components/users/profile/profile.component.vue"),
        FeedbackSubmit: () => import("components/feedback/submit/submit.component.vue"),
        Reporting: () => import("components/reporting/reporting.component.vue"),
        InviteUser: () => import("components/project/inviteUser/inviteUser.component.vue"),
        Todos
    },
    router
})
class ProtestWeb extends ProtestBase {
    public searchParams = new URLSearchParams(window.location.search)

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    protected timerId: any = -1
    public scrollToTop = false
    public showTodosNavigationDrawer = false
    private showNavigationDrawer = false

    public showInviteUserMenu = false
    public rolesToChooseFrom: string[] = []
    public allowInviteUser = false
    private sse: EventSource | undefined
    public get pageName(): string {
        if (this.$route.name) return this.$route.name.charAt(0).toUpperCase() + this.$route.name.slice(1)
        else return ""
    }

    protected async mounted(): Promise<void> {
        window.addEventListener("scroll", this.dailiesScroll)
        this.$store.commit("setEmbedded", this.searchParams.has("embedded"))
        this.$store.commit("setPrinting", this.searchParams.has("printing"))
        if (this.$cookies.get("darkMode") === "true") this.$store.commit("setDarkMode", true)
        if (this.$cookies.get("stickyMode") === "false") this.$store.commit("setStickyMode", false)
        if (this.$cookies.get("compactMode") === "true") this.$store.commit("setCompactMode", true)
        if (this.$cookies.get("isActivityListView") === "true") this.$store.commit("setIsActivityListView", true)
        if (this.$cookies.get("isConstraintListView") === "true") this.$store.commit("setIsConstraintListView", true)
        if (this.$cookies.get("isReviewListView") === "true") this.$store.commit("setIsReviewListView", true)
        if (this.$cookies.get("isMilestoneListView") === "true") this.$store.commit("setIsMilestoneListView", true)
        if (this.$cookies.get("isTimelineListView") === "true") this.$store.commit("setIsTimelineListView", true)
        if (this.$cookies.get("isWorklogListView") === "true") this.$store.commit("setIsWorklogListView", true)
        if (this.$cookies.get("lastWorkingDailyDate") !== DateTime.local().toISODate())
            this.$store.commit("setLastDailyDate", this.$cookies.get("lastWorkingDailyDate"))

        if (this.searchParams.has("darkMode")) this.$store.commit("setDarkMode", this.searchParams.has("darkMode"))
    }

    private dailiesScroll() {
        if (window.scrollY > this.$store.state.pageOffSetLimit) this.scrollToTop = true
        else this.scrollToTop = false
    }

    @Watch("$store.state.userPreferences.stickyMode", { immediate: false })
    public onStickyModeChangeWeb(newVal: boolean): void {
        const now = new Date()
        now.setTime(now.getTime() + 30 * 24 * 3600 * 1000)
        if (!this.isEmbedded) this.$cookies.set("stickyMode", newVal, now.toUTCString())
    }

    @Watch("$store.state.userPreferences.darkMode")
    public onDarkModeChangeWeb(newVal: boolean): void {
        const now = new Date()
        now.setTime(now.getTime() + 30 * 24 * 3600 * 1000)
        if (!this.isEmbedded) this.$cookies.set("darkMode", newVal, now.toUTCString())
    }

    @Watch("$store.state.userPreferences.darkMode", { immediate: true })
    public onDarkModeOrProjectChange(): void {
        const firstDummy = "navbarMarginElement"
        const navbarMarginElement = document.getElementById(firstDummy)
        if (navbarMarginElement) navbarMarginElement.outerHTML = ""
    }

    @Watch("$store.state.userPreferences.compactMode")
    public onCompactModeChangeWeb(newVal: boolean): void {
        const now = new Date()
        now.setTime(now.getTime() + 30 * 24 * 3600 * 1000)
        if (!this.isEmbedded) this.$cookies.set("compactMode", newVal, now.toUTCString())
    }

    @Watch("$store.state.userPreferences.isWorklogListView")
    public onWorklogListViewChangeWeb(newVal: boolean): void {
        const now = new Date()
        now.setTime(now.getTime() + 30 * 24 * 3600 * 1000)
        if (!this.isEmbedded) this.$cookies.set("isWorklogListView", newVal, now.toUTCString())
    }

    @Watch("$store.state.lastWorkingDailyDate")
    public onLastWorkingDailyDateChange(newVal: Date): void {
        const now = new Date()
        now.setTime(now.getTime() + 30 * 24 * 3600 * 1000)
        if (!this.isEmbedded) this.$cookies.set("lastWorkingDailyDate", newVal, now.toUTCString())
    }

    @Watch("$store.state.userPreferences.isTimelineListView")
    public onTimelineListViewChangeWeb(newVal: boolean): void {
        const now = new Date()
        now.setTime(now.getTime() + 30 * 24 * 3600 * 1000)
        if (!this.isEmbedded) this.$cookies.set("isTimelineListView", newVal, now.toUTCString())
    }

    @Watch("$store.state.userPreferences.isActivityListView")
    public onActivityListViewChangeWeb(newVal: boolean): void {
        const now = new Date()
        now.setTime(now.getTime() + 30 * 24 * 3600 * 1000)
        if (!this.isEmbedded) this.$cookies.set("isActivityListView", newVal, now.toUTCString())
    }

    @Watch("$store.state.userPreferences.isConstraintListView")
    public onConstraintListViewChangeWeb(newVal: boolean): void {
        const now = new Date()
        now.setTime(now.getTime() + 30 * 24 * 3600 * 1000)
        if (!this.isEmbedded) this.$cookies.set("isConstraintListView", newVal, now.toUTCString())
    }

    @Watch("$store.state.userPreferences.isReviewListView")
    public onReviewListViewChangeWeb(newVal: boolean): void {
        const now = new Date()
        now.setTime(now.getTime() + 30 * 24 * 3600 * 1000)
        if (!this.isEmbedded) this.$cookies.set("isReviewListView", newVal, now.toUTCString())
    }

    @Watch("$store.state.userPreferences.isMilestoneListView")
    public onMilestoneListViewChangeWeb(newVal: boolean): void {
        const now = new Date()
        now.setTime(now.getTime() + 30 * 24 * 3600 * 1000)
        if (!this.isEmbedded) this.$cookies.set("isMilestoneListView", newVal, now.toUTCString())
    }

    public get dark(): boolean {
        return whiteOrBlackText(variables.primary) === "white"
    }

    public get isDarkMode(): boolean {
        return this.$store.state.userPreferences.darkMode
    }

    public get webNavigation(): [string, NavigationPart[]][] {
        return Object.entries(this.filteredNavigation)
    }

    protected registerSSE(): void {
        if (this.sse) this.sse.close()

        this.sse = new EventSource(`${process.env.FLYPAPER_API_URL}/sse`)
        this.sse.addEventListener("message", (event) => {
            const stringData = getNestedPropertyValueOrNull("data", event)
            this.reactToSSE(stringData)
        })
    }

    @Watch("$route.query", { immediate: true })
    public async onRouteChange(newQuery?: Record<string, unknown>): Promise<void> {
        const showProfile = newQuery?.["profile"]
        if (showProfile !== undefined && showProfile === "true") this.showProfile = true

        const projectId = this.$route.query["project"]
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        if (projectId !== undefined) {
            this.$router.replace({ query: {} })
            const projectIdNum = Number(projectId)
            if (this.project?.id !== projectIdNum && this.user !== undefined) {
                // try to change current project if needed
                const response = await UsersDb.setUserCurrentProject(this.user.id, projectIdNum)
                if (isHttpError(response)) {
                    this.$router
                        .push({
                            path: "/errors",
                            query: {
                                message: "Access denied to the requested project. Please reach out to the admin."
                            }
                        })
                        .catch((e) => {
                            // NOTE Sentry throws strange errors if we don't wrap this in a try/catch
                        })

                    return
                }
            }
        }

        this.fBannerStore.removeBannerByText(hubConfig.unsavedChangesTitle)
        this.fBannerStore.removeBannerByText(planscanConfig.re_apply_filter_banner_title)
    }

    /**
     * Method to handle scroll to top functionality
     *
     */
    public goToTop(): void {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
    }

    public ketFrameScroll(): void {
        if (document.documentElement.scrollTop <= 0) clearInterval(this.timerId)
        else document.documentElement.scrollTop = document.documentElement.scrollTop - 10

        if (document.documentElement.scrollTop < 0) document.documentElement.scrollTop = 0
    }

    @Watch("project", { immediate: false })
    public userOrProjectIdChanged(newVal: number | undefined): void {
        if (!this.user || !this.project) return

        const userRoles: string[] = []
        this.project.data.companies_in_project.map((company) => {
            company.users.map((user) => {
                if (user.id === this.user?.id) {
                    user.roles.map((role) => {
                        userRoles.push(role)
                    })
                }
            })
        })

        this.allowInviteUser = this.project.data.can_invite_user.some((role) => userRoles.includes(role))
    }

    @Watch("user", { immediate: false })
    public onUserChanged(newOlder: UsersSchemaWithId | undefined, oldUser: UsersSchemaWithId | undefined): void {
        if (!newOlder && oldUser) {
            LOGGER.error(`User session wasn't present. Refreshing the app.`, this.loggerState)
            this.signout()
        }
    }
}

new ProtestWeb({
    el: "#protest",
    vuetify: new Vuetify(vuetifyOpts)
})

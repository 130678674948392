import { CompaniesSchemaWithId } from "components/companies/companies.schema"
import { ProjectSchemaWithId } from "components/project/project.schema"
import { UsersSchemaWithId } from "components/users/users.schema"
import { userCompanyNameInProject, userName } from "components/users/users.utils"
import compact from "lodash/compact"
import Vue from "vue"
import { Component } from "vue-property-decorator"

import { AttachmentsSchema, AttachmentsSchemaWithId, Variation } from "./attachments.schema"
import { AttachmentViewModel } from "./viewer/viewer"

export type fileClass = "audio" | "video" | "image" | "document" | "archive"
/**
 * Determines file class
 *
 * @param filename The name of the file with an extension
 * @returns The file class
 */
export function determineFileClass(filename: string): fileClass | undefined {
    const extname = "." + filename.split(".").pop()
    switch (extname.toLowerCase()) {
        case ".mp3":
        case ".wav":
        case ".caf":
        case ".aif":
        case ".wma":
        case ".ogg":
        case ".wav":
            return "audio"
        case ".mp4":
        case ".avi":
        case ".webm":
        case ".mkv":
        case ".mov":
            return "video"
        case ".jpeg":
        case ".jpg":
        case ".png":
        case ".gif":
        case ".bmp":
        case ".tiff":
        case ".tif":
        case ".svg":
        case ".raw":
        case ".webp":
        case ".heic":
            return "image"
        case ".docx":
        case ".doc":
        case ".pptx":
        case ".ppt":
        case ".pdf":
        case ".md":
        case ".txt":
        case ".csv":
        case ".xls":
        case ".xlsx":
        case ".msg":
            return "document"
        case ".zip":
        case ".tar":
        case ".gz":
            return "archive"
    }
}

/**
 * Get the file extension from an attachment record
 *
 * @param attachment The attachment record to derive the extension from
 * @returns attachment extension
 */
export function getAttachmentExtension(attachment: AttachmentsSchemaWithId): string | undefined {
    const mutateFileExt: string | undefined = attachment.data.metadata?.mutate?.file_ext?.toLowerCase()
    return mutateFileExt ? mutateFileExt : attachment.data.filename.split(".").pop()?.toLowerCase()
}

/**
 * Get a string representation of a number with at least one significant number
 *
 * @param num The number to convert
 * @param minimumDecimalPlaces The minimum number of decimal places
 * @returns A string
 */
function firstSignificantDecimal(num: number, minimumDecimalPlaces: number): string {
    const test = num.toFixed(minimumDecimalPlaces)
    if (num === 0) return test

    if (Number(test) === 0) return firstSignificantDecimal(num, minimumDecimalPlaces + 1)

    return test
}

export type fileSizeUnit = "MB" | "GB" | "KB" | "B"
/**
 * Formatted file size
 *
 * @param sizeInBytes The byte number to convert
 * @param [unit] The unit to convert to, defaults to MB
 * @returns A formatted string
 */
export function formattedFileSize(sizeInBytes: number, unit: fileSizeUnit = "MB"): string {
    let denominator = 1
    switch (unit) {
        case "KB":
            denominator = 1000
            break
        case "MB":
            denominator = 1000 * 1000
            break
        case "GB":
            denominator = 1000 * 1000 * 1000
            break
    }

    const convertedSize = sizeInBytes / denominator
    return firstSignificantDecimal(convertedSize, 2) + " " + unit
}

/**
 * @param attachment attachment
 * @param verticalSize vertical size
 * @param variationType type of variation
 * @returns closest size
 */
export function closestVarient(attachment: AttachmentsSchema & RequiredId, verticalSize: number, variationType: string): Variation | undefined {
    const variations = compact(attachment.data.variations.map((x) => x.tags.find((tag) => tag.includes(variationType))))

    let sizes: number[] = []

    if (variations.length > 0) sizes = variations.map((x) => Number(x.replace(variationType + "-", ""))).filter((x) => !Number.isNaN(x))

    if (sizes.length > 0) {
        const maxSize = Math.max(...sizes)

        const closestVariant = attachment.data.variations.find((x) => x.tags.includes(variationType + "-" + maxSize))

        if (variationType === "thumbnail") {
            const originalTrimmed = attachment.data.variations.find((x) => x.tags.includes("original-trimmed"))
            return verticalSize > maxSize ? originalTrimmed ?? closestVariant : closestVariant ?? originalTrimmed
        } else {
            return closestVariant
        }
    }
}

/**
 * Generates chunk sizes, start and end point
 *
 * @param range rannge
 * @param fileSize fileSize
 * @returns chunk sizes, start and end point
 */
export function getChunkProps(
    range: string,
    fileSize: number
): {
    start: number
    end: number
    chunkSize: number
} {
    const parts = range.replace(/bytes=/, "").split("-")

    const start = parseInt(parts[0], 10)
    const end = parts[1] ? parseInt(parts[1], 10) : fileSize - 1
    const chunkSize = end - start + 1

    return {
        start,
        end,
        chunkSize
    }
}

/**
 * get Attachment View Model
 *
 * @param attachment AttachmentsSchemaWithId
 * @param allCompanies All companies in hub
 * @param allUsers All users
 * @param project curent project
 * @returns getAttachmentViewModel
 */
export function getAttachmentViewModel(
    attachment: AttachmentsSchemaWithId,
    allCompanies: CompaniesSchemaWithId[],
    allUsers: UsersSchemaWithId[],
    project: ProjectSchemaWithId | undefined
): AttachmentViewModel {
    const author = userName(attachment.created_by, allUsers)
    const authorCompany = userCompanyNameInProject(attachment.created_by, allCompanies, project)

    return {
        ...attachment,
        author: author,
        authorCompany: authorCompany,
        size: formattedFileSize(attachment.data.file_size),
        filename: attachment.data.filename,
        createdAt: new Date(attachment.date_created).toLocaleString(),
        type: determineFileClass(attachment.data.filename) || "Unknown"
    }
}

@Component
export class AttachmentsUtilsMixin extends Vue {
    public determineFileClass(filename: string): fileClass | undefined {
        return determineFileClass(filename)
    }

    public formattedFileSize(sizeInBytes: number): string {
        return formattedFileSize(sizeInBytes)
    }
}

import { HttpError } from "common/http"
import Vue from "vue"

export default class SnackEmitter {
    private vue: Vue
    constructor(vue: Vue) {
        this.vue = vue
    }

    public showCustomSnack(snack: Snack): void {
        if (snack.timeout === undefined) snack.timeout = 4000

        this.vue.$root.$emit("newSnack", snack)
    }

    public showHttpError(error: HttpError): void {
        let message = error.errorMessage || "Something went wrong, please try again later"
        if (typeof message !== "string") message = JSON.stringify(message)

        this.showCustomSnack({
            message: message.trim(),
            color: "error"
        })
    }

    public showSomethingWentWrongSnack(): void {
        this.showCustomSnack({
            message: "Something went wrong, please try again later.",
            color: "error",
            timeout: 4000
        })
    }

    public showUnableToConnectSnack(): void {
        this.showCustomSnack({
            message: `Unable to connect, please try again later.`,
            timeout: 4000,
            color: "error"
        })
    }
}

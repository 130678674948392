import * as Tracing from "@sentry/tracing"
import { Primitive } from "@sentry/types"
import * as Sentry from "@sentry/vue"
import Vue from "vue"

Sentry.init({
    Vue: Vue,
    dsn: process.env.FLYPAPER_SENTRY_DSN,

    // To set your release version
    release: process.env.FLYPAPER_VERSION,
    environment: process.env.FLYPAPER_ENVIRONMENT,
    integrations: [new Tracing.Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: MODE === "production" ? 0.1 : 1.0,

    beforeSend(event, hint) {
        // if (MODE === "production") Sentry.showReportDialog({ eventId: event.event_id })
        return event
    }
})

class SentryWeb implements SentryImplementation {
    public message(message: string, level: Sentry.SeverityLevel): void {
        Sentry.captureMessage(message, { level })
    }

    public exception(err: Error): void {
        Sentry.captureException(err)
    }
    public setTags(tags: { [key: string]: Primitive }): void {
        Sentry.setTags(tags)
    }
}

export default new SentryWeb()

import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"todos"}},[_c('FMenu',{ref:"fmenu",attrs:{"allowMenuPointer":"true","icon":"mdi-playlist-check","toolTip":"Show ToDo's.","badgeValue":_vm.toDosdata.length,"minWidth":650,"maxWidth":650}},[_c(VCard,{attrs:{"flat":""}},[_c('FToolbar',{staticClass:"pa-2",attrs:{"title":"TODOs","flat":"","boldText":""}}),(_vm.toDosdata.length > 0)?_c(VDataTable,{ref:"todos",staticClass:"elevation-0",staticStyle:{"cursor":"pointer"},attrs:{"headers":_vm.headers,"items":_vm.toDosdata,"item-key":"id","single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":"","items-per-page":5,"loading":"tableLoading"},on:{"click:row":_vm.handleRowClick,"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.data.icon",fn:function(ref){
var item = ref.item;
return [_c(VIcon,[_vm._v(_vm._s(item.data.icon))])]}},{key:"item.data.title",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.data.title))]}},{key:"item.date_created",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getFormattedDate(item.date_created)))]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(VCardActions,_vm._l((item.data.actions),function(action){return _c('FButton',{key:action.text,staticStyle:{"display":"block","margin":"auto"},attrs:{"text":"","prepend-icon":action.icon,"outlined":"","buttonText":action.text},on:{"click":function($event){return _vm.handleActionClick(action.url, action.type)}}})}),1)],1)]}}],null,false,1823190422)}):_vm._e(),(_vm.toDosdata.length == 0)?_c('FAlert',{staticClass:"ma-2",attrs:{"type":"info","prominent":"","text":"","border":"left"}},[_vm._v("No todos left for today")]):_vm._e(),_c(VCardActions,[_c('div',{staticClass:"flex-grow-1"}),_c('FButton',{attrs:{"text":"","outlined":"","buttonText":"Close"},on:{"click":function($event){return _vm.closeMenu()}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import BaseVueComponent from "common/BaseVueComponent"
import { getIconForRecordType } from "components/filters/filters.utils"
import { Component, Prop } from "vue-property-decorator"

@Component
export default class GroupBySortByBase extends BaseVueComponent {
    @Prop(String) public sortBy!: string
    @Prop(String) public groupBy!: string
    @Prop() public sortKeys!: string[] | never
    @Prop() public groupKeys!: string[]
    @Prop(Boolean) public sortDesc!: boolean
    @Prop({ type: String, default: "width: 300px; padding-top: 5px; max-height: 100px" }) public customStyle!: string
    @Prop({ type: Boolean, default: false }) public hideSortClerable!: boolean
    @Prop({ type: String, default: "margin-top: -10px; margin-left: -15px" }) public sortDescbuttonStyle!: string

    public updateGroupBy(value: string): void {
        this.$emit("onGroupByItemChanged", value)
    }
    public updateSortBy(value: string): void {
        this.$emit("onSortByItemChanged", value)
    }
    public updateSortOrder(): void {
        this.$emit("onSortOrderChanged")
    }

    public getIconForRecordType(recordType: string | undefined): string | undefined {
        return getIconForRecordType(recordType)
    }
}

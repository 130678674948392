import BaseVueComponent from "common/BaseVueComponent"
import { Component, Prop } from "vue-property-decorator"
@Component
export default class FSwitchBase extends BaseVueComponent {
    @Prop() public value!: string | number
    @Prop(Boolean) public disabled!: boolean
    @Prop({ default: "", type: String }) public color!: string
    @Prop({ default: "", type: String }) public label!: string
    @Prop({ default: "", type: String }) public prependIcon!: string
    @Prop({ type: Boolean, default: false }) public hideDetails!: boolean
    @Prop({ type: Boolean, default: false }) public isLight!: boolean
    @Prop({ default: "", type: String }) public customLabelStyle!: string
    @Prop({ default: "", type: String }) public customLabelClass!: string
    @Prop({ default: "", type: String }) public customStyle!: string

    public get localValue(): string | number {
        return this.value
    }

    public set localValue(value: string | number) {
        if (value === this.value) return
        this.$emit("input", value)
    }
}

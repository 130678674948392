import Axios, { RawAxiosRequestHeaders } from "axios"
import ClientToken from "common/clientToken"
import Sentry from "common/sentry/sentry"
import { stringify } from "flatted"
import get from "lodash/get"
import { DateTime } from "luxon"

import { getErrorMessage } from "./Errors"

/**
 * Logger API
 *
 * @description A simple logging framework for communicating easily with Avian
 * and its Fluentd logging middleware.
 * @global
 * @class
 */
export default class Logger {
    private label = LOGLABEL
    private sourceName: string

    /**
     * Creates an instance of Logger.
     *
     * @param sourceName The source of the request. In this case it's the class name or component name.
     */
    constructor(sourceName: string) {
        this.sourceName = sourceName || ""
    }
    /**
     * Debugs logger
     *
     * @param message the message to log
     * @param object the object to log
     * @param [sessionId] optional sessionId to use for the request
     */
    public debug(message: string, object: unknown, sessionId?: string): void {
        this.sendRecord(message, object, "debug", sessionId)
    }
    /**
     * Errors logger
     *
     * @param message the message to log
     * @param object the object to log
     * @param [sessionId] optional sessionId to use for the request
     */
    public error(message: string, object: unknown, sessionId?: string): void {
        this.sendRecord(message, object, "error", sessionId)
    }
    /**
     * Fatals logger
     *
     * @param message the message to log
     * @param object the object to log
     * @param [sessionId] optional sessionId to use for the request
     */
    public fatal(message: string, object: unknown, sessionId?: string): void {
        this.sendRecord(message, object, "fatal", sessionId)
    }
    /**
     * Infos logger
     *
     * @param message the message to log
     * @param object the object to log
     * @param [sessionId] optional sessionId to use for the request
     */
    public info(message: string, object: unknown, sessionId?: string): void {
        this.sendRecord(message, object, "info", sessionId)
    }
    /**
     * Warns logger
     *
     * @param message the message to log
     * @param object the object to log
     * @param [sessionId] optional sessionId to use for the request
     */
    public warn(message: string, object: unknown, sessionId?: string): void {
        this.sendRecord(message, object, "warning", sessionId)
    }

    protected async sendRecord(
        message: string,
        object: unknown,
        level: "warning" | "debug" | "info" | "fatal" | "error",
        sessionId?: string
    ): Promise<void> {
        try {
            // trying to determine if the object that was given to us is an exception to properly handle it in sentry
            const errorInstance = this.getErrorInstanceFromObject(object)
            if (errorInstance) Sentry.exception(errorInstance)

            if (level !== "info" && level !== "debug") {
                Sentry.setTags({ environment: MODE === "production" ? "app" : "dev", source: this.sourceName })
                Sentry.message(message, level)
            }
        } catch (error) {
            try {
                await this.postToLogger("Failed to send log to sentry", level, { error: getErrorMessage(error) }, sessionId)
            } catch {}
        }

        try {
            let objectToSend = object
            try {
                JSON.stringify(object)
            } catch (error) {
                objectToSend = stringify(object)
            }

            await this.postToLogger(message, level, objectToSend, sessionId)
        } catch (error) {
            try {
                await this.postToLogger("Failed to send log to logger", level, { error: getErrorMessage(error) }, sessionId)
            } catch {}
        }
    }

    private async postToLogger(message: string, level: string, object: unknown, sessionId?: string) {
        const data = {
            timestamp: DateTime.utc().toMillis(),
            message,
            object
        }

        // NOTE do not use http here
        await Axios.post(`${process.env.FLYPAPER_API_URL}/protest/logger`, data, {
            params: {
                label: this.label,
                level,
                source: this.sourceName
            },
            headers: {
                ...this.makeAuthHeader(sessionId)
            }
        })
    }

    private getErrorInstanceFromObject(object: unknown): Error | undefined {
        if (object instanceof Error) return object
        const err = get(object, "err")
        if (err instanceof Error) return err
        const error = get(object, "error")
        if (error instanceof Error) return error

        return undefined
    }

    private makeAuthHeader(sessionId?: string): RawAxiosRequestHeaders {
        if (sessionId) {
            return {
                Authorization: "Bearer " + sessionId
            }
        }

        const token = new ClientToken().get()
        if (token) {
            return {
                Authorization: "Bearer " + token
            }
        }

        return {} as RawAxiosRequestHeaders
    }
}

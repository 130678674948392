import { getAvatarUrl } from "common/avatar"
import { AttachmentReference, CompaniesSchema, CompaniesSchemaWithId } from "components/companies/companies.schema"
import { ProjectInfo } from "components/hub/manageCompanies/manageCompanies"
import { CompaniesInProject, ProjectSchemaWithId } from "components/project/project.schema"
import { getProjectLogoSync } from "components/project/project.utils"
import Vue from "vue"
import { Component } from "vue-property-decorator"
/**
 * Get a Company's name from Id
 *
 * @param companyId The company id
 * @param companies The list of companies to search
 * @returns The company's name or empty string
 */
export function companyName(companyId: number, companies: CompaniesSchema[]): string {
    const companyRecord = companies.find((companyRecord) => companyRecord.id === companyId)
    return companyRecord ? companyRecord.data.name : ""
}

/**
 * Get company logo or default logo with company string
 *
 * @param company Company for which logo is accessed
 * @param color Company color
 * @param base64 if true, Returns base64 logo string
 * @param isCompanyLogoAvailable
 * @param project
 * @returns string logo
 */
export function getCompanyLogo(
    company: CompaniesSchema,
    color?: string,
    base64?: boolean,
    project?: ProjectSchemaWithId
): {
    url: string
    isSvg: boolean
} {
    const attachmentIds = company.data.attachments.map((attachment) => attachment.id)
    const isAttachmentPresentInProject = company.data.attachments.length
        ? attachmentIds.every((id) => project?.data.attachments.some((attachment) => attachment.id === id))
        : false

    const logos: AttachmentReference[] = isAttachmentPresentInProject
        ? company.data.attachments.filter((attachment) => attachment.tags.indexOf("logo") !== -1)
        : []

    if (logos.length > 0) {
        const attachmentStreamUrl = `${process.env.FLYPAPER_HOST}/attachments/thumbnail/${logos[0].id}`
        return { url: attachmentStreamUrl, isSvg: false }
    } else {
        return {
            url: getAvatarUrl(company.data.name, color),
            isSvg: !base64
        }
    }
}

/**
 * Get all projects which have this company
 *
 * @param companyId Company id to search for
 * @param allProjects All projects
 * @returns project info array
 */
export function getActiveProjectsForCompany(companyId: number, allProjects: Array<ProjectSchemaWithId>): Array<ProjectInfo> {
    const projects: Array<ProjectInfo> = []
    allProjects.forEach((project) => {
        if (project.data.companies_in_project.some((company) => company.company_id === companyId)) {
            projects.push({
                id: project.id,
                name: project.data.name,
                logo: getProjectLogoSync(project.id, allProjects)
            })
        }
    })

    return projects
}

/**
 * Get Logo for a given company from project
 *
 * @param name Name of company
 * @param companies_in_project Companies in current project
 * @param allCompanies All Companies
 * @param project
 * @param color Company color in project
 * @param base64 boolean if we want base64
 * @param size logo size
 * @returns Company logo string
 */
export function getLogoForCompany(
    name: string,
    companies_in_project: CompaniesInProject[],
    allCompanies: CompaniesSchemaWithId[],
    color?: string,
    base64?: boolean,
    size?: number
): {
    url: string
    isSvg: boolean
} {
    const company = companies_in_project.find((comp) => companyName(comp.company_id, allCompanies) === name)
    if (company) {
        return getCompanyAvatar(company, allCompanies, undefined, color, base64, size)
    } else {
        return {
            url: "",
            isSvg: false
        }
    }
}

/**
 * Get company logo
 *
 * @param company Company for which logo is accessed
 * @param project
 * @param allCompanies All companies
 * @param color Company color
 * @param base64 optional boolean if we need base64
 * @param size logo size
 * @returns string avatar
 */
export function getCompanyAvatar(
    company: CompaniesInProject,
    allCompanies: CompaniesSchemaWithId[],
    project?: ProjectSchemaWithId,
    color?: string,
    base64?: boolean,
    size?: number
): {
    url: string
    isSvg: boolean
} {
    if (company.project_logo) {
        return {
            url: size
                ? `${process.env.FLYPAPER_HOST}/attachments/thumbnail/${company.project_logo.id}?size=${size}`
                : `${process.env.FLYPAPER_HOST}/attachments/thumbnail/${company.project_logo.id}`,
            isSvg: false
        }
    } else {
        const companyData = allCompanies.find((comp) => comp.id === company.company_id)

        if (companyData) {
            return getCompanyLogo(companyData, company.color, base64, project)
        } else {
            return {
                url: "",
                isSvg: false
            }
        }
    }
}

@Component
export class CompanyUtilsMixin extends Vue {
    public companyName(companyId: number, companies: CompaniesSchema[]): string {
        return companyName(companyId, companies)
    }

    public getCompanyLogo(
        company: CompaniesSchema,
        color?: string,
        base64?: boolean,
        project?: ProjectSchemaWithId
    ): {
        url: string
        isSvg: boolean
    } {
        return getCompanyLogo(company, color, base64, project)
    }

    public getActiveProjectsForCompany(companyId: number, allProjects: Array<ProjectSchemaWithId>): Array<ProjectInfo> {
        return getActiveProjectsForCompany(companyId, allProjects)
    }

    public getLogoForCompany(
        name: string,
        companies_in_project: CompaniesInProject[],
        allCompanies: CompaniesSchemaWithId[],
        color?: string,
        base64?: boolean
    ): {
        url: string
        isSvg: boolean
    } {
        return getLogoForCompany(name, companies_in_project, allCompanies, color, base64)
    }

    public getCompanyAvatar(
        company: CompaniesInProject,
        allCompanies: CompaniesSchemaWithId[],
        project?: ProjectSchemaWithId,
        color?: string,
        base64?: boolean
    ): {
        url: string
        isSvg: boolean
    } {
        return getCompanyAvatar(company, allCompanies, project, color, base64)
    }
}

import { WorklogsSchemaWithId } from "components/daily/worklogs/worklogs.schema"
import { store } from "components/protest.store"
import { DateTime } from "luxon"
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators"

@Module({
    dynamic: true,
    store,
    name: "worklogs",
    namespaced: true
})
export default class WorklogsStore extends VuexModule {
    public worklogs: Array<WorklogsSchemaWithId> = []

    @Mutation
    public setWorklogs(worklogs: Array<WorklogsSchemaWithId>): void {
        this.worklogs = worklogs
    }

    @Mutation
    public updateWorklog({ index, updatedWorklog }: { index: number; updatedWorklog: WorklogsSchemaWithId }): void {
        this.worklogs.splice(index, 1, updatedWorklog)
    }

    @Mutation
    public removeWorklog(index: number): void {
        this.worklogs.splice(index, 1)
    }

    @Action
    public updateWorklogIfExists(updatedWorklog: WorklogsSchemaWithId): void {
        const index = this.worklogs.findIndex((x) => x.id === updatedWorklog.id)

        if (index !== -1) {
            const currentVersion = this.worklogs[index]
            if (DateTime.fromISO(updatedWorklog.date_modified) <= DateTime.fromISO(currentVersion.date_modified)) return
            this.updateWorklog({ index, updatedWorklog })
        }
    }

    @Action
    public removeWorklogIfExists(removedWorklog: WorklogsSchemaWithId): void {
        const index = this.worklogs.findIndex((x) => x.id === removedWorklog.id)

        if (index !== -1) this.removeWorklog(index)
    }
}

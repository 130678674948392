import BaseVueComponent from "common/BaseVueComponent"
import { Component, Prop } from "vue-property-decorator"

@Component
export default class FIconBase extends BaseVueComponent {
    @Prop({ type: String, default: "" })
    public value!: string

    public get iconClass() {
        if (this.value.indexOf("fi-") !== -1) return "fi"
        else if (this.value.indexOf("mdi-") !== -1) return "mdi"
        else return null
    }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.viewer-controls[data-v-59921618] {
  z-index: 3;
}
.thumb-controls[data-v-59921618] {
  width: 100%;
  margin-top: 145px;
  padding-left: 35px;
  padding-right: 50px;
  position: absolute;
  z-index: 3;
}
.selected[data-v-59921618] {
  position: absolute;
  width: 100%;
  left: 0px;
  right: 0px;
  z-index: 1;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  border: 3px solid #0075b4;
  background-color: rgba(0, 126, 255, 0.24);
}
.image-holder[data-v-59921618] {
  border-radius: 10px;
  width: 100%;
}
.video-holder[data-v-59921618] {
  width: 100%;
  height: 600px;
}
.audio-holder[data-v-59921618] {
  width: 100%;
  height: 80px;
}
`, "",{"version":3,"sources":["webpack://./components/attachments/viewer/viewer.style.less","webpack://./viewer.style.less"],"names":[],"mappings":"AAAA;EACI,UAAA;ACCJ;ADEA;EACI,WAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;EACA,kBAAA;EACA,UAAA;ACAJ;ADGA;EACI,kBAAA;EACA,WAAA;EACA,SAAA;EACA,UAAA;EACA,UAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,yCAAA;ACDJ;ADIA;EACI,mBAAA;EACA,WAAA;ACFJ;ADKA;EACI,WAAA;EACA,aAAA;ACHJ;ADMA;EACI,WAAA;EACA,YAAA;ACJJ","sourcesContent":[".viewer-controls {\n    z-index: 3;\n}\n\n.thumb-controls {\n    width: 100%;\n    margin-top: 145px;\n    padding-left: 35px;\n    padding-right: 50px;\n    position: absolute;\n    z-index: 3;\n}\n\n.selected {\n    position: absolute;\n    width: 100%;\n    left: 0px;\n    right: 0px;\n    z-index: 1;\n    width: 200px;\n    height: 200px;\n    border-radius: 10px;\n    border: 3px solid #0075b4;\n    background-color: rgb(0 126 255 / 24%);\n}\n\n.image-holder {\n    border-radius: 10px;\n    width: 100%;\n}\n\n.video-holder {\n    width: 100%;\n    height: 600px;\n}\n\n.audio-holder {\n    width: 100%;\n    height: 80px;\n}\n",".viewer-controls {\n  z-index: 3;\n}\n.thumb-controls {\n  width: 100%;\n  margin-top: 145px;\n  padding-left: 35px;\n  padding-right: 50px;\n  position: absolute;\n  z-index: 3;\n}\n.selected {\n  position: absolute;\n  width: 100%;\n  left: 0px;\n  right: 0px;\n  z-index: 1;\n  width: 200px;\n  height: 200px;\n  border-radius: 10px;\n  border: 3px solid #0075b4;\n  background-color: rgba(0, 126, 255, 0.24);\n}\n.image-holder {\n  border-radius: 10px;\n  width: 100%;\n}\n.video-holder {\n  width: 100%;\n  height: 600px;\n}\n.audio-holder {\n  width: 100%;\n  height: 80px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;

import FBannerStore from "components/widgets/fBanner/fBanner.store"
import Vue from "vue"
import { Component } from "vue-property-decorator"
import { getModule } from "vuex-module-decorators"

@Component
export class FBannerUtilsMixin extends Vue {
    protected fBannerStore = getModule(FBannerStore)
    private bannerHeight = 65

    public get bannerTotalHeight(): number {
        return this.fBannerStore.visibleBannerCount * this.bannerHeight
    }
}

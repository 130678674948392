import { store } from "components/protest.store"
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators"

@Module({
    dynamic: true,
    store,
    name: "FBanner",
    namespaced: true
})
export default class FBannerStore extends VuexModule {
    public banners: Array<FBannerOptions> = []

    @Mutation
    public addBanner(banner: FBannerOptions): void {
        this.banners.push(banner)
    }

    @Mutation
    public removeBanner(index: number): void {
        this.banners.splice(index)
    }

    @Mutation
    public updateBanner(index: number, updatedBanner: FBannerOptions): void {
        this.removeBanner(index)
        this.addBanner(updatedBanner)
    }

    @Action
    public addNewBanners(newBanners: FBannerOptions[]): void {
        newBanners.forEach((banner) => {
            if (!this.banners.find((x) => x.text === banner.text)) this.addBanner(banner)
        })
    }

    @Action
    public removeBannerByText(text: string): void {
        const index = this.banners.findIndex((banner) => banner.text === text)
        if (index > -1) this.removeBanner(index)
    }

    public get bannerCount(): number {
        return this.banners.length
    }

    public get visibleBannerCount(): number {
        return this.banners.filter((banner) => banner.showBanner).length
    }
}

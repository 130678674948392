import BaseVueComponent from "common/BaseVueComponent"
import ReadOnlyTextField from "components/widgets/readOnlyTextField/readOnlyTextField.component.vue"
import { Component, Prop } from "vue-property-decorator"

@Component({
    components: {
        ReadOnlyTextField
    }
})
export default class FTextBase extends BaseVueComponent {
    @Prop() public value!: string | number
    @Prop({ default: 1, type: Number }) public rows!: number
    @Prop({ default: false, type: Boolean }) public autoGrow!: boolean
    @Prop({ default: false, type: Boolean }) public textarea!: boolean
    @Prop({ default: true, type: Boolean }) public filled!: boolean
    @Prop({ default: false, type: Boolean }) public outlined!: boolean
    @Prop({ default: false, type: Boolean }) public solo!: boolean
    @Prop({ default: false, type: Boolean }) public flat!: boolean
    @Prop({ default: false, type: Boolean }) public required!: boolean
    @Prop({ default: false, type: Boolean }) public readonly!: boolean
    @Prop({ default: false, type: Boolean }) public disabled!: boolean
    @Prop({ default: false, type: Boolean }) public dense!: boolean
    @Prop({ default: false, type: Boolean }) public clearable!: boolean
    @Prop({ default: false, type: Boolean }) public persistentHint!: boolean
    @Prop({ default: "", type: String }) public label!: string
    @Prop({ default: "", type: String }) public hint!: string
    @Prop({ default: "", type: String }) public prependInnerIcon!: string
    @Prop({ default: "", type: String }) public prependIcon!: string
    @Prop({ default: "", type: String }) public appendInnerIcon!: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    @Prop({ default: () => [] }) public rules!: Array<any>
    @Prop({ default: false, type: Boolean }) public textOnly!: boolean
    public locaLabelOnly = false
    public get localValue(): string | number {
        return this.value
    }

    public set localValue(value: string | number) {
        if (value === this.value) return
        this.$emit("input", value)
    }

    public get filledProp(): boolean {
        return this.filled && !this.solo && !this.outlined
    }

    public get denseProp(): boolean {
        return this.dense || this.$store.state.userPreferences.compactMode
    }
}

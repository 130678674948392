import BaseVueComponent from "common/BaseVueComponent"
import { DocumentationUtilsMixin } from "components/documentation/documentation.utils"
import Tutorials from "components/documentation/tutorials/tutorials.component.vue"
import ButtonDisplay from "components/widgets/fToolbar/buttonDisplay/buttonDisplay.component.vue"
import { Component, Mixins, Prop } from "vue-property-decorator"
@Component({ components: { Tutorials, ButtonDisplay } })
export default class FToolbarBase extends Mixins(BaseVueComponent, DocumentationUtilsMixin) {
    @Prop(String) public icon!: string
    @Prop(String) public title!: string
    @Prop(String) public subtitle!: string
    @Prop(Boolean) public isSuperAdmin!: boolean
    @Prop(Boolean) public isProjectAdmin!: boolean
    @Prop(Boolean) public isCompanyAdmin!: boolean
    @Prop(Boolean) public rounded!: boolean
    @Prop(Boolean) public prominent!: boolean
    @Prop({ type: Number, default: 900 }) public buttonBreakpoint!: number
    @Prop(String) public buttonMenuMinWidth!: string
    @Prop(Boolean) public collapse!: boolean
    @Prop(Boolean) public dense!: boolean
    @Prop(Boolean) public absolute!: boolean
    @Prop(Boolean) public flat!: boolean
    @Prop(Boolean) public roundedTop!: boolean
    @Prop(Boolean) public roundedBottom!: boolean
    @Prop(Boolean) public boldText!: boolean
    @Prop(Boolean) public lowercase!: boolean
    @Prop(String) public backgroundColor!: string
    @Prop(String) public toolBarId!: string
    @Prop(String) public customStyle!: string
    @Prop(Array) public tutorialCategory!: []
    @Prop(Array) public tutorialTags!: []
    @Prop(String) public tab!: string
    @Prop(String) public elementId!: string
    @Prop(Array) public stickyClasses!: string[]

    public compactView = false
    public showTutorialDialog = false
    public showTotorialCompactView = false
    public stickyMode = true
    public stickyRounded = false

    public onShowDetailedView(): void {
        if (this.compactView) {
            this.compactView = !this.compactView
            this.showTotorialCompactView = false
            this.showTutorialDialog = true
        }
    }

    public get titleClass(): string {
        let classes = "text-h6"
        if (this.boldText) classes = classes + " font-weight-bold"
        if (!this.lowercase) classes = classes + " text-uppercase"

        return classes
    }

    public get getRounded(): boolean {
        return this.rounded
    }
}

import Fuse from "fuse.js"
/**
 * @param keys the search keys
 * @returns the default fuse options
 */
export function defaultFuzzySearchFuseOptions<T>(keys: string[]): Fuse.IFuseOptions<T> {
    return {
        shouldSort: true,
        useExtendedSearch: true,
        threshold: 0.2,
        ignoreLocation: true,
        keys
    }
}
